import React, { useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  TreeSelect,
  Table,
} from "antd";
import { withFormik, Field, FormikProps } from "formik";
import Spinner from "../../../../reusable-components/Spinner";
import {
  reports_filter_options,
  dashboard_status_options,
} from "../../../../util/helpers/form-field-options";
import { history } from "../../../../util/helpers/browserHistory";
import { numberWithCommas } from "../../../../util/helpers/reusable-functions";
import Pagination from "../../../../reusable-components/Pagination";
import moment from "moment";
import get from "lodash/get";

const FormItem = Form.Item;
const { Option } = Select;

const ViewLoansSummary = ({
  isloading,
  loans,
  fromDate,
  toDate,
  officerId,
  page,
  pageCount,
  ...props
}) => {
  const columns = (props) => [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => {
        return numberWithCommas(row.amount);
      },
    },
    {
      title: "Flow type",
      dataIndex: "flow_type",
      key: "flow_type",
      render: (text, row) => {
        let fullName = row.flow_type.split("_").join(" ");
        return fullName;
      },
    },
    {
      title: "Operation Type",
      dataIndex: "cash_operation_type",
      key: "cash_operation_type",
      render: (text, row) => {
        let operation_type =
          row.cash_operation_type === "cash_inflow" ? (
            <span className="inflow">Cash inflow</span>
          ) : (
            <span className="outflow">Cash outflow</span>
          );
        return operation_type;
      },
    },
    {
      title: "Client",
      dataIndex: "user_id.name",
      key: "user_id.name",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, row) => {
        return moment(row.created_at).format("DD/MM/YYYY hh:mm");
      },
    },
  ];

  return (
    <>
      <Row gutter={32}>
        <Col span={24}>
          <h3 className="header">
            <b>Summary</b>
          </h3>
        </Col>
        <Col span={24}>
          {props.isloading && <Spinner />}
          {loans && (
            <Table
              className="table"
              dataSource={loans}
              pagination={false}
              columns={columns(props)}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <div>
              <span>Showing records</span>
            </div>
            <Pagination
              handlePagination={(_page) => {
                props.fetchRoLoansSummary(_page, fromDate, toDate, officerId);
              }}
              page={page}
              pageCount={pageCount}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ViewLoansSummary;
