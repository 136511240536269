import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/organizations/actions';
import CreateUpdateOrganization from "./components/CreateUpdateOrganization";

const mapStateToProps = state => {
  const { isloading, organization } = state.organizations;
  return {
    organization,
    isloading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: (data) => {
      dispatch(Creators.createOrganization(data));
    },
    getOrganization: (data) => {
      dispatch(Creators.getOrganization(data));
    },
    updateOrganization: (id, data) => {
      dispatch(Creators.updateOrganization(id, data));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdateOrganization);
