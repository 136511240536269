import React from "react";
import {Layout, Row, Col} from "antd";
import  "../../styles/header.less";
import MobileHeader from "./components/MobileHeader";
import WebHeader from "./components/WebHeader";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';

const { Header} = Layout;

class Index extends React.Component {
  render() {
    const { loggedInUser, logoutMethod, setupComplete } = this.props;
    return (
      <>
        <Header className="header">
        <div id="main">
          <Row>
            {/* web header  */}
            <Col xs={0} sm={0} md={24} >
              <WebHeader 
                loggedInUser={loggedInUser} 
                logout={logoutMethod}
                setupComplete={setupComplete}
                {...this.props}
              />
            </Col>
            {/* mobile header  */}
            <Col xs={24} sm={24} md={0} >
              <MobileHeader 
                loggedInUser={loggedInUser}  
                logout={logoutMethod}
                setupComplete={setupComplete} 
                {...this.props}
              />
            </Col> 
          </Row>            
        </div>
        </Header>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps)(Index);

