import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({

  createCashManagement: ['org_id', 'payload'],
  createCashManagementSuccess: ['responseData'],
  createCashManagementFailure: ['error'],

  getCashManagement: ['org_id'],
  getCashManagementSuccess: ['responseData'],
  getCashManagementFailure: ['error'],

  fetchCashManagements: ['org_id', 'query'],
  fetchCashManagementsSuccess: ['responseData', 'page', 'pageCount'],
  fetchCashManagementsFailure: ['error'],

  fetchCashTotals: ['org_id', 'query'],
  fetchCashTotalsSuccess: ['responseData'],
  fetchCashTotalsFailure: ['error'],

  updateCashManagement: ['id', 'payload'],
  updateCashManagementSuccess: ['responseData'],
  updateCashManagementFailure: ['error'],

  downloadCashflowCSV: ['org_id', 'query'],
  downloadCashflowCSVSuccess: ['responseData'],
  downloadCashflowCSVFailure: ['error'],

  reset: null
}, {});
