import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/loans/actions';
import { Creators as UsersCreators } from '../../../services/redux/users/actions';
import { getLocalState } from '../../../util/helpers/reusable-functions';
import CreateUpdateLoan from "./components";

const mapStateToProps = state => {
  const { isloading, loan, isFetching } = state.loans;
  return {
    loan,
    isloading,
    isFetching,
    loan_officers: state.users.users,
    isFetchingLoanUsers: state.users.isloading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLoan: (data) => {
      dispatch(Creators.createLoan(data));
    },
    updateLoan: (id, data) => {
      let redirect = true
      dispatch(Creators.updateLoan(id, data, redirect));
    },
    fetchLoan: (id) => {
      dispatch(Creators.getLoan(id));
    },
    fetchUsers: () => {
      const user = getLocalState('lsu-user-details')
      dispatch(UsersCreators.fetchUsers(user && user.organization_id && user.organization_id._id));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdateLoan);
