import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/loans/actions';
import { Creators as UserCreators } from '../../../services/redux/users/actions';
import { Creators as ReportsCreators } from '../../../services/redux/reports/actions';
import ListComponent from "./components";
import '../../../styles/repayments.less';

const mapStateToProps = state => {
  const { loan, isloading, loan_repayments, isFetchingRepayments, page, pageCount } = state.loans;
  return {
    loan,
    isloading,
    page,
    pageCount,
    repayments: loan_repayments,
    isFetchingRepayments,
    isFetchingUser: state.users.isloading,
    user: state.users.user,
    repaymentsReport: state.reports.repaymentsReport
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPagination: () => {

    },
    fetchUser: (id) => {
      dispatch(UserCreators.getUser(id));
    },
    fetchLoan: (id) => {
      dispatch(Creators.getLoan(id));
    },
    fetchLoanRepayments: (id, query={page: 1}) => {
      dispatch(Creators.fetchLoanRepayments(id, query));
    },
    downloadRepaymentsCsv: (id, query={page: 1}) => {
      const _query = {
        isReport: true,
        ...query
      }
      dispatch(Creators.downloadRepaymentsCSV(id, _query));
    },
    extractRepaymentsReport: (org_id, user_id, query={page: 1}) => {
      dispatch(ReportsCreators.extractRepaymentsReport(org_id, user_id, query));
    },
    addComment: (loan_id, comment) => {
      let redirect = false
      dispatch(Creators.updateLoan(loan_id, { notes: comment }, redirect));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
