import React, { useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  TreeSelect,
} from "antd";
import { withFormik, Field, FormikProps } from "formik";
import Spinner from "../../../reusable-components/Spinner";
import { InputField } from "../../../reusable-components/InputField";
import {
  reports_filter_options,
  dashboard_status_options,
} from "../../../util/helpers/form-field-options";
import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;

const Filters = ({ isloading, handleChange, ...props }) => {
  const [period, setPeriod] = useState("months");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const handleSelect = (val) => {
    setPeriod(val);
    handleChange(start_date, end_date, val);
  };

  return (
    <>
      <div className="dates-container" style={{ justifyContent: "flex-end" }}>
        {/*<Select
          name="status"
          size="large"
          style={{ width: "400px", marginRight: '0px' }}
          loading={isloading}
          showSearch
          placeholder="filter by status"
          optionFilterProp="children"
          onChange={(value) => {
            handleSelect(value);
          }}
          value={period}
          filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          { dashboard_status_options.map((option, index) => (
              <Option key={index} value={option.value}>{option.label}</Option>
            ))
          }
        </Select>*/}
        <div className="date-picker-customer">
          <span className="label">From Date</span>
          <DatePicker
            name="from_date"
            format="YYYY-MM-DD"
            showTime={false}
            size="large"
            className="filters-date-picker"
            onChange={(date, dateString) => {
              setStartDate(dateString);
              handleChange(dateString, end_date, period);
            }}
            disabledDate={(current) => {
              // disable future dates
              const today = moment();
              return current && current > moment(today, "YYYY-MM-DD");
            }}
          />
        </div>
        <div className="date-picker-customer">
          <span className="label">To Date</span>
          <DatePicker
            name="to_date"
            format="YYYY-MM-DD"
            showTime={false}
            size="large"
            className="filters-date-picker"
            onChange={(date, dateString) => {
              setEndDate(dateString);
              handleChange(start_date, dateString, period);
            }}
            disabledDate={(current) => {
              // disable future dates
              const today = moment();
              return current && current > moment(today, "YYYY-MM-DD");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Filters;
