import { call, put, takeLeading, takeEvery } from "redux-saga/effects";
import { message } from "antd";
import { Creators, Types } from "../../redux/loans/actions";
import {
	createLoan as createLoanApi,
	updateLoan as updateLoanApi,
	fetchLoans as fetchLoansApi,
	fetchAllLoans as fetchAllLoansApi,
	fetchUserLoans as fetchUserLoansApi,
	getLoan as getLoanApi,
	fetchLoanRepayments as fetchLoanRepaymentsApi,
	fetchDefaulters as fetchDefaultersApi,
	fetchRoLoansSummary as fetchRoLoansSummaryApi,
} from "../../api/loans";
import { history } from "../../../util/helpers/browserHistory";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";
import {
	generateFormData,
	getLocalState,
} from "../../../util/helpers/reusable-functions";
import get from "lodash/get";
import csvDownload from "json-to-csv-export";
import moment from "moment";

const user = getLocalState("lsu-user-details");

export function* createLoan(actions) {
	try {
		const { payload } = actions;
		const formData = generateFormData(payload);
		const response = yield call(createLoanApi, formData);
		const resp = response && response.data;
		yield put(Creators.createLoanSuccess(resp));
		OpenNotificationWithIcon(
			"success",
			"Create Loan Success!",
			"Loan has been created successfully"
		);
		history.goBack();
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"Create Loan Failed!",
			errorsFound && errorsFound.message
		);
		yield put(Creators.createLoanFailure(errorsFound));
	}
}

export function* updateLoan(actions) {
	try {
		const { id, payload, redirect } = actions;
		const formData = generateFormData(payload);
		const response = yield call(updateLoanApi, id, formData);
		const resp = response && response.data;
		yield put(Creators.updateLoanSuccess(resp && resp.doc));
		OpenNotificationWithIcon(
			"success",
			"Update Loan Success!",
			"Loan has been updated successfully"
		);
		if (redirect) {
			console.log("history ", history);
			if (get(history, "location.pathname") === "/loans")
				yield put(
					Creators.fetchLoans(
						user && user.organization_id && user.organization_id._id
					)
				);
			else history.push("/loans");
		}
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"Loan Update Failed!",
			errorsFound && errorsFound.message
		);
		yield put(Creators.updateLoanFailure(errorsFound));
	}
}

export function* getLoan(actions) {
	try {
		const { id } = actions;
		const response = yield call(getLoanApi, id);
		const resp = response && response.data;
		yield put(Creators.getLoanSuccess(resp && resp.doc));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		console.log("error ", error);
		yield put(Creators.getLoanFailure(errorsFound));
	}
}

export function* fetchLoans(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchLoansApi, org_id, query);
		const resp = response && response.data;
		console.log("fetchLoans response ", resp);
		yield put(
			Creators.fetchLoansSuccess(
				get(resp, "doc"),
				get(resp, "page"),
				get(resp, "pageCount")
			)
		);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		console.log("errorsFound ", error);
		yield put(Creators.fetchLoansFailure(errorsFound));
	}
}

export function* fetchAllLoans(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchAllLoansApi, org_id, query);
		const resp = response && response.data;
		console.log("fetchAllLoans response ", resp);
		yield put(
			Creators.fetchAllLoansSuccess(
				get(resp, "doc"),
				get(resp, "page"),
				get(resp, "pageCount")
			)
		);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		console.log("errorsFound ", error);
		yield put(Creators.fetchAllLoansFailure(errorsFound));
	}
}

export function* fetchUserLoans(actions) {
	try {
		const { user_id } = actions;
		const response = yield call(fetchUserLoansApi, user_id);
		const resp = response && response.data;
		yield put(Creators.fetchLoansSuccess(resp && resp.doc));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchLoansFailure(errorsFound));
	}
}

export function* fetchLoanRepayments(actions) {
	try {
		const { id, query } = actions;
		const response = yield call(fetchLoanRepaymentsApi, id, query);
		const resp = response && response.data;
		yield put(
			Creators.fetchLoanRepaymentsSuccess(
				resp && resp.doc,
				resp && resp.page,
				resp && resp.pageCount
			)
		);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchLoanRepaymentsFailure(errorsFound));
	}
}

export function* downloadRepaymentsCVS(actions) {
	try {
		const { id, query } = actions;
		const response = yield call(fetchLoanRepaymentsApi, id, query);
		csvDownload(
			get(response, "data.doc"),
			`repayments-${moment().format("YYYY-MM-DD HH:MM:SS")}.csv`
		);
		yield put(Creators.downloadCSVSuccess(get(response, "data.doc")));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.downloadCSVFailure(errorsFound));
		OpenNotificationWithIcon(
			"error",
			"Failed to download csv!",
			errorsFound && errorsFound.message
		);
	}
}

export function* fetchDefaulters(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchDefaultersApi, org_id, query);
		const resp = response && response.data;
		yield put(
			Creators.fetchDefaultersSuccess(
				get(resp, "doc"),
				get(resp, "page"),
				get(resp, "pageCount")
			)
		);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchDefaultersFailure(errorsFound));
	}
}

export function* downloadCVS(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchDefaultersApi, org_id, query);
		csvDownload(
			get(response, "data.doc"),
			`defaulters-${moment().format("YYYY-MM-DD HH:MM:SS")}.csv`
		);
		yield put(Creators.downloadCSVSuccess(get(response, "data.doc")));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.downloadCSVFailure(errorsFound));
		OpenNotificationWithIcon(
			"error",
			"Failed to download csv!",
			errorsFound && errorsFound.message
		);
	}
}

export function* fetchRoLoansSummary(actions) {
	try {
		const { id, query } = actions;
		const response = yield call(fetchRoLoansSummaryApi, id, query);
		const resp = response && response.data;
		yield put(
			Creators.fetchRoLoansSummarySuccess(
				resp && resp.doc,
				resp && resp.page,
				resp && resp.pageCount
			)
		);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchRoLoansSummaryFailure(errorsFound));
	}
}

export function* watchFetchLoans() {
	yield takeEvery(Types.FETCH_LOANS, fetchLoans);
}

export function* watchFetchAllLoans() {
	yield takeEvery(Types.FETCH_ALL_LOANS, fetchAllLoans);
}

export function* watchFetchUserLoans() {
	yield takeEvery(Types.FETCH_USER_LOANS, fetchUserLoans);
}

export function* watchGetLoan() {
	yield takeEvery(Types.GET_LOAN, getLoan);
}

export function* watchCreateLoan() {
	yield takeEvery(Types.CREATE_LOAN, createLoan);
}

export function* watchUpdateLoan() {
	yield takeEvery(Types.UPDATE_LOAN, updateLoan);
}

export function* watchFetchLoanRepayments() {
	yield takeEvery(Types.FETCH_LOAN_REPAYMENTS, fetchLoanRepayments);
}

export function* watchFetchDefaulters() {
	yield takeEvery(Types.FETCH_DEFAULTERS, fetchDefaulters);
}

export function* watchDownloadCVS() {
	yield takeLeading(Types.DOWNLOAD_CSV, downloadCVS);
}

export function* watchDownloadRepaymentsCVS() {
	yield takeLeading(Types.DOWNLOAD_REPAYMENTS_CSV, downloadRepaymentsCVS);
}

export function* watchFetchRoLoansSummary() {
	yield takeEvery(Types.FETCH_RO_LOANS_SUMMARY, fetchRoLoansSummary);
}
