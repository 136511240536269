import axios from 'axios';
import { BASE_URL } from './urls';
import decode from 'jwt-decode';
import { getLocalState } from '../../util/helpers/reusable-functions';

export const getToken = () => {
  let token = getLocalState('lsu-user-access', true);
  console.log("token ", token);
  return token;
}

export const getUserDetails = () => {
  let user = getLocalState('lsu-user-details');
  return user;
}

export const getUserRole = () => {
  let user = getUserDetails();
  return user && user.role;
}

export const getUser = () => {
  const { user_id } = decode(getToken());
  return user_id;
}

export const refreshToken = () => {

}

export const axiosInstance = () => {
  var AUTH_TOKEN = getToken();
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + AUTH_TOKEN
    }
  });
  // maybe we can add an interceptor, which checks if token is expired and refreshes it.

  return instance;
}

// for endpoints that do not require tokens
export default axios.create({
  baseURL: BASE_URL
});

// this is for other request types i.e application/pdf or formdata etc
export const axiosInstanceOthers = (headerType, responseType) => {
  var AUTH_TOKEN = getToken();
  console.log(headerType)
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: headerType,
      Authorization: 'Bearer ' + AUTH_TOKEN,
      'Content-Type': 'multipart/form-data'
    },
    responseType: responseType,
  });

  return instance;
}