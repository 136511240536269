import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/users/actions';
import DetailsComponent from "./components";

const mapStateToProps = state => {
  const { user, isloading } = state.users;
  return {
    isloading,
    user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: (id) => {
      dispatch(Creators.getUser(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsComponent);

