import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import { InputField } from '../../../reusable-components/InputField';
import Spinner from '../../../reusable-components/Spinner';
import { validationSchemaSignUpForm } from '../../../util/validations';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class SignUpForm extends Component {

  componentDidMount() {
    const {reset} = this.props;
    reset();
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Sign Up";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <Form onSubmit={handleSubmit} {...formItemLayout}>
          <Row>
            <Col span={24}><h2>Sign Up</h2></Col>
            <Col md={24} sm={24}>
            	<Field
                name='firstName'
                label="First Name"
                placeholder="first name"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
            <Col md={24} sm={24}>
              <Field
                name='lastName'
                label="Last Name"
                placeholder="last name"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
            <Col md={24} sm={24}>
              <Field
                name='email'
                label="Email"
                placeholder="email"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
            <Col md={24} sm={24}>
            	<Field
            		type="password"
                name='password'
                label="Password"
                placeholder="enter password"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
            <Col md={24} sm={24}>
              <Field
                type="password"
                name='confirmPassword'
                label="Confirm Password"
                placeholder="confirm password"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem className="form-btns-container">
                <Row>
                  <Col>
                    <button type="submit" className="auth-buttons">{buttonState}</button>
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

SignUpForm.propTypes = {
  isloading: PropTypes.bool,
}

const SignUp = withFormik({
  validationSchema: validationSchemaSignUpForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    delete values.confirmPassword
    values.name = values.firstName + ' ' + values.lastName
    values.role = 'admin'
    props.signUpUser(values);
  },

  displayName: 'signup-form',
  enableReinitialize: false
})(SignUpForm);

export default SignUp;

