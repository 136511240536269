import { apiRequest, apiRequestOthers } from '../apiRequest';
import { REPAYMENTS_ENDPOINT } from '../urls';
import moment from 'moment';

export const fetchRepayments = (query={ is_draft: false }) => {
	let search_term = query && query.search_term
	let limit = query && query.limit
	let offset = query && query.offset

	return apiRequest('GET', `${REPAYMENTS_ENDPOINT}?
		${ search_term ? `&search=${search_term}`: '' }
		${ limit ? `&limit=${limit}`: '' }
		${ offset ? `&offset=${offset}`: '' }`);
}

export const getRepayment = (id) => { 
	return apiRequest('GET', `${REPAYMENTS_ENDPOINT}/${id}/`);
}

export const createRepayment = data => {
	return apiRequest('POST', REPAYMENTS_ENDPOINT, data);
}

export const updateRepayment = (id, data) => {
	return apiRequest('PATCH', `${REPAYMENTS_ENDPOINT}/${id}/`, data);
}

export const fetchRepayment = (id) => {
	return apiRequest('GET', `${REPAYMENTS_ENDPOINT}/${id}/`);
}

// i think this is missing currently...
export const fetchUserRepayments = (id) => {
	return apiRequest('GET', `${REPAYMENTS_ENDPOINT}/user/${id}/`); 
}

