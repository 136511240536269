import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  fetchReports: ['query'],
  fetchReportsSuccess: ['responseData'],
  fetchReportsFailure: ['error'],

  extractRepaymentsReport: ['org_id', 'user_id', 'query'],
  extractRepaymentsReportSuccess: ['responseData'],
  extractRepaymentsReportFailure: ['error'],

  reset: null

}, {});
