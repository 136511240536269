import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/users/actions';
import ListComponent from "./components";
import { getLocalState } from '../../../util/helpers/reusable-functions';

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
  const { users, isloading, page, pageCount } = state.users;
  return {
    users,
    isloading,
    page,
    pageCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPagination: () => { 
      dispatch(Creators.reset());
    },
    fetchUsers: (query={page: 1}) => {
      dispatch(Creators.fetchUsers(user && user.organization_id && user.organization_id._id, query));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
