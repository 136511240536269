import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/loans/actions';
import ListComponent from "./components";
import { getLocalState } from '../../../util/helpers/reusable-functions';

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
  const { defaulters, isloading, page, pageCount, csvData, isDownloading } = state.loans;
  return {
    defaulters,
    isloading,
    page,
    pageCount,
    csvData,
    isDownloading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPagination: () => {

    },
    fetchDefaulters: (query={page: 1}) => {
      dispatch(Creators.fetchDefaulters(user && user.organization_id && user.organization_id._id, query));
    },
    downloadCSV: (query) => {
      const _query = {
        isReport: true,
        ...query
      }
      dispatch(Creators.downloadCSV(user && user.organization_id && user.organization_id._id, _query));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
