import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  FETCH_REPORTS
  FETCH_REPORTS_SUCCESS
  FETCH_REPORTS_FAILURE

  EXTRACT_REPAYMENTS_REPORT
  EXTRACT_REPAYMENTS_REPORT_SUCCESS
  EXTRACT_REPAYMENTS_REPORT_FAILURE

  RESET

`, {});
