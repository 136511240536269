import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, DatePicker } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { DateField } from '../../../../reusable-components/DateField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaStepTwoForm } from '../../../../util/validations';
import { gender_options, role_option, account_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import { history } from '../../../../util/helpers/browserHistory';

const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateUserForm extends Component {

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location, goBack, moveNext } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    console.log("Values are ", this.props)

    return (
      <>
          <PageContent 
            title="Address of the Applicant"
            goBack={goBack}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container custom-stepper-content"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            showFooter={true}
            hasBackBtn={true}
            hasNextBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            formClassNames=""
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col span={24}>
                  <div>
                    <i><b>Current Address of the Applicant</b></i>
                    <Row gutter={30}>
                      <Col md={12} sm={24}>
                        <Field
                          name='current_address.village'
                          label="Village"
                          placeholder="village"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <Field
                          name='current_address.parish'
                          label="Parish"
                          placeholder="parish"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <Field
                          name='current_address.sub_county'
                          label="Sub-county"
                          placeholder="sub-county"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <Field
                          name='current_address.district'
                          label="District"
                          placeholder="district"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <i>Period at the address</i>
                        <Row gutter={15}>
                          <Col md={8} sm={24}>
                            <Field
                              type="number"
                              name='period_at_current_address.years'
                              label="Years"
                              placeholder="years"
                              size="large"
                              component={InputField}
                              formerrors={formerrors}
                            />
                          </Col>
                          <Col md={8} sm={24}>
                            <Field
                              type="number"
                              name='period_at_current_address.months'
                              label="Months"
                              placeholder="months"
                              size="large"
                              component={InputField}
                              formerrors={formerrors}
                            />
                          </Col>
                          <Col md={8} sm={24}>
                            <Field
                              type="number"
                              name='period_at_current_address.days'
                              label="Days"
                              placeholder="days"
                              size="large"
                              component={InputField}
                              formerrors={formerrors}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} sm={24}>
                        <i>Chairperson details</i>
                        <Row gutter={15}>
                          <Col md={12} sm={24}>
                            <Field
                              name='chairperson.name'
                              label="Name"
                              placeholder="name"
                              size="large"
                              component={InputField}
                              formerrors={formerrors}
                            />
                          </Col>
                          <Col md={12} sm={24}>
                            <Field
                              type="number"
                              name='chairperson.phone'
                              label="Contact"
                              placeholder="Contact"
                              size="large"
                              component={InputField}
                              formerrors={formerrors}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
                { values && values.period_at_current_address && values.period_at_current_address.years < 2 && 
                  (<Col span={24}>
                    <div>
                      <i><b>If less than 2 years, provide details of the previous address</b></i>
                      <Row gutter={30}>
                        <Col md={12} sm={24}>
                          <Field
                            name='previous_address.village'
                            label="Village"
                            placeholder="village"
                            size="large"
                            component={InputField}
                            formerrors={formerrors}
                          />
                        </Col>
                        <Col md={12} sm={24}>
                          <Field
                            name='previous_address.parish'
                            label="Parish"
                            placeholder="parish"
                            size="large"
                            component={InputField}
                            formerrors={formerrors}
                          />
                        </Col>
                        <Col md={12} sm={24}>
                          <Field
                            name='previous_address.sub_county'
                            label="Sub-county"
                            placeholder="sub-county"
                            size="large"
                            component={InputField}
                            formerrors={formerrors}
                          />
                        </Col>
                        <Col md={12} sm={24}>
                          <Field
                            name='previous_address.district'
                            label="District"
                            placeholder="district"
                            size="large"
                            component={InputField}
                            formerrors={formerrors}
                          />
                        </Col>
                        <Col md={12} sm={24}>
                          <i>Period at the address</i>
                          <Row gutter={15}>
                            <Col md={8} sm={24}>
                              <Field
                                type="number"
                                name='period_at_previous_address.years'
                                label="Years"
                                placeholder="years"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                            <Col md={8} sm={24}>
                              <Field
                                type="number"
                                name='period_at_previous_address.months'
                                label="Months"
                                placeholder="months"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                            <Col md={8} sm={24}>
                              <Field
                                type="number"
                                name='period_at_previous_address.days'
                                label="Days"
                                placeholder="days"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                            <Col md={8} sm={24}></Col> 
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>)
                }
              </Row>
          </PageContent>
      </>
    );
  }
}

CreateUpdateUserForm.propTypes = {
  isloading: PropTypes.bool,
}

const StepTwo = withFormik({
  validationSchema: validationSchemaStepTwoForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log("Current Address 1", values)
    props.storeUserDataChunks(values);
    props.moveNext();
  },

  displayName: 'create-user-form',
  enableReinitialize: true
})(CreateUpdateUserForm);

export default StepTwo;

