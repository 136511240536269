import { call, put, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/auth/actions";
import {
	login as loginApi,
	signUp as signUpApi,
	resetPassword as resetPasswordApi,
	forgotPassword as forgotPasswordApi,
	changePassword as changePasswordApi,
	getUserDetails as userDetailsApi,
} from "../../api/auth";
import {
	storeLocalState,
	clearLocalState,
	generateFormData,
} from "../../../util/helpers/reusable-functions";
import { history } from "../../../util/helpers/browserHistory";
import { OpenNotificationWithIcon } from "../../../reusable-components/Notifications";

export function* login(actions) {
	try {
		const { email, password } = actions;

		const formData = generateFormData({ email, password });
		// const response = yield call(loginApi, { email, password });
		const response = yield call(loginApi, formData);
		console.log("Data is :", response);
		const resp = response?.data;

		storeLocalState("lsu-user-access", resp && resp?.token);
		storeLocalState("lsu-user-details", resp && resp?.user);

		// set this to true/false depending on if the user has an organization attached, if not and they are admin, then redirect to `Create Organization`
		storeLocalState("company-set-up-complete", true);
		yield put(Creators.loginSuccess(resp));
		OpenNotificationWithIcon(
			"success",
			"Logged in successfully!",
			`It's great to have you back ${resp && resp.user && resp?.user?.name}`,
		);

		window.location.href = "/dashboard";
	} catch (e) {
		console.log("error here is ", e);
		const message = e?.response?.data?.message || "Invalid Credentials";
		OpenNotificationWithIcon("error", "Login Failed!", message);
		yield put(Creators.loginFailure(message));
	}
}

export function* signUp(actions) {
	try {
		const { payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(signUpApi, payload);
		const response = yield call(signUpApi, formData);
		const resp = response && response.data;

		storeLocalState("lsu-user-access", resp && resp.token);
		storeLocalState("lsu-user-details", resp && resp.user);
		storeLocalState("company-set-up-complete", false);

		yield put(Creators.signUpSuccess(resp));
		//history.push('/setup/organization');
		window.location.href = "/setup/organization";
	} catch (e) {
		const message = "Invalid Credentials";
		OpenNotificationWithIcon("error", "Sign Up failed", message);
		yield put(Creators.signUpFailure(message));
	}
}

export function* forgotPassword(actions) {
	try {
		const { email } = actions;
		const formData = generateFormData({ email });
		// const response = yield call(forgotPasswordApi, { email });
		const response = yield call(forgotPasswordApi, formData);
		const data = response.data;
		const message =
			"An email has been sent to your inbox. Kindly click on the link in it to complete your password change.";
		yield put(Creators.forgotPasswordSuccess(message));
		OpenNotificationWithIcon("success", "Forgot Password!", message);
	} catch (e) {
		const message =
			(e && e.response.data && e.response.data.message) ||
			"Failed to send email";
		OpenNotificationWithIcon("error", "Forgot Password!", message);
		yield put(Creators.forgotPasswordFailure(message));
	}
}

export function* resetPassword(actions) {
	try {
		const { token, password } = actions;
		const formData = generateFormData({ password });
		// const response = yield call(resetPasswordApi, token, { password });
		const response = yield call(resetPasswordApi, token, formData);
		const data = response.data;
		const message =
			"An email has been sent to your inbox. Kindly click on the link in it to complete your password change.";
		yield put(Creators.resetPasswordSuccess(message));
		OpenNotificationWithIcon("success", "Password Reset!", message);
	} catch (e) {
		const message =
			(e && e.response.data && e.response.data.message) ||
			"Failed to reset password.";
		OpenNotificationWithIcon("error", "Password Reset!", message);
		yield put(Creators.resetPasswordFailure(message));
	}
}

export function* changePassword(actions) {
	try {
		const { id, payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(changePasswordApi, id, payload);
		const response = yield call(changePasswordApi, id, formData);
		const data = response.data;

		const message = "Password has been changed successfully.";
		yield put(Creators.changePasswordSuccess(message));
		OpenNotificationWithIcon("success", "Password Change!", message);
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon(
			"error",
			"Password Change!",
			errorsFound && errorsFound.message,
		);
		yield put(
			Creators.changePasswordFailure(errorsFound && errorsFound.message),
		);
	}
}

export function* logout() {
	// clear localStorage
	clearLocalState("lsu-user-access");
	clearLocalState("lsu-user-details");
	clearLocalState("company-set-up-complete");

	yield put(Creators.logout());

	// redirect to landing page
	window.location.href = "/";
}

export function* watchLogin() {
	yield takeLeading(Types.LOGIN_REQUEST, login);
}

export function* watchSignUp() {
	yield takeLeading(Types.SIGN_UP_REQUEST, signUp);
}

export function* watchForgotPassword() {
	yield takeLeading(Types.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
	yield takeLeading(Types.RESET_PASSWORD, resetPassword);
}

export function* watchChangePassword() {
	yield takeLeading(Types.CHANGE_PASSWORD_REQUEST, changePassword);
}

export function* watchLogout() {
	yield takeLeading(Types.LOGOUT, logout);
}
