import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/auth/actions';
import ChangePassword from "./components";

const mapStateToProps = state => {
  const { isloading } = state.auth;
  return {
    isloading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (id, data) => {
      dispatch(Creators.changePasswordRequest(id, data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
