import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  isloading: false,
  isFetching: false,
  repayment: null,
  repayments: []
}

export const fetchRepayments = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchRepaymentsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, repayments: action.responseData, isloading: false }
}
 
export const fetchRepaymentsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const getRepayment = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetching: true }
}
 
export const getRepaymentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, repayment: action.responseData, isFetching: false }
}
 
export const getRepaymentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, errors: action.error }
}

export const createRepayment = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const createRepaymentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, repayment: action.responseData, isloading: false }
}
 
export const createRepaymentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const updateRepayment = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const updateRepaymentSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, repayment: action.responseData, isloading: false }
}
 
export const updateRepaymentFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: null, repayment: { amount_paid: 0, paid_against: '', received_by: '' } }
}
  
export const HANDLERS = {
  [Types.FETCH_REPAYMENTS]: fetchRepayments,
  [Types.FETCH_REPAYMENTS_SUCCESS]: fetchRepaymentsSuccess,
  [Types.FETCH_REPAYMENTS_FAILURE]: fetchRepaymentsFailure,
  [Types.CREATE_REPAYMENT]: createRepayment,
  [Types.CREATE_REPAYMENT_SUCCESS]: createRepaymentSuccess,
  [Types.CREATE_REPAYMENT_FAILURE]: createRepaymentFailure,
  [Types.GET_REPAYMENT]: getRepayment,
  [Types.GET_REPAYMENT_SUCCESS]: getRepaymentSuccess,
  [Types.GET_REPAYMENT_FAILURE]: getRepaymentFailure,
  [Types.UPDATE_REPAYMENT]: updateRepayment,
  [Types.UPDATE_REPAYMENT_SUCCESS]: updateRepaymentSuccess,
  [Types.UPDATE_REPAYMENT_FAILURE]: updateRepaymentFailure,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
