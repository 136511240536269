import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import {  getUserRole } from './services/api/axiosDefaults';
import { history } from './util/helpers/browserHistory';
import Can from './util/helpers/Can';
import AuthContainer from './containers/Auth';
import ResetContainer from './containers/Auth/components/ResetPassword';
import Dashboard from './containers/Dashboard';
import Permission from './containers/Permission';
import Notfound from './containers/NotFound';
import Layout from "./containers/Layout";
import CreateUpdateOrganization from './containers/Organizations/create-update-organization';
import Users from './containers/Users/list-users';
import ViewUser from './containers/Users/view-user';
import CreateUpdateUser from './containers/Users/create-update-user';
import CreateUpdateClient from './containers/Users/create-update-client';
import CreateUpdateRepayment from './containers/Repayments/create-update-repayment';
import Loans from './containers/Loans/list-loans';
import AllLoans from './containers/Loans/all-loans';
import SelectClient from './containers/Loans/select-client';
import Defaulters from './containers/Loans/list-defaulters';
import RelationshipOfficers from './containers/Loans/list-relationship-officers';
import CreateUpdateLoan from './containers/Loans/create-update-loan';
import ViewClientLoans from './containers/Loans/view-client-loans';
import ViewLoanClients from './containers/Repayments/view-loan-repayments';
import ViewReports from './containers/Reports';
import ViewProfile from './containers/Profile/view-profile';
import ChangePassword from './containers/Profile/change-password';
import CreateUpdateCashManagement from './containers/CashManagement/create-update';
import ViewCashManagement from './containers/CashManagement/view';
import { messageConfig } from './util/configs';
import { notificationConfig } from './util/configs';
import checkAuth from "./util/helpers/checkAuth";
import store from './store';
import { Creators } from './services/redux/auth/actions';

const role = getUserRole();
let userActivityTimeout = null;
let userActivityThrottlerTimeout = null;
const USER_ACTIVITY_THROTTLER_TIME = 5000;
const INACTIVE_USER_TIME_THRESHOLD = 300000;
// const INACTIVE_USER_TIME_THRESHOLD = 60000;

// Loan validation failed: notes.2._id: Cast to ObjectID failed for value "[ '[object Object]', '[object Object]' ]" at path "_id"

const AuthenticatedRoute = ({ component: Component, perform,  ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkAuth() ? (
        <Can
          role={role}
          perform={perform}
          yes={() => (
            <Component {...props} />
          )}
          no={() =>  <Redirect to={{ pathname: "/permission" }} />}
        />
      
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    } />
);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.activateActivityTracker();
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);
  
    clearTimeout(userActivityTimeout);
    clearTimeout(userActivityThrottlerTimeout);
  }

  resetUserActivityTimeout = () => {
    clearTimeout(userActivityTimeout);
    userActivityTimeout = setTimeout(() => {
      store.dispatch(Creators.logout());
    }, INACTIVE_USER_TIME_THRESHOLD);
  }

  userActivityThrottler = () => {
    if (!userActivityThrottlerTimeout) {
      userActivityThrottlerTimeout = setTimeout(() => {
        this.resetUserActivityTimeout();

        clearTimeout(userActivityThrottlerTimeout);
        userActivityThrottlerTimeout = null;
      }, USER_ACTIVITY_THROTTLER_TIME);
    }
  }

  activateActivityTracker = () => {
    window.addEventListener("mousemove", this.userActivityThrottler);
    window.addEventListener("scroll", this.userActivityThrottler);
    window.addEventListener("keydown", this.userActivityThrottler);
    window.addEventListener("resize", this.userActivityThrottler);
  }

  render() {
    messageConfig();
    notificationConfig();
   
    return (
      <Router history={history}>
        <Layout>
          <Switch>
            <Route path={["/", "/signup", "/forgot/password"]} exact component={AuthContainer} />
            <Route path={"/reset/:token"} exact component={ResetContainer} />
            <AuthenticatedRoute path={"/view/profile"} exact component={ViewProfile} perform="profile:view" />
            <AuthenticatedRoute path={"/change-password"} exact component={ChangePassword} perform="password:change" />
            <AuthenticatedRoute path={"/setup/organization"} exact component={CreateUpdateOrganization} perform="organization:edit" />
            <AuthenticatedRoute path={["/dashboard", "/home"]} exact component={Dashboard} perform="dashboard:view" />
            <AuthenticatedRoute path={["/update/org/:id", "/create/org"]} exact component={CreateUpdateOrganization} perform="dashboard:view" />
            <AuthenticatedRoute path={"/permission"} exact component={Permission} perform="permission:view" />
            <AuthenticatedRoute path={["/users", "/clients"]} exact component={Users} perform={["user:list", "client:list"]} />
            <AuthenticatedRoute path={["/view/user/:id"]} exact component={ViewUser} perform={["user:view"]} />
            <AuthenticatedRoute path={["/update/user/:id", "/create/user"]} exact component={CreateUpdateUser} perform={["user:edit", "user:create"]} />
            <AuthenticatedRoute path={["/update/client/:id", "/create/client"]} exact component={CreateUpdateClient} perform={["client:edit", "client:create"]} />
            <AuthenticatedRoute path={"/loans"} exact component={Loans} perform="loan:list" />
            <AuthenticatedRoute path={"/all/loans"} exact component={AllLoans} perform="all-loans:list" />
            <AuthenticatedRoute path={"/select/client"} exact component={SelectClient} perform="loan:create" />
            <AuthenticatedRoute path={"/defaulters"} exact component={Defaulters} perform="loan:list" />
            <AuthenticatedRoute path={"/relationship/officers"} exact component={RelationshipOfficers} perform="loan:list" />
            <AuthenticatedRoute path={["/update/loan/:id", "/create/loan"]} exact component={CreateUpdateLoan} perform={["loan:edit", "loan:create"]} />
            <AuthenticatedRoute path={"/view/loans/client/:id"} exact component={ViewClientLoans} perform="loan:view" />
            <AuthenticatedRoute path={"/repayments/loan/:id"} exact component={ViewLoanClients} perform="repayment:list" />
            <AuthenticatedRoute path={["/update/repayment/:id", "/create/repayment"]} exact component={CreateUpdateRepayment} perform={["repayment:edit", "repayment:create"]} />
            <AuthenticatedRoute path={"/reports"} exact component={ViewReports} perform="reports:view" />
            <AuthenticatedRoute path={["/cash-management/create", "cash-management/update"]} exact component={CreateUpdateCashManagement} perform="cash-management:create" />
            <AuthenticatedRoute path={"/cash-management/view"} exact component={ViewCashManagement} perform="cash-management:view" />
            <Route component={Notfound} />
          </Switch>
        </Layout>
      </Router>
    );
  }
}
