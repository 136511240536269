import React, { Fragment, Component } from "react";
import { Card, Col, Row, Divider } from "antd";
import { Line } from "react-chartjs-2";
import PageContent from "../../../reusable-components/PageContent";
import Spinner from "../../../reusable-components/Spinner";
import Filters from "./filters";
import {
  getLocalState,
  printDocument,
  numberWithCommas,
} from "../../../util/helpers/reusable-functions";
import "../../../styles/dashboard.less";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchStatistics, resetStats } = this.props;
    resetStats();
    fetchStatistics("months");
  }

  render() {
    const { statistics, isloading, fetchStatistics } = this.props;

    let loans_released_graph_data = {
      labels: statistics && statistics.labels,
      datasets: [
        {
          label: "Amount released",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgb(205, 130, 158)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.loans_released,
        },
      ],
    };

    let collections_data = {
      labels: statistics && statistics.labels,
      datasets: [
        {
          label: "Amount collected",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgb(205, 130, 158)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.collections,
        },
      ],
    };

    let fully_paid_vs_open_loans_data = {
      labels: statistics && statistics.labels,
      datasets: [
        {
          label: "Fully paid loans",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "green",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.fully_paid_loans_data,
        },
        {
          label: "Open loans",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "gray",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(35, 26, 136)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.open_loans_data,
        },
        {
          label: "Cancelled loans",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "red",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(35, 26, 136)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.cancelled_loans_data,
        },
        {
          label: "Pending loans",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "orange",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(35, 26, 136)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.pending_loans_data,
        },
      ],
    };

    let defaulted_loans_data = {
      labels: statistics && statistics.labels,
      datasets: [
        {
          label: "Number defaulted",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgb(205, 130, 158)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: statistics && statistics.defaulted_loans_data,
        },
      ],
    };

    return (
      <>
        <PageContent
          title="Dashboard"
          showExportBtn={true}
          exportBtnLabel="Export Graph Data"
          exportData={() => printDocument("graphdiv", "graph.pdf", 550, 550)}
          isFilterContainer={true}
        >
          <Row>
            <Col span={24}>
              <Filters
                handleChange={(from_date, to_date, period) => {
                  fetchStatistics(period, from_date, to_date);
                }}
              />
            </Col>
          </Row>
        </PageContent>
        <PageContent>
          <div id="graphdiv">
            <Row>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Total loans released</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.total_loans_released
                            )}
                          </span>{" "}
                          <span className="currency">UGX</span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Total Repayments</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.total_loans_collections
                            )}
                          </span>{" "}
                          <span className="currency">UGX</span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Fully Paid Loans</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.fully_paid_loans
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Open Loans</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.open_loans
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Pending Loans</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.pending_loans
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Cancelled Loans</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.cancelled_loans
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="summary-cards">
                  <div className="card-info">
                    <p>Defaulted Loans</p>
                    <p className="no-padding">
                      {isloading && <Spinner />}
                      {!isloading && (
                        <>
                          <span className="price">
                            {numberWithCommas(
                              statistics && statistics.defaulted_loans
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={24}>
                <div className="line-graph">
                  <h3 className="graph-title">Total loans released Graph</h3>
                  <Line
                    data={loans_released_graph_data}
                    options={{ responsive: true }}
                  />
                </div>
              </Col>
              <Col md={12} sm={24}>
                <div className="line-graph">
                  <h3 className="graph-title">Total collections Graph</h3>
                  <Line
                    data={collections_data}
                    options={{ responsive: true }}
                  />
                </div>
              </Col>
              <Col md={12} sm={24}>
                <div className="line-graph">
                  <h3 className="graph-title">
                    Fully paid Vs open loans Graph
                  </h3>
                  <Line
                    data={fully_paid_vs_open_loans_data}
                    options={{ responsive: true }}
                  />
                </div>
              </Col>
              <Col md={12} sm={24}>
                <div className="line-graph">
                  <h3 className="graph-title">Defaulted loans Graph</h3>
                  <Line
                    data={defaulted_loans_data}
                    options={{ responsive: true }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </PageContent>
      </>
    );
  }
}

export default Dashboard;
