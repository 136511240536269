import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  FETCH_STATISTICS
  FETCH_STATISTICS_SUCCESS
  FETCH_STATISTICS_FAILURE

  RESET_STATS

`, {});
