import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import { InputField } from '../../../reusable-components/InputField';
import Spinner from '../../../reusable-components/Spinner';
import { validationSchemaLoginForm } from '../../../util/validations';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class LoginForm extends Component {

  componentDidMount() {
    const {reset} = this.props;
    reset();
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, message, errors } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Login";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <Form onSubmit={handleSubmit} {...formItemLayout} autoComplete="off">
          <Row>
            <Col span={24}><h2>Login</h2></Col>
            <Col md={24} sm={24}>
            	<Field
                id="email"
                name='email'
                label="Email"
                placeholder="enter email"
                size="large"
                component={InputField}
                formerrors={formerrors}
                readOnly 
                onFocus={() => {
                  var _element = document.getElementById('email')
                  _element.removeAttribute('readonly')
                }}
              />
            </Col>
            <Col md={24} sm={24}>
            	<Field
                id="password"
            		type="password"
                name='password'
                label="Password"
                placeholder="enter password"
                size="large"
                component={InputField}
                formerrors={formerrors}
                readOnly 
                onFocus={() => {
                  var _element = document.getElementById('password')
                  _element.removeAttribute('readonly')
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem className="form-btns-container">
                <Row>
                  <Col>
                    <button type="submit" className="auth-buttons">{buttonState}</button>
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

LoginForm.propTypes = {
  isloading: PropTypes.bool,
}

const Login = withFormik({
  validationSchema: validationSchemaLoginForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    var _element1 = document.getElementById('email');
    var _element2 = document.getElementById('password');
    _element1.value = '';
    _element2.value = '';

  	props.loginUser(values.email, values.password);
  },

  displayName: 'login-form',
  enableReinitialize: false
})(LoginForm);

export default Login;

