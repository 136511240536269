import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../reusable-components/Spinner';
import { InputField } from '../../../reusable-components/InputField';
import { reports_filter_options } from '../../../util/helpers/form-field-options';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

const Filters = ({ isloading, handleChange }) => {
	const [searchTerm, setSearchTerm] = useState("")
  const [start_date, setStartDate] = useState("")
	const [end_date, setEndDate] = useState("")

	return ( 
		<>
      <div>
        <Row gutter={32}>
          <Col md={8} sm={12}>
            <div className="date-picker-customer">
              <span className="label">From Date</span>
              <DatePicker
                name="from_date"
                format="YYYY-MM-DD"
                showTime={false}
                size="large"
                className="filters-date-picker"
                onChange={(date, dateString) => {
                  console.log("Date and dateString are ", date, dateString)
                  setStartDate(dateString)
                  handleChange(searchTerm, dateString, end_date)
                }}
                disabledDate= {(current) => {
                  // disable future dates
                  const today = moment();
                  return current && current > moment(today, "YYYY-MM-DD");
                }}
              />
            </div>
          </Col>
          <Col md={8} sm={12}>
            <div className="date-picker-customer">
              <span className="label">To Date</span>
              <DatePicker
                name="to_date"
                format="YYYY-MM-DD"
                showTime={false}
                size="large"
                className="filters-date-picker"
                onChange={(date, dateString) => {
                  console.log("Date and dateString are ", date, dateString)
                  setEndDate(dateString)
                  handleChange(searchTerm, start_date, dateString)
                }}
                disabledDate= {(current) => {
                  // disable future dates
                  const today = moment();
                  return current && current > moment(today, "YYYY-MM-DD");
                }}
              />
            </div>
          </Col>
          <Col md={8} sm={24}>
            <span className="label">Search</span>
            <Select
              size="large"
              loading={isloading}
              showSearch
              placeholder="Select an option"
              optionFilterProp="children"
              className="search-input"
              onChange={(value) => {
                setSearchTerm(value)
                handleChange(value, start_date, end_date)
              }}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { reports_filter_options.map((option, index) => (
                  <Option key={index} value={option.value || option.code}>{option.label || option.name}</Option>
                ))
              }
            </Select>
          </Col>
        </Row>
      </div>
		</>
	);
}

export default Filters;

