import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/organizations/actions'
import {
	createOrganization as createOrganizationApi,
	updateOrganization as updateOrganizationApi,
	fetchOrganizations as fetchOrganizationsApi,
	getOrganization as getOrganizationApi,
} from '../../api/organizations';
import { history } from '../../../util/helpers/browserHistory';
import { storeLocalState, generateFormData } from '../../../util/helpers/reusable-functions';

export function* createOrganization(actions) {
	try {
		const { payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(createOrganizationApi, payload);
		const response = yield call(createOrganizationApi, formData);
		const resp = response && response.data;
		storeLocalState('organization-details', resp && resp.doc);
		yield put(Creators.createOrganizationSuccess(resp && resp.doc))

		window.location.href = '/complete/setup';
	} catch (error) {
		let errors = error.response && error.response.data;
		console.log("Errors found 1 ", error)
		console.log("Errors found ", errors)
		yield put(Creators.createOrganizationFailure(errors));
	}
}

export function* updateOrganization(actions) {
	try {
		const { id, payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(updateOrganization, id, payload);
		const response = yield call(updateOrganization, id, formData);
		yield put(Creators.updateOrganizationSuccess(response))
	} catch (error) {
		let errors = error.response && error.response.data;
		yield put(Creators.updateOrganizationFailure(errors));
	}
}

export function* getOrganization(actions) {
	try {
		const { id } = actions;
		const response = yield call(getOrganizationApi, id);
		yield put(Creators.getOrganizationSuccess(response))
	} catch (error) {
		let errors = error.response && error.response.data;
		yield put(Creators.getOrganizationFailure(errors));
	}
}

export function* fetchOrganizations(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchOrganizations, query);
		yield put(Creators.fetchOrganizationsSuccess(response))
	} catch (error) {
		let errors = error.response && error.response.data;
		yield put(Creators.fetchOrganizationsFailure(errors));
	}
}

export function* watchFetchOrganizations() {
	yield takeEvery(Types.FETCH_ORGANIZATIONS, fetchOrganizations);
}

export function* watchGetOrganization() {
	yield takeEvery(Types.GET_ORGANIZATION, getOrganization);
}

export function* watchCreateOrganization() {
    yield takeEvery(Types.CREATE_ORGANIZATION, createOrganization);
}

export function* watchUpdateOrganization() {
    yield takeEvery(Types.UPDATE_ORGANIZATION, updateOrganization);
}


