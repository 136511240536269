import { apiRequest, apiRequestOthers } from '../apiRequest';
import { CASH_MANAGEMENT_ENDPOINT, CASH_TOTALS_STATISTICS_ENDPOINT } from '../urls';

export const getCashManagement = (org_id) => { 
	return apiRequest('GET', `${CASH_MANAGEMENT_ENDPOINT}?org_id=${org_id}`);
}

export const fetchCashManagements = (org_id, query) => { 
	let page = query && query.page;
	let search_term = query && query.search_term
	let from_date = query && query.from_date
	let to_date = query && query.to_date
	let isReport = query && query.isReport

	return apiRequest('GET', `${CASH_MANAGEMENT_ENDPOINT}?org_id=${org_id}
		${ page ? `&page=${page}`: '' }
		${ isReport ? `&isReport=${isReport}`: '' }
		${ search_term ? `&search=${search_term}`: '' }
		${ from_date ? `&from_date=${from_date}`: '' }
		${ to_date ? `&to_date=${to_date}`: '' }`);
} 

export const createCashManagement = (org_id, data) => {
	return apiRequest('POST', `${CASH_MANAGEMENT_ENDPOINT}?org_id=${org_id}`, data);
}

export const updateCashManagement = (id, data) => {
	return apiRequest('PATCH', `${CASH_MANAGEMENT_ENDPOINT}/org/${id}`, data);
}

export const fetchCashTotals = (org_id, query) => {
	let search_term = query && query.search_term
	let from_date = query && query.from_date
	let to_date = query && query.to_date

	return apiRequest('GET', `${CASH_TOTALS_STATISTICS_ENDPOINT}?org_id=${org_id}
		${ search_term ? `&search=${search_term}`: '' }
		${ from_date ? `&from_date=${from_date}`: '' }
		${ to_date ? `&to_date=${to_date}`: '' }`);
}

