import { apiRequest, apiRequestOthers } from '../apiRequest';
import { USERS_ENDPOINT, SIGN_UP_ENDPOINT } from '../urls';
import moment from 'moment';

export const fetchUsers = (query) => {
	let search_term = query && query.search_term
	let page = query && query.page
	let userType = query && query.userType

	console.log("fetchUsers query ", query)

	return apiRequest('GET', `${USERS_ENDPOINT}?
		${ search_term ? `&search=${search_term}`: '' }
		${ page ? `&page=${page}`: '' }
		${ userType ? `&userType=${userType}`: '' }`);
}

export const getUser = (id) => { 
	return apiRequest('GET', `${USERS_ENDPOINT}/${id}/`);
}

export const createUser = data => {
	// return apiRequest('POST', SIGN_UP_ENDPOINT, data);
	return apiRequestOthers('POST', SIGN_UP_ENDPOINT, data, '*/*');
}

export const updateUser = (id, data) => {
	console.log("updateUser id && data ", id, data)
	// return apiRequest('PATCH', `${USERS_ENDPOINT}/${id}/`, data);
	return apiRequestOthers('PATCH', `${USERS_ENDPOINT}/${id}/`, data, '*/*');
}

export const fetchUser = (id) => {
	return apiRequest('GET', `${USERS_ENDPOINT}/${id}/`);
}

// this is to replace fetchUsers temporarily, but it's not enough, fetchUsers with filters is better...
export const fetchOrganizationUsers = (id, query) => {
	let search_term = query && query.search_term
	let page = query && query.page
	let userType = query && query.userType

	console.log("fetchUsers query ", query)

	return apiRequest('GET', `${USERS_ENDPOINT}/org/${id}/?
		${ search_term ? `search=${search_term}`: '' }
		${ page ? `&page=${page}`: '' }
		${ userType ? `&userType=${userType}`: '' }`);
}

