import React from 'react';
import { Form,  Input, Checkbox } from 'antd';
import { Formik, Field } from 'formik';

const FormItem = Form.Item;

export const CheckboxField = ({ label, field, form: { touched, errors }, ...props }) => {
	const errorMsg =  errors[field.name];
	return (
	  <FormItem
	    validateStatus={ errorMsg ? 'error': ''}
	    help={errorMsg}
	  >
	    <Checkbox 
	    	name={field.name} 
			checked={props.value}  
			{... props}  
	    	onChange={e => {
          props.setFieldValue(field.name, !props.value)
        }} >{label}</Checkbox>
	  </FormItem>
	);
}

