import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
// import { validationSchemaProfileForm } from '../../../../util/validations';
import { gender_options, role_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class ProfileForm extends Component {

  componentDidMount() {
    const { fetchUser } = this.props;
    const user = getLocalState('lsu-user-details');
    fetchUser(user._id);
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, isFetching } = this.props;

    return (
      <>
          <PageContent 
            title="Profile"
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={false}
            hasSubmitBtn={true}
            submitBtnName="Update"
            hasCancelBtn={true}
            formClassNames=""
            // errors={error_message}
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
            {isFetching && <Spinner />}
            {!isFetching && 
              (<>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                	<Field
                    name='name'
                    label="Name"
                    placeholder="name"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='email'
                    label="Email"
                    placeholder="email"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='phone_number'
                    label="Contact"
                    placeholder="contact"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='national_id'
                    label="National Id"
                    placeholder="National Id"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name="gender"
                    label="Gender"
                    size="large"
                    component={SelectField}
                    options={gender_options}
                    placeholder="select gender"
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='village'
                    label="Village"
                    placeholder="village"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='parish'
                    label="Parish"
                    placeholder="parish"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='sub_county'
                    label="Sub-county"
                    placeholder="sub-county"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='district'
                    label="District"
                    placeholder="district"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='date_of_birth'
                    label="Date of birth"
                    placeholder="date of birth"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='next_of_kin'
                    label="Next of kin"
                    placeholder="next of kin"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='business_name'
                    label="Business Name"
                    placeholder="business name"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                    disabled
                  />
                </Col>
                {/*<Col md={12} sm={24}>
                  <Field
                    name="role"
                    label="Role"
                    size="large"
                    component={SelectField}
                    options={role_options}
                    placeholder="select role"
                  />
                </Col>*/}
                <Col md={12} sm={24}>
                  <FormItem>
                  <Field
                    type="checkbox"
                    name="active"
                    label="Account Status"
                    size="large"
                    component={CheckBox}
                    value={this.props.values && this.props.values.active}
                    setFieldValue={this.props.setFieldValue}
                    disabled
                  />
                  </FormItem>
                </Col>
                </Row>
                <Row>
                <Col md={12} sm={24}>
                  <Field
                    name="extra_info"
                    label="Extra Info/Bio"
                    placeholder="Write any extra bio about the user"
                    size="large"
                    component={TextAreaField}
                    rows={9}
                    disabled
                  />
                </Col>
              </Row>
              </>) 
            }
          </PageContent>
      </>
    );
  }
}

ProfileForm.propTypes = {
  isloading: PropTypes.bool,
}

const ViewProfile = withFormik({
  // validationSchema: validationSchemaProfileForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');
    props.updateUser(user._id, values)
  },

  displayName: 'profile-form',
  enableReinitialize: true
})(ProfileForm);

export default ViewProfile;

