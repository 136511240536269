import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  CREATE_ORGANIZATION
  CREATE_ORGANIZATION_SUCCESS
  CREATE_ORGANIZATION_FAILURE

  FETCH_ORGANIZATIONS
  FETCH_ORGANIZATIONS_SUCCESS
  FETCH_ORGANIZATIONS_FAILURE

  UPDATE_ORGANIZATION
  UPDATE_ORGANIZATION_SUCCESS
  UPDATE_ORGANIZATION_FAILURE

  GET_ORGANIZATION
  GET_ORGANIZATION_SUCCESS
  GET_ORGANIZATION_FAILURE

  RESET

`, {});
