import React, { Component } from "react";
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from "antd";
import Spinner from "../../../reusable-components/Spinner";
import PageContent from "../../../reusable-components/PageContent";
import { history } from "../../../util/helpers/browserHistory";
import { getLocalState } from "../../../util/helpers/reusable-functions";
import Filters from "./filters";
import List from "./list";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import PropTypes from "prop-types";

export default class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: null,
    };
  }

  componentDidMount() {
    const { reset } = this.props;
    reset();
  }

  HeaderBtns = () => {
    const { reports } = this.props;
    return (
      reports && (
        <button type="button" className="btn header-btn">
          <CSVLink data={reports}>Download Report</CSVLink>
        </button>
      )
    );
  };

  render() {
    const { isloading, reports, fetchReports, reset } = this.props;
    let buttonState = isloading ? <Spinner /> : "Continue";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <PageContent
          title="Reports"
          showExportBtn={false}
          exportBtnLabel="Download CSV"
          exportData={() => {}}
          showExtraBtns={true}
          extraBtns={<this.HeaderBtns />}
          isFilterContainer={true}
        >
          <Filters
            handleChange={(value, from_date, to_date) => {
              const user = getLocalState("lsu-user-details");
              this.setState({ filters: value });
              reset();
              fetchReports(
                user && user.organization_id && user.organization_id._id,
                value,
                from_date,
                to_date
              );
            }}
            {...this.props}
          />
        </PageContent>
        <PageContent>
          <Row>
            <div className="filters-list">
              <List filters={this.state.filters} {...this.props} />
            </div>
          </Row>
        </PageContent>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool,
};
