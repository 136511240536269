import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchLoans: ["org_id", "query"],
    fetchLoansSuccess: ["responseData", "page", "pageCount"],
    fetchLoansFailure: ["error"],

    fetchAllLoans: ["org_id", "query"],
    fetchAllLoansSuccess: ["responseData", "page", "pageCount"],
    fetchAllLoansFailure: ["error"],

    fetchUserLoans: ["user_id"],
    fetchUserLoansSuccess: ["responseData"],
    fetchUserLoansFailure: ["error"],

    createLoan: ["payload"],
    createLoanSuccess: ["responseData"],
    createLoanFailure: ["error"],

    getLoan: ["id"],
    getLoanSuccess: ["responseData"],
    getLoanFailure: ["error"],

    updateLoan: ["id", "payload", "redirect"],
    updateLoanSuccess: ["responseData"],
    updateLoanFailure: ["error"],

    fetchLoanRepayments: ["id", "query"],
    fetchLoanRepaymentsSuccess: ["responseData", "page", "pageCount"],
    fetchLoanRepaymentsFailure: ["error"],

    fetchDefaulters: ["org_id", "query"],
    fetchDefaultersSuccess: ["responseData", "page", "pageCount"],
    fetchDefaultersFailure: ["error"],

    downloadCSV: ["org_id", "query"],
    downloadCSVSuccess: ["responseData"],
    downloadCSVFailure: ["error"],

    downloadRepaymentsCSV: ["id", "query"],
    downloadRepaymentsCSVSuccess: ["responseData"],
    downloadRepaymentsCSVFailure: ["error"],

    fetchRoLoansSummary: ["id", "query"],
    fetchRoLoansSummarySuccess: ["responseData", "page", "pageCount"],
    fetchRoLoansSummaryFailure: ["error"],

    reset: null,
  },
  {}
);
