import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  CREATE_CASH_MANAGEMENT
  CREATE_CASH_MANAGEMENT_SUCCESS
  CREATE_CASH_MANAGEMENT_FAILURE

  GET_CASH_MANAGEMENT
  GET_CASH_MANAGEMENT_SUCCESS
  GET_CASH_MANAGEMENT_FAILURE

  FETCH_CASH_MANAGEMENTS
  FETCH_CASH_MANAGEMENTS_SUCCESS
  FETCH_CASH_MANAGEMENTS_FAILURE

  FETCH_CASH_TOTALS
  FETCH_CASH_TOTALS_SUCCESS
  FETCH_CASH_TOTALS_FAILURE

  UPDATE_CASH_MANAGEMENT
  UPDATE_CASH_MANAGEMENT_SUCCESS
  UPDATE_CASH_MANAGEMENT_FAILURE

  DOWNLOAD_CASHFLOW_CSV
  DOWNLOAD_CASHFLOW_CSV_SUCCESS
  DOWNLOAD_CASHFLOW_CSV_FAILURE

  RESET

`, {});
