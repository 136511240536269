import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/reports/actions'
import {
	fetchReports as fetchReportsApi,
	extractRepaymentsReport as extractRepaymentsReportApi,
} from '../../api/reports';
import { history } from '../../../util/helpers/browserHistory';
import { OpenNotificationWithIcon } from '../../../reusable-components/Notifications';

export function* fetchReports(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchReportsApi, query);
		const resp = response && response.data;
		console.log("response from fetchReports ", response)
		yield put(Creators.fetchReportsSuccess(resp))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchReportsFailure(errorsFound));
	}
}

export function* extractRepaymentsReport(actions) {
	try {
		const { org_id, user_id, query } = actions;
		const response = yield call(extractRepaymentsReportApi, org_id, user_id, query);
		const resp = response && response.data;
		yield put(Creators.extractRepaymentsReportSuccess(resp))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.extractRepaymentsReportFailure(errorsFound));
	}
}

export function* watchFetchReports() {
	yield takeEvery(Types.FETCH_REPORTS, fetchReports);
}

export function* watchExtractRepaymentsReport() {
    yield takeEvery(Types.EXTRACT_REPAYMENTS_REPORT, extractRepaymentsReport);
}

