import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect, Comment, Avatar } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../reusable-components/Spinner';
import { InputField } from '../../reusable-components/InputField';
import { reports_filter_options } from '../../util/helpers/form-field-options';
import moment from 'moment';
import get from 'lodash/get';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const Comments = ({ title="Comments", comments, ...props }) => {
	return ( 
    <>
      <Col span={24}>
        <div>
          <h2>{title}</h2>
          <div className="comments-container">
            {comments && comments.length > 0 ?
              comments.map((comment, index) => {
                return (<Comment
                  key={index}
                  author={<a>{get(comment, 'deactivated_by.name') || get(comment, 'comment_by.name')}</a>}
                  avatar={
                    <Avatar
                      src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                      alt="Avatar"
                    />
                  }
                  content={
                    <p>{comment.comment}</p>
                  }
                  datetime={
                    <span>{moment(comment.date).format('LLLL')}</span>
                  }
                />)
              }) :
              (<div>No comments found</div>)
            }
          </div>
        </div>
      </Col>
		</>
	);
}

export default Comments;

