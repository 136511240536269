import React from "react";
import { Icon, Row, Col, Button } from "antd";
import  "../../../styles/header.less";
import { Link} from 'react-router-dom';
import Can from '../../../util/helpers/Can'
import { getUserRole } from '../../../services/api/axiosDefaults';
import { history } from '../../../util/helpers/browserHistory';

class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overlay: 'overlay'
        }
    }
    
    toggleOverlay = () => {
        if (this.state.overlay === 'overlay') {
            this.setState({overlay : 'overlay open'});
        } else {
            this.setState({overlay : 'overlay'});
        }
    }

    render() {
        const { overlay } = this.state;
        const { loggedInUser, showLoginModal,logout } = this.props;

         //get user role from localstorage
         const role = getUserRole();

        return (
            <>
                
                <Row id="lowerheader" className="mobile-header">
                    <Col span={9} id="logo">
                        <Link to="/"><img src="" alt="logo" /></Link>
                    </Col>
                    
                <Col span={13} className="mobile-right-col">

                <Icon type="bars" className="harmburger" onClick={this.toggleOverlay}/>
                <div id="myNav" className={overlay}>
                    {!loggedInUser ? (
                            <div className="overlay-content">
                               <ul>
                                   <li>
                                       <hr/>
                                       <Link to="/" onClick={this.toggleOverlay}>Home</Link> {/* close overlay on click of every link */}
                                   </li>
                                   <li>
                                       <Link to="/" className="orange-text" onClick={() => {
                                           this.toggleOverlay();
                                           showLoginModal();
                                       }}>Login</Link>
                                   </li>
                               </ul>
                            </div>
                        ) :
                        (
                            <div className="overlay-content" id="logged-in-menu">
                                <ul>
                                    <li>
                                        <Link to="" className="orange-text" onClick={this.toggleOverlay}></Link>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="" onClick={this.toggleOverlay}></Link>
                                    </li>
                                    <Can
                                                role={role}
                                                perform="location:list"
                                                yes={() => (
                                                    <li>
                                                        <Link to="/" onClick={this.toggleOverlay}></Link>
                                                    </li>
                                                )}
                                                no={() => <li hidden="true"></li>}
                                            /> 
                                   
                                    <li>
                                        <Link to="/" className="orange-text">Reports</Link>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="/incidents" onClick={this.toggleOverlay}></Link>
                                    </li>
                                    <li>
                                        <Link to="" onClick={this.toggleOverlay}></Link>
                                    </li>
                                    <li>
                                        <Link to="/drafts" onClick={this.toggleOverlay}>My Drafts</Link>
                                    </li>
                                    <Can
                                                role={role}
                                                perform="insurance:manage"
                                                yes={() => (
                                                    <li>
                                                    <   Link to="" onClick={this.toggleOverlay}></Link>
                                                    </li>
                                                )}
                                                no={() => <li hidden={true}></li>}
                                            /> 
                                   
                                    <li>
                                        <Link to="/" onClick={this.toggleOverlay}>Report Analysis</Link>
                                    </li>
                                    <li>
                                        <hr/>
                                        <Link to="/" onClick={this.toggleOverlay}>Profile Edit</Link>
                                    </li>
                                    <li>
                                        <Link to="/" onClick={this.toggleOverlay}>Settings</Link>
                                    </li>
                                    <li>
                                        <Link to="/" onClick={logout}>Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                        </div>  
                        </Col>
                        
                    </Row>
                </>
      );
    }
}


export default MobileHeader;