import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  fetchStatistics: ['query'],
  fetchStatisticsSuccess: ['responseData'],
  fetchStatisticsFailure: ['error'],

  resetStats: null

}, {});
