import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import { loan_status_options } from '../../../../util/helpers/form-field-options';

const FormItem = Form.Item;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const Filters = ({ isloading, handleChange, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [status, setStatus] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")

  const onChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    handleChange(start_date, end_date, val, status);
  }

	return ( 
		<>
      <div>
        <Row gutter={32}>
          <Col offset={8} md={8} sm={12}>
            <Select
              name="status"
              size="large"
              style={{ width: "100%" }}
              loading={isloading}
              showSearch
              placeholder="filter by status"
              optionFilterProp="children"
              onChange={(value) => {
                setStatus(value);
                handleChange(start_date, end_date, searchTerm, value);
              }}
              filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { loan_status_options.map((option, index) => (
                  <Option key={index} value={option.value}>{option.label}</Option>
                ))
              }
            </Select>
          </Col>
          <Col md={8} sm={12}>
            <Input
              name='search_term'
              size="large"
              className="search-input"
              style={{ width: "100%" }}
              placeholder="Search..."
              prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={onChange}
              value={searchTerm}
              autoComplete="off"
            />
          </Col>
        </Row>
      </div>
		</>
	);
}

export default Filters;

