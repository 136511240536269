import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/loans/actions';
import ListComponent from "./components";
import { getLocalState } from '../../../util/helpers/reusable-functions';

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
  const { loans, isloading, page, pageCount } = state.loans;
  return {
    loans,
    isloading,
    page,
    pageCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPagination: () => {

    },
    fetchLoans: (query={page:1}) => {
      dispatch(Creators.fetchAllLoans(user && user.organization_id && user.organization_id._id, query));
    },
    approveLoan: (loan_id, loan_status, comment) => {
      const data = {
        loan_status,
        notes: comment
      }
      let redirect = true
      dispatch(Creators.updateLoan(loan_id, data, redirect));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
