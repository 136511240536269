import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, DatePicker, Upload } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { DateField } from '../../../../reusable-components/DateField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import Comments from '../../../../reusable-components/Comments';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaStepThreeForm } from '../../../../util/validations';
import { gender_options, role_option, account_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { history } from '../../../../util/helpers/browserHistory';

const { Dragger } = Upload;
const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateUserForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    }
  }

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location, goBack, moveNext } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    console.log("Values are ", values)
    console.log("formerrors are ", formerrors)

    return (
      <>
          <PageContent 
            title="Other details"
            goBack={goBack}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container custom-stepper-content"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            showFooter={true}
            hasBackBtn={true}
            hasNextBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            formClassNames=""
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <i><b>Other details</b></i>
                    <Row gutter={30}>
                      <Col md={24} sm={24}>
                        <Field
                          name='business.name'
                          label="Business Name"
                          placeholder="business name"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <i><b>File fees</b></i>
                    <Row gutter={30}>
                      <Col md={24} sm={24}>
                        <Field
                          type="number"
                          name='file_fees'
                          label="File fees"
                          placeholder="file fees"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <Field
                    name='other_business'
                    label="Other sources of income"
                    placeholder="other sources of income"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row>
                      <Col span={24}>
                        <FormItem>
                          <Field
                            name="active"
                            label="Account Status"
                            size="large"
                            component={SelectField}
                            options={account_status_options}
                            placeholder="select status"
                          />
                        </FormItem>
                      </Col>
                      {
                        values && values.deactivated && values.deactivated.length > 0 &&
                        <Comments 
                          title="Comments(when user was deactivated)"
                          comments={values.deactivated} 
                          {...this.props} />
                      }
                      { values && values.active === "deactive" &&
                        <Col span={24}>
                          <Field
                            name="reason_for_deactivation"
                            label="Reason for deactivation of this account"
                            placeholder="Reason for deactivation"
                            size="large"
                            component={TextAreaField}
                            rows={9}
                          />
                        </Col>
                      }
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24}>
                  <h2>Attach All Necessary Documents</h2>
                  <div>
                    <Row gutter={30}>
                      <Col md={12} sm={24} style={{margin: "10px 0"}}>
                        <i><b>Client's profile picture</b></i>
                        {
                          values && values.profile_picture &&
                          (<> 
                            <p>Attached files:
                              <a onClick={()=> window.open( get(values, 'profile_picture.path') )}>Click to preview file</a>
                            </p>
                          </>)
                        }
                        <Dragger 
                          name='client_profile_pic_id'
                          onChange={(info) => {
                            const { status } = info.file;
                            
                            const files = [];
                            info.fileList && info.fileList.forEach(f => {
                              files.push(f.originFileObj);
                            });
                            this.props.setFieldValue('profile_picture', info.file);
                          }}
                          beforeUpload={this.beforeUpload}
                          >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">
                            Upload client's National ID.
                          </p>
                        </Dragger>
                      </Col>
                      <Col md={12} sm={24} style={{margin: "10px 0"}}>
                        <i><b>Client's National ID</b></i>
                        {
                          values && values.personal_docs &&
                          (<> 
                            <p>Attached files:
                              <a onClick={()=> window.open( get(values, 'personal_docs.path') )}>Click to preview file</a>
                            </p>
                          </>)
                        }
                        <Dragger 
                          name='client_national_id'
                          onChange={(info) => {
                            const { status } = info.file;

                            const files = [];
                            info.fileList && info.fileList.forEach(f => {
                              files.push(f.originFileObj);
                            });
                            this.props.setFieldValue('personal_docs', info.file)
                          }}
                          beforeUpload={this.beforeUpload}
                          >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">
                            Upload client's profile picture.
                          </p>
                        </Dragger>
                      </Col>
                      <Col md={12} sm={24} style={{margin: "10px 0"}}>
                        <i><b>Chairperson's Approval Letter</b></i>
                        {
                          values && values.chairperson && values.chairperson.docs &&
                          (<> 
                            <p>Attached files:
                              <a onClick={()=> window.open( get(values, 'chairperson.docs.path') )}>Click to preview file</a>
                            </p>
                          </>)
                        }
                        <Dragger 
                          name='chairperson_approval_letter'
                          multiple={true}
                          onChange={(info) => {
                            const { status } = info.file;

                            const files = [];
                            info.fileList && info.fileList.forEach(f => {
                              files.push(f.originFileObj);
                            });
                            this.props.setFieldValue('chairperson_docs', info.file)
                          }}
                          beforeUpload={this.beforeUpload}
                          >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">
                            Upload Chairperson's Approval Letter.
                          </p>
                        </Dragger>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

CreateUpdateUserForm.propTypes = {
  isloading: PropTypes.bool,
}

const StepThree = withFormik({
  validationSchema: validationSchemaStepThreeForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');

    // if (values && values.deactivated) {
    //   delete values.deactivated;
    // }

    if (values && values.reason_for_deactivation) {
      values.deactivatedObj = {
        comment: values.reason_for_deactivation,
        deactivated_by: user._id
      }
    }

    props.storeUserDataChunks(values);
    props.moveNext();
  },

  displayName: 'create-user-form',
  enableReinitialize: true
})(CreateUpdateUserForm);

export default StepThree;

