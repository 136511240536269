import React from 'react';
import { Form,  Input } from 'antd';
import { Formik, Field } from 'formik';

const FormItem = Form.Item;
const { TextArea } = Input;

export const TextAreaField = ({ label, field, form: { touched, errors }, ...props }) => {
	const errorMsg =  errors[field.name];
	return (
	  <FormItem
	  	label={label}
	    validateStatus={ errorMsg ? 'error': ''}
	    help={errorMsg}
	  >
	    <TextArea
	    {...field}
		  {... props}  
		  onChange = {(e) => {
			field.onChange(e)
			props.onChange && props.onChange(e)
		  }}
		  style={{...props.styles || props.style}}
	    />
	  </FormItem>
	);
}