import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import NotFoundComponent from "./component/NotFoundComponent";

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (username, password) => {
      dispatch(Creators.login(username, password));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundComponent);
