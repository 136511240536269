import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';

const FormItem = Form.Item;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const Filters = ({ isloading, handleChange, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [from_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")

  const onChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
    handleChange(from_date, end_date, val);
  }

  return ( 
    <>
      <div>
        <Row gutter={32}>
          <Col md={8} sm={12}>
            <div className="date-picker-customer5">
              <span className="label">From Date</span>
              <DatePicker
                name="from_date"
                format="YYYY-MM-DD"
                showTime={false}
                size="large"
                className="filters-date-picker"
                onChange={(date, dateString) => {
                  setStartDate(dateString)
                  handleChange(dateString, end_date, searchTerm)
                }}
              />
            </div>
          </Col>
          <Col md={8} sm={12}>
            <div className="date-picker-customer5">
              <span className="label">To Date</span>
              <DatePicker
                name="to_date"
                format="YYYY-MM-DD"
                showTime={false}
                size="large"
                className="filters-date-picker"
                onChange={(date, dateString) => {
                  setEndDate(dateString)
                  handleChange(from_date, dateString, searchTerm)
                }}
              />
            </div>
          </Col>
          <Col md={8} sm={24}>
            <span className="label">Search</span>
            <Input
              name='search_term'
              size="large"
              className="search-input"
              style={{ width: "380px", marginRight: '0px' }}
              placeholder="Search..."
              prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={onChange}
              value={searchTerm}
              autoComplete="off"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Filters;

