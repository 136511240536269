import React, { Component } from 'react';
import { Steps, Button, message } from 'antd';

const { Step } = Steps;

const Steppers = ({ steps, current, ...props }) => {
  return (
    <>
      <Steps current={current} className="customSteppers">
        {steps.map(item => (
          <Step 
          	key={item.title} 
          	title={item.title}
          	onClick={() => {
          		console.log('Clicked on ', item, item.step)
          		props.onPress(item.step)
          	}} />
        ))}
      </Steps>
    </>
  )
}

export default Steppers;

