import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/loans/actions';
import { Creators as UserCreators } from '../../../services/redux/users/actions';
import ListComponent from "./components";

const mapStateToProps = state => {
  const { loans, isloading } = state.loans;
  return {
    loans,
    isloading,
    isFetchingUser: state.users.isloading,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPagination: () => {

    },
    fetchUser: (id) => {
      dispatch(UserCreators.getUser(id));
    },
    fetchUserLoans: (user_id) => {
      dispatch(Creators.fetchUserLoans(user_id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListComponent);
