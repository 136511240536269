import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';
import { Formik, Field } from 'formik';
import moment from 'moment';

const FormItem = Form.Item;

export const DateField = ({ label, field, form: { touched, errors }, ...props }) => {
	const errorMsg =  errors[field.name];
	  
	return (
	  <FormItem
	    label={label}
	    validateStatus={ errorMsg ? 'error': ''}
	    help={errorMsg}
	  >
	    <DatePicker
		  	{...props}
		  	name={field.name}
	      format="YYYY-MM-DD"
	      showTime={false}
	      size={props.size}
	      value={field && field.name && props.values && props.values[field.name] ? moment(props.values[field.name], "YYYY-MM-DD") : moment(new Date(), "YYYY-MM-DD")}
	      onChange={(date, dateString) => {
	        props.setFieldValue(field.name, dateString)
	      }}
	    />
	  </FormItem>
	);
}