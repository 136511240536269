import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  createOrganization: ['payload'],
  createOrganizationSuccess: ['responseData'],
  createOrganizationFailure: ['error'],

  getOrganization: ['id'],
  getOrganizationSuccess: ['responseData'],
  getOrganizationFailure: ['error'],

  updateOrganization: ['id', 'payload'],
  updateOrganizationSuccess: ['responseData'],
  updateOrganizationFailure: ['error'],

  fetchOrganizations: ['query'],
  fetchOrganizationsSuccess: ['responseData'],
  fetchOrganizationsFailure: ['error'],

  reset: null
}, {});
