import React from "react";
import { Spin, Icon } from 'antd';

const Spinner = () => {
    const antIcon = <Icon type="loading" style={{ fontSize: 24, padding: 0 }} spin />;
    return(
        <Spin indicator={antIcon} style={{color: "#E84B07"}} />
    );
}

export default Spinner;