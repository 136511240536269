import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/cash-management/actions'
import {
	createCashManagement as createCashManagementApi,
	updateCashManagement as updateCashManagementApi,
	getCashManagement as getCashManagementApi,
	fetchCashManagements as fetchCashManagementsApi,
	fetchCashTotals as fetchCashTotalsApi,
} from '../../api/cash-management';
import { history } from '../../../util/helpers/browserHistory';
import { OpenNotificationWithIcon } from '../../../reusable-components/Notifications';
import { generateFormData } from '../../../util/helpers/reusable-functions';
import get from 'lodash/get';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';

export function* createCashManagement(actions) {
	try {
		const { org_id, payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(createCashManagementApi, org_id, payload);
		const response = yield call(createCashManagementApi, org_id, formData);
		const resp = response && response.data;
		yield put(Creators.createCashManagementSuccess(resp));
		OpenNotificationWithIcon('success', 'Cash Management Create', 'Cash Management has been created successfully!');
		history.push('/cash-management/view')
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon('error', 'Cash Management Create', 'Failed to create Cash Management');
		yield put(Creators.createCashManagementFailure(errorsFound));
	}
}

export function* updateCashManagement(actions) {
	try {
		const { id, payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(updateCashManagementApi, id, payload);
		const response = yield call(updateCashManagementApi, id, formData);
		const resp = response && response.data;
		yield put(Creators.updateCashManagementSuccess(resp && resp.doc));
		OpenNotificationWithIcon('success', 'Cash Management Update', 'Cash Management has been updated successfully!');
		history.push('/loans');
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon('error', 'Cash Management Update', 'Failed to update Cash Management!');
		yield put(Creators.updateCashManagementFailure(errorsFound));
	}
}

export function* fetchCashManagements(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchCashManagementsApi, org_id, query);
		const resp = response && response.data;
		yield put(Creators.fetchCashManagementsSuccess(resp && resp.doc, resp && resp.page, resp && resp.pageCount));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchCashManagementsFailure(errorsFound));
	}
}

export function* fetchCashTotals(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchCashTotalsApi, org_id, query);
		const resp = response && response.data;
		const responseData = {
			total_cash_inflow: resp.total_cash_inflow,
			total_cash_outflow: resp.total_cash_outflow
		}
		yield put(Creators.fetchCashTotalsSuccess(responseData));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchCashTotalsFailure(errorsFound));
	}
}

export function* getCashManagement(actions) {
	try {
		const { org_id } = actions;
		const response = yield call(getCashManagementApi, org_id);
		const resp = response && response.data;
		yield put(Creators.getCashManagementSuccess(resp && resp.doc));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getCashManagementFailure(errorsFound));
	}
}

export function* downloadCVS(actions) {
	try {
		const { org_id, query } = actions;
		const response = yield call(fetchCashManagementsApi, org_id, query);
	    csvDownload(get(response, 'data.doc'), `cash-flow-${moment().format('YYYY-MM-DD HH:MM:SS')}.csv`);
		yield put(Creators.downloadCashflowCSVSuccess(get(response, 'data.doc')));
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.downloadCashflowCSVFailure(errorsFound));
		OpenNotificationWithIcon('error', 'Failed to download csv!', errorsFound && errorsFound.message)
	}
}

export function* watchFetchCashManagements() {
	yield takeEvery(Types.FETCH_CASH_MANAGEMENTS, fetchCashManagements);
}

export function* watchFetchCashTotals() {
	yield takeEvery(Types.FETCH_CASH_TOTALS, fetchCashTotals);
}

export function* watchGetCashManagement() {
	yield takeEvery(Types.GET_CASH_MANAGEMENT, getCashManagement);
}

export function* watchCreateCashManagement() {
    yield takeEvery(Types.CREATE_CASH_MANAGEMENT, createCashManagement);
}

export function* watchUpdateCashManagement() {
    yield takeEvery(Types.UPDATE_CASH_MANAGEMENT, updateCashManagement);
}

export function* watchCashFlowCVS() {
	yield takeLeading(Types.DOWNLOAD_CASHFLOW_CSV, downloadCVS);
}


