import React, { useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  TreeSelect,
} from "antd";
import { withFormik, Field, FormikProps } from "formik";
import Spinner from "../../../../reusable-components/Spinner";
import { InputField } from "../../../../reusable-components/InputField";
import {
  reports_filter_options,
  dashboard_status_options,
} from "../../../../util/helpers/form-field-options";

const FormItem = Form.Item;
const { Option } = Select;

const Filters = ({
  isloading,
  loan_officer_options,
  handleChange,
  ...props
}) => {
  const [officerId, setOfficer] = useState(undefined);
  const [period, setPeriod] = useState("months");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const handleSelect = (val) => {
    setPeriod(val);
    handleChange(start_date, end_date, val, officerId);
  };

  return (
    <>
      <Row gutter={32}>
        <Col md={8} sm={12}>
          <span className="label">Relationship Officer</span>
          <Select
            name="status"
            size="large"
            style={{ width: "100%" }}
            loading={isloading}
            showSearch
            placeholder="select relationship officer"
            optionFilterProp="children"
            onChange={(value) => {
              setOfficer(value);
              handleChange(start_date, end_date, period, value);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {loan_officer_options &&
              loan_officer_options.length > 0 &&
              loan_officer_options.map((option, index) => (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </Select>
        </Col>
        <Col md={8} sm={12}>
          <div className="date-picker-customer5">
            <span className="label">From Date</span>
            <DatePicker
              name="from_date"
              format="YYYY-MM-DD"
              showTime={false}
              size="large"
              className="filters-date-picker"
              onChange={(date, dateString) => {
                setStartDate(dateString);
                handleChange(dateString, end_date, period, officerId);
              }}
            />
          </div>
        </Col>
        <Col md={8} sm={12}>
          <div className="date-picker-customer5">
            <span className="label">To Date</span>
            <DatePicker
              name="to_date"
              format="YYYY-MM-DD"
              showTime={false}
              size="large"
              className="filters-date-picker"
              onChange={(date, dateString) => {
                setEndDate(dateString);
                handleChange(start_date, dateString, period, officerId);
              }}
            />
          </div>
        </Col>
      </Row>
      {/*<Row gutter={32} style={{marginTop: '10px'}}>
        <Col md={8} sm={12}>
          <span className="label">Period</span>
          <Select
            name="status"
            size="large"
            style={{ width: "100%" }}
            loading={isloading}
            showSearch
            placeholder="filter by status"
            optionFilterProp="children"
            onChange={(value) => {
              handleSelect(value);
            }}
            value={period}
            filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            { dashboard_status_options.map((option, index) => (
                <Option key={index} value={option.value}>{option.label}</Option>
              ))
            }
          </Select>
        </Col>
      </Row>*/}
    </>
  );
};

export default Filters;
