import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  FETCH_REPAYMENTS
  FETCH_REPAYMENTS_SUCCESS
  FETCH_REPAYMENTS_FAILURE

  CREATE_REPAYMENT
  CREATE_REPAYMENT_SUCCESS
  CREATE_REPAYMENT_FAILURE

  GET_REPAYMENT
  GET_REPAYMENT_SUCCESS
  GET_REPAYMENT_FAILURE

  UPDATE_REPAYMENT
  UPDATE_REPAYMENT_SUCCESS
  UPDATE_REPAYMENT_FAILURE

  RESET

`, {});
