import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  fetchRepayments: ['query'],
  fetchRepaymentsSuccess: ['responseData'],
  fetchRepaymentsFailure: ['error'],

  createRepayment: ['payload'],
  createRepaymentSuccess: ['responseData'],
  createRepaymentFailure: ['error'],

  getRepayment: ['id'],
  getRepaymentSuccess: ['responseData'],
  getRepaymentFailure: ['error'],

  updateRepayment: ['id', 'payload'],
  updateRepaymentSuccess: ['responseData'],
  updateRepaymentFailure: ['error'],

  reset: null
}, {});
