import React, { useState, useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import Pagination from '../../../../reusable-components/Pagination';
import { history } from '../../../../util/helpers/browserHistory';
import { usePrevious } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const columns = (props) => [
  {
    title: 'Name',
    dataIndex: 'user_id.name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'user_id.email',
    key: 'email',
  },
  {
    title: 'Gender',
    dataIndex: 'user_id.gender',
    key: 'gender',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, row) => {
      return numberWithCommas(row.amount)
    }
  },
  {
    title: 'Penalty fees',
    dataIndex: 'penalty_fees',
    key: 'penalty_fees',
    render: (text, row) => {
      return numberWithCommas(row.penalty_fees)
    }
  },
  {
    title: 'Due Created',
    dataIndex: 'last_repayment_date',
    key: 'last_repayment_date',
    render: (text, row) => {
      return moment(row.last_repayment_date).format("DD/MM/YYYY hh:mm")
    }
  },
  {
    title: 'Duration past',
    dataIndex: 'defaulted_days',
    key: 'defaulted_days',
    render: (text, row) => {
      var date1 = new Date(row.last_repayment_date); 
      var date2 = new Date(); 

      // To calculate the time difference of two dates 
      var Difference_In_Time = date2.getTime() - date1.getTime(); 

      // To calculate the no. of days between two dates 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days.toFixed(0);
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <>
          <Button type="link" onClick={(e) => {
            e.preventDefault();
            history.push({
              pathname: `/repayments/loan/${row._id}`,
              search: '',
              state: { name: row.user_id.name, userId: row.user_id._id }
            });
          }}>
            View
          </Button>
        </>
      )         
    }
  }
];

const List = ({ defaulters, isloading, page, pageCount, searchTerm, from_date, to_date, ...props}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  return ( 
    <>
      { isloading ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { props.isloading && (<div>Loading ...</div>) }
              { defaulters.length > 0 &&                  
                <Table 
                  className="table"
                  dataSource={defaulters} 
                  pagination={false}
                  columns={columns(props)} />
            	}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing Defaulters</span>
                </div>
                <Pagination
                  handlePagination={(page) => {
                    props.fetchDefaulters({ page, search_term: searchTerm, from_date, to_date })
                  }}
                  page={page}
                  pageCount={pageCount} />
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

