import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';

const FormItem = Form.Item;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const Filters = (props) => {
  const [search_term, setSearchTerm] = useState(null)

  const onChange = (e) => {
    e.preventDefault();
    const searchedValue = e.target.value;
    setSearchTerm(searchedValue);
    props.handleChange(searchedValue);
  }

	return ( 
		<>
      <div>
        <Row gutter={32}>
          <Col offset={16} md={8} sm={12}>
            <Input
              name='search_term'
              size="large"
              className="search-input"
              style={{ width: "100%" }}
              placeholder="Search..."
              prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={onChange}
              value={search_term}
              autoComplete="off"
            />
          </Col>
        </Row>
      </div>
		</>
	);
}

export default Filters;

