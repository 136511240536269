import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, Upload } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import { DateField } from '../../../../reusable-components/DateField';
import Spinner from '../../../../reusable-components/Spinner';
import Comments from '../../../../reusable-components/Comments';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaCreateUpdateLoanForm } from '../../../../util/validations';
import { 
  gender_options, 
  role_options, 
  interest_rate_options, 
  duration_options, 
  repayment_cycle_options, 
  daily_repayment_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Dragger } = Upload;

let loan_officer_options = [];
let verifier_options = [];

class CreateUpdateLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    }
  }

  componentDidMount() {
    const { fetchLoan, match, fetchUsers, reset } = this.props;
    fetchUsers();
    if (match.params.id) {
      fetchLoan(match.params.id);
    } else {
      reset();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, reset, values } = this.props;
    if (location !== prevProps.location) {
      const locationPathname = location && location.pathname;
      if (locationPathname === '/create/loan') {
        reset();
      }
    }

    if (get(values, 'relationship_officer') !== prevProps.values.relationship_officer) {
      verifier_options = [];
      if (values && values.verified_by) values.verified_by = '';
      verifier_options = loan_officer_options.filter(option => option.value !== get(values, 'relationship_officer'))
    }
  }

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  onChange = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.setState({ fileList: info.fileList});
    } 
    else if (info.file.status === 'removed') {
      this.setState({ fileList: info.fileList});
    }
    
    const files = [];
    info.fileList && info.fileList.forEach(f => {
      files.push(f.originFileObj);
    })
    // this.props.setFieldValue('loan_documents', files)
    this.props.setFieldValue('loan_documents', info.file)
  }

  render() {
    const { match, handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location, loan_officers, isFetchingLoanUsers } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";
    const userId = location.state.userId
    const userName = location.state.name

    // loan_officers.filter(user => user.role === 'loan_officer').map(filteredUser => {
    loan_officer_options = []
    loan_officers.filter(user => user.role !== 'client').map(filteredUser => {
      let userObj = {
        label: filteredUser && filteredUser.name,
        value: filteredUser && filteredUser._id
      }
      loan_officer_options.push(userObj)
    });

    verifier_options = [];
    verifier_options = loan_officer_options.filter(option => option.value !== get(values, 'relationship_officer'))

    return (
      <>
          <PageContent 
            title={` ${ this.props.match.params.id ? 'Update': 'Create' } Loan for ${userName} `}
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={isFetchingLoanUsers ? false : true}
            hasSubmitBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            hasCancelBtn={true}
            formClassNames=""
            // errors={error_message}
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              { isFetchingLoanUsers && <Spinner /> } 
              { !isFetchingLoanUsers && 
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={15}>
                      <Col span={24}>
                      	<Field
                          type="number"
                          name='amount'
                          label="Loan Amount"
                          placeholder="loan amount"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='release_date'
                          label="Release Date"
                          size="large"
                          component={DateField}
                          setFieldValue={this.props.setFieldValue}
                          values={values}
                        />
                      </Col>
                      <Col md={24} sm={24}>
                        <Field
                          name='loan_interest_rate_percentage'
                          label="Loan Interest(%)"
                          placeholder="interest rate"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      {/*<Col md={12} sm={24}>
                        <Field
                          name="loan_interest_rate_period"
                          label="Interest Period"
                          size="large"
                          component={SelectField}
                          options={interest_rate_options}
                          placeholder="select interest period"
                        />
                      </Col>*/}
                    </Row>
                    <Row gutter={15}>
                      <Col md={12} sm={24}>
                        <Field
                          name='loan_duration'
                          label="Loan Duration"
                          placeholder="loan duration"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <Field
                          name="loan_duration_period"
                          label="Duration Period"
                          size="large"
                          component={SelectField}
                          options={duration_options}
                          placeholder="select duration period"
                        />
                      </Col>
                    </Row>
                    <Row gutter={15}>
                      <Col md={12} sm={24}>
                        <Field
                          name="repayment_cycle"
                          label="Repayment Cycle"
                          size="large"
                          component={SelectField}
                          options={repayment_cycle_options}
                          placeholder="repayment cycle"
                        />
                      </Col>
                      <Col md={12} sm={24}>
                        <Field
                          name="require_daily_repayments"
                          label="Apply penalty fees (daily, weekly, e.t.c)?"
                          size="large"
                          component={SelectField}
                          options={daily_repayment_status_options}
                          placeholder="Dependent on repayment cycle?"
                        />
                      </Col>
                    </Row>
                    <Row gutter={15}>
                      <Col span={24}>
                        <Field
                          name="relationship_officer"
                          label="Relationship Officer"
                          size="large"
                          component={SelectField}
                          options={loan_officer_options}
                          placeholder="Relationship Officer"
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name="verified_by"
                          label="Verified by"
                          size="large"
                          component={SelectField}
                          options={verifier_options}
                          placeholder="verified by"
                        />
                      </Col>
                      <Col span={24}>
                        <div>
                          <i><b>Next of Kin</b></i>
                          <Row gutter={30}>
                            <Col md={12} sm={24}>
                              <Field
                                name='next_of_kin'
                                label="Name"
                                placeholder="name"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                            <Col md={12} sm={24}>
                              <Field
                                type="number"
                                name='next_of_kin_phone'
                                label="Contact"
                                placeholder="contact"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div>
                          <i><b>Guarantor's Details</b></i>
                          <Row gutter={30}>
                            <Col md={12} sm={24}>
                              <Field
                                name='guarantor_name'
                                label="Name"
                                placeholder="name"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                            <Col md={12} sm={24}>
                              <Field
                                type="number"
                                name='guarantor_phone'
                                label="Contact"
                                placeholder="contact"
                                size="large"
                                component={InputField}
                                formerrors={formerrors}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={15}>
                      <Col span={24}>
                        <Field
                          name="purpose"
                          label="Purpose for the loan"
                          placeholder=""
                          size="large"
                          component={TextAreaField}
                          rows={7}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          type="number"
                          name='surcharge'
                          label="Surcharge fees"
                          placeholder="surcharge fees"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          type="number"
                          name='loan_application_fees'
                          label="Loan Application fees"
                          placeholder="loan application fees"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          type="number"
                          name='cash_security_fees'
                          label="Cash Security fees"
                          placeholder="cash security fees"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='type_of_collateral'
                          label="Type of collateral"
                          placeholder="collateral"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        Attached files:
                        {
                          values && values.loan_documents_details ? 
                          <a onClick={()=> window.open( get(values, 'loan_documents_details.path') )}>Click to preview file</a> : ' none'
                        }
                      </Col>
                      <Col span={24}>
                        Attach Files:
                        <Dragger 
                          name= 'collateral_file'
                          multiple= {true}
                          onChange={this.onChange}
                          accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .txt"
                          beforeUpload={this.beforeUpload}>
                          <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                        <div style={{marginBottom: 20}}></div>
                      </Col>
                      {
                        values && values.notes && values.notes.length > 0 &&
                        <Comments 
                          comments={values.notes} {...this.props} />
                      }
                      { match.params.id &&
                        <Col span={24}>
                          <Field
                            name="comment"
                            label="Comment"
                            placeholder="Add a comment..."
                            size="large"
                            component={TextAreaField}
                            rows={9}
                          />
                        </Col>
                      }
                    </Row>
                  </div>
                </Col>
              </Row>}
          </PageContent>
      </>
    );
  }
}

CreateUpdateLoanForm.propTypes = {
  isloading: PropTypes.bool,
}

const CreateUpdateClient = withFormik({
  validationSchema: validationSchemaCreateUpdateLoanForm,

  mapPropsToValues: (props) => {
    if (props.loan) {
      let loanProps = props.loan;
      loanProps.relationship_officer = get(loanProps, 'relationship_officer._id');
      console.log("mapPropsToValues ", loanProps)
      return loanProps;
    }
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');
    values.created_by = user._id
    values.user_id = props.location.state.userId
    values.org_id = user && user.organization_id && user.organization_id._id

    // check if location pathname is update, then update the loan with id.
    console.log("values to submit ", values)
    if (props.match.params.id) {
      let comment = values && values.comment 
      // first delete existing notes coz it does not match standard of payload...
      delete values.notes
      if (comment) {
        values.notes = {
          comment,
          comment_by: user && user._id
        }
      }
      props.updateLoan(props.match.params.id, values)
    } else {
      props.createLoan(values);
    }
  },

  displayName: 'create-update-loan-form',
  enableReinitialize: true
})(CreateUpdateLoanForm);

export default CreateUpdateClient;

