import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import PageContent from '../../../../reusable-components/PageContent';
import { history } from '../../../../util/helpers/browserHistory';
import Filters from './filters';
import List from './list';

import PropTypes from 'prop-types';

export default class ListComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      searchTerm: null,
      status: null
    };
  }

  componentDidMount() {
    const { fetchUsers, resetPagination, location } = this.props;
    resetPagination();
    if (location.pathname === '/users') {
      fetchUsers({ page: 1 });
    } else {
      fetchUsers({ page: 1, userType: "client" });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, fetchUsers } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.setState({ searchTerm: null, status: null });
      if (location.pathname === '/users') {
        fetchUsers({ page: 1 });
      } else {
        fetchUsers({ page: 1, userType: "client" });
      }
    }
  }

  _handleSearch = (val) => {
    const { fetchUsers, location } = this.props;
    this.setState({ searchTerm: val });
    fetchUsers({ page: 1, search_term: val, userType: location.pathname === '/users' ? null : 'client' })
  }

  render() {
    const { isloading } = this.props;
    let buttonState = isloading ? <Spinner /> : "Continue";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    console.log("Users ", this.props.users)

    return (
      <>
        <PageContent 
          title={` ${ this.props.location.pathname === '/users' ? 'System Users' : 'Clients' } `}
          isFilterContainer={true}>
          <Filters 
            handleChange={(val) => {
              this._handleSearch(val);
            }}
            {...this.props} />
        </PageContent>
        <PageContent>
            <Button
              className="float-btn"
              onClick={() => {
                if (this.props.location.pathname === '/users') {
                  history.push('/create/user')
                } else {
                  history.push('/create/client')
                }
              }}>
              <Icon type="plus" style={{ fontSize: 24 }} />
            </Button>
            <div className="filters-list">
              <List {...this.props} />
            </div>
        </PageContent>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool,
  initialValues: PropTypes.object,
}


