import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/users/actions'
import {
	createUser as createUserApi,
	updateUser as updateUserApi,
	//fetchUsers as fetchUsersApi,
	fetchOrganizationUsers as fetchOrganizationUsersApi,
	getUser as getUserApi,
} from '../../api/users';
import { history } from '../../../util/helpers/browserHistory';
import { OpenNotificationWithIcon } from '../../../reusable-components/Notifications';
import { generateFormData, storeLocalState } from '../../../util/helpers/reusable-functions';
var generator = require('generate-password');

const objectHasProperty = (obj) => {
	if (typeof obj === 'object' && obj.hasOwnProperty("name") && obj.hasOwnProperty("path")) {
		return true
	}
	if (typeof obj === 'string') {
		return true
	}
	return false
}

export function* createUser(actions) {
	try {
		const { payload } = actions;
		var password = generator.generate({
		    length: 10,
		    numbers: true
		});
		payload.password = password
		if (payload.active === "active") {
	    payload.active = true
	  } else {
	    payload.active = false
	  }
	  if (payload.active) delete payload.deactivated
	  console.log("Data to post is ", payload)
	  const formData = generateFormData(payload);
		const response = yield call(createUserApi, formData);
		const resp = response && response.data;
		yield put(Creators.createUserSuccess(resp))
		OpenNotificationWithIcon('success', 'User Account', 'Account has been created successfully!')

		const location = history && history.location && history.location.pathname

		if (location === '/create/user') {
			history.push('/users');
		} else {
			history.push('/clients')
		}
	} catch (error) {
		console.log("errorsFound ", error)
		console.log("error response ", error.response)
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon('error', 'User Account', `Failed to create account! ${errorsFound && errorsFound.message}`)
		yield put(Creators.createUserFailure(errorsFound));
	}
}

export function* updateUser(actions) {
	try {
		const { id, payload } = actions;
		if (payload.active === "active") {
	    payload.active = true
	  } else {
	    payload.active = false
	  }
	  if (objectHasProperty(payload.profile_picture)) delete payload.profile_picture
	  if (objectHasProperty(payload.personal_docs)) delete payload.personal_docs
	  if (objectHasProperty(payload.chairperson_docs)) delete payload.chairperson_docs
	  if (payload.active) delete payload.deactivated
	  console.log("payload is ", payload)
	  const formData = generateFormData(payload);
		const response = yield call(updateUserApi, id, formData);
		const resp = response && response.data;
		yield put(Creators.updateUserSuccess(resp && resp.doc))
		OpenNotificationWithIcon('success', 'User Update', 'User has been updated successfully!');
		const location = history && history.location && history.location.pathname
		if (location.split('/')[2] === 'user') {
			history.push('/users');
		} else {
			history.push('/clients');
		}
	} catch (error) {
		OpenNotificationWithIcon('error', 'User Update', 'Failed to update the user!')
		let errorsFound = error.response && error.response.data;
		yield put(Creators.updateUserFailure(errorsFound));
	}
}

export function* attachUserOrganization(actions) {
	try {
		const { id, payload } = actions;
		const formData = generateFormData(payload);
		const response = yield call(updateUserApi, id, formData);
		const resp = response && response.data;
		yield put(Creators.updateUserSuccess(resp && resp.doc))
		OpenNotificationWithIcon('success', 'User Update', 'User has been updated successfully!')
		storeLocalState('company-set-up-complete', true);
	} catch (error) {
		OpenNotificationWithIcon('error', 'User Update', 'Failed to update the user!')
		let errorsFound = error.response && error.response.data;
		yield put(Creators.updateUserFailure(errorsFound));
	}
}

export function* getUser(actions) {
	try {
		const { id } = actions;
		const response = yield call(getUserApi, id);
		const resp = response && response.data;
		yield put(Creators.getUserSuccess(resp && resp.doc))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getUserFailure(errorsFound));
	}
}

export function* fetchUsers(actions) {
	try {
		const { id, query } = actions;
		// const response = yield call(fetchUsersApi, query);
		const response = yield call(fetchOrganizationUsersApi, id, query);
		const resp = response && response.data;
		yield put(Creators.fetchUsersSuccess(resp && resp.doc, resp && resp.page, resp && resp.pageCount))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchUsersFailure(errorsFound));
	}
}

export function* watchFetchUsers() {
	yield takeEvery(Types.FETCH_USERS, fetchUsers);
}

export function* watchGetUser() {
	yield takeEvery(Types.GET_USER, getUser);
}

export function* watchCreateUser() {
    yield takeEvery(Types.CREATE_USER, createUser);
}

export function* watchUpdateUser() {
    yield takeEvery(Types.UPDATE_USER, updateUser);
}

export function* watchAttachUserOrganization() {
    yield takeEvery(Types.ATTACH_USER_ORGANIZATION, attachUserOrganization);
}


