import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import { DateField } from '../../../../reusable-components/DateField';
import Comments from '../../../../reusable-components/Comments';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaCreateUpdateUserForm } from '../../../../util/validations';
import { gender_options, role_options, account_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import { history } from '../../../../util/helpers/browserHistory';
import moment from 'moment';

const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateUserForm extends Component {

  componentDidMount() {
    const { fetchUser, match, reset } = this.props;
    if (match.params.id) {
      fetchUser(match.params.id);
    } else {
      reset();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, reset } = this.props;
    if (location !== prevProps.location) {
      console.log("componentDidUpdate props ", this.props);
      const locationPathname = location && location.pathname;
      if (locationPathname === '/create/user' || locationPathname === '/create/client') {
        reset();
      }
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    console.log("Values ", values)

    return (
      <>
          <PageContent 
            title={` ${ this.props.match.params.id ? 'Update': 'Create' } System User`}
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            hasSubmitBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            hasCancelBtn={true}
            formClassNames=""
            // errors={error_message}
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={30}>
                      <Col span={24}>
                      	<Field
                          name='name'
                          label="Name"
                          placeholder="name"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          type="number"
                          name='phone_number'
                          label="Contact"
                          placeholder="contact"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name="gender"
                          label="Gender"
                          size="large"
                          component={SelectField}
                          options={gender_options}
                          placeholder="select gender"
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='current_address.parish'
                          label="Parish"
                          placeholder="parish"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='current_address.district'
                          label="District"
                          placeholder="district"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name="role"
                          label="Role"
                          size="large"
                          component={SelectField}
                          options={role_options}
                          placeholder="select role"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={30}>
                      <Col span={24}>
                        <Field
                          name='email'
                          label="Email"
                          placeholder="email"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='national_id'
                          label="National Id"
                          placeholder="National Id"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='current_address.village'
                          label="Village"
                          placeholder="village"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='current_address.sub_county'
                          label="Sub-county"
                          placeholder="sub-county"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name='date_of_birth'
                          label="Date of birth"
                          size="large"
                          component={DateField}
                          setFieldValue={this.props.setFieldValue}
                          values={values}
                          disabledDate= {(current) => {
                            // disable future dates
                            const today = moment();
                            return current && current > moment(today, "YYYY-MM-DD");
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <div>
                          <Row>
                            <Col span={24}>
                              <FormItem>
                                <Field
                                  name="active"
                                  label="Account Status"
                                  size="large"
                                  component={SelectField}
                                  options={account_status_options}
                                  placeholder="select status"
                                />
                              </FormItem>
                            </Col>
                            {
                              values && values.deactivated && values.deactivated.length > 0 &&
                              <Comments 
                                title="Comments(when user was deactivated)"
                                comments={values.deactivated} 
                                {...this.props} />
                            }
                            { values && values.active === "deactive" &&
                              <Col span={24}>
                                <Field
                                  name="reason_for_deactivation"
                                  label="Reason for deactivation of this account"
                                  placeholder="Reason for deactivation"
                                  size="large"
                                  component={TextAreaField}
                                  rows={9}
                                />
                              </Col>
                            }
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

CreateUpdateUserForm.propTypes = {
  isloading: PropTypes.bool,
}

const CreateUpdateUser = withFormik({
  validationSchema: validationSchemaCreateUpdateUserForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');

    if (values && values.deactivated) {
      delete values.deactivated;
    }

    if (values && values.reason_for_deactivation) {
      values.deactivated = {
        comment: values.reason_for_deactivation,
        deactivated_by: user._id
      }
    }

    if (props.match.params.id) {
      delete values._id
      delete values.password
      delete values.organization_id
      delete values.created_at
      delete values.updated_at
      props.updateUser(props.match.params.id, values)
    } else {
      values.organization_id = user.organization_id && user.organization_id._id
      props.createUser(values);
    }
  },

  displayName: 'create-user-form',
  enableReinitialize: true
})(CreateUpdateUserForm);

export default CreateUpdateUser;

