import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;

const Filters = ({ isloading, handleChange, handleExport, ...props }) => {
  const [start_date, setStartDate] = useState("")
	const [end_date, setEndDate] = useState("")

	return ( 
		<>
      <div className="dates-container" style={{margin: "10px"}}>
        <div className="date-picker-customer">
          <span className="label">From Date</span>
          <DatePicker
            name="from_date"
            format="YYYY-MM-DD"
            showTime={false}
            size="medium"
            className="filters-date-picker"
            onChange={(date, dateString) => {
              console.log("Date and dateString are ", date, dateString)
              setStartDate(dateString)
              handleChange(dateString, end_date)
            }}
            disabledDate= {(current) => {
              // disable future dates
              const today = moment();
              return current && current > moment(today, "YYYY-MM-DD");
            }}
          />
        </div>
        <div className="date-picker-customer">
          <span className="label">To Date</span>
          <DatePicker
            name="to_date"
            format="YYYY-MM-DD"
            showTime={false}
            size="medium"
            className="filters-date-picker"
            onChange={(date, dateString) => {
              console.log("Date and dateString are ", date, dateString)
              setEndDate(dateString)
              handleChange(start_date, dateString)
            }}
            disabledDate= {(current) => {
              // disable future dates
              const today = moment();
              return current && current > moment(today, "YYYY-MM-DD");
            }}
          />
        </div>
        <button 
          type="button"
          className="btn csv-btn" 
          onClick={() => {
            handleExport(start_date, end_date)
          }}>Export</button>
      </div>
		</>
	);
}

export default Filters;

