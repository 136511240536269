import React, { useState, useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import Pagination from '../../../../reusable-components/Pagination';
import { history } from '../../../../util/helpers/browserHistory';
import { usePrevious } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const columns = (props) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (text, row) => {
      let fullName = row.role.split('_').join(' ')
      return fullName;
    }
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (text, row) => {
      let status = row.active ? "Active" : "Deactivated"
      return status
    }
  },
  {
    title: 'Date Created',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, row) => {
      return moment(row.created_at).format("DD/MM/YYYY hh:mm")
    }
  },
  {
    title: 'Loans',
    dataIndex: 'view_loan',
    className: props.location.pathname === '/users' ? 'hide' : 'show',
    key: 'view_loan',
    render: (text, row) => {
      return (
        <Button onClick={(e) => {
          e.preventDefault();
          history.push(`/view/loans/client/${row._id}`)
        }}>
          View Loans
        </Button>
      )         
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <>
          { props.location.pathname !== '/users' &&
            <Button type="link" onClick={(e) => {
              e.preventDefault();
              if (props.location.pathname === '/users') {
                history.push(`/view/user/${row._id}`)
              } else {
                history.push(`/view/user/${row._id}`)
              }
            }}>
              View
            </Button>
          }
          <Button type="link" onClick={(e) => {
            e.preventDefault();
            if (props.location.pathname === '/users') {
              history.push(`/update/user/${row._id}`)
            } else {
              history.push(`/update/client/${row._id}`)
            }
          }}>
            Edit
          </Button>
        </>
      )         
    }
  }
];

const List = ({ users, isloading, page, pageCount, searchTerm, ...props}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  return ( 
    <>
      { isloading ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { props.isloading && (<div>Loading ...</div>) }
              { users.length > 0 &&                  
                <Table 
                  className="table"
                  dataSource={users} 
                  pagination={false}
                  columns={columns(props)} />
            	}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing {` ${ props.location.pathname === '/users' ? 'Users' : 'Clients' } `}</span>
                </div>
                <Pagination
                  handlePagination={(page) => {
                    props.fetchUsers({ page, search_term: searchTerm, userType: props.location.pathname === '/users' ? null : 'client' })
                  }}
                  page={page}
                  pageCount={pageCount} />
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

