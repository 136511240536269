import { all, fork } from "redux-saga/effects";
import {
    watchLogin,
    watchSignUp,
    watchResetPassword,
    watchLogout,
    watchChangePassword,
    watchForgotPassword,
} from "./auth";
import {
    watchCreateOrganization,
    watchGetOrganization,
    watchFetchOrganizations,
    watchUpdateOrganization,
} from "./organizations";
import {
    watchCreateUser,
    watchFetchUsers,
    watchGetUser,
    watchUpdateUser,
    watchAttachUserOrganization,
} from "./users";
import {
    watchCreateLoan,
    watchFetchLoans,
    watchFetchAllLoans,
    watchFetchUserLoans,
    watchGetLoan,
    watchUpdateLoan,
    watchFetchLoanRepayments,
    watchFetchDefaulters,
    watchDownloadCVS,
    watchDownloadRepaymentsCVS,
    watchFetchRoLoansSummary,
} from "./loans";
import {
    watchCreateRepayment,
    watchFetchRepayments,
    watchGetRepayment,
    watchUpdateRepayment,
} from "./repayments";
import { watchFetchStatistics } from "./statistics";
import { watchFetchReports, watchExtractRepaymentsReport } from "./reports";
import {
    watchGetCashManagement,
    watchCreateCashManagement,
    watchUpdateCashManagement,
    watchFetchCashManagements,
    watchFetchCashTotals,
    watchCashFlowCVS,
} from "./cash-management";

export default function* rootSaga() {
    yield all([
        watchLogin(),
        watchSignUp(),
        watchForgotPassword(),
        watchResetPassword(),
        watchChangePassword(),
        watchLogout(),
        watchCreateUser(),
        watchGetUser(),
        watchUpdateUser(),
        watchFetchUsers(),
        watchAttachUserOrganization(),
        watchCreateOrganization(),
        watchGetOrganization(),
        watchFetchOrganizations(),
        watchUpdateOrganization(),
        watchCreateLoan(),
        watchFetchLoans(),
        watchFetchAllLoans(),
        watchFetchUserLoans(),
        watchGetLoan(),
        watchUpdateLoan(),
        watchCreateRepayment(),
        watchFetchRepayments(),
        watchGetRepayment(),
        watchUpdateRepayment(),
        watchExtractRepaymentsReport(),
        watchFetchLoanRepayments(),
        watchFetchStatistics(),
        watchFetchReports(),
        watchGetCashManagement(),
        watchCreateCashManagement(),
        watchUpdateCashManagement(),
        watchFetchCashManagements(),
        watchFetchCashTotals(),
        watchFetchDefaulters(),
        watchDownloadCVS(),
        watchCashFlowCVS(),
        watchDownloadRepaymentsCVS(),
        watchFetchRoLoansSummary(),
    ]);
}
