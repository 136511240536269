import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/cash-management/actions';
import { getLocalState } from '../../../util/helpers/reusable-functions';
import View from './components';

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
	const { isloading, cash_flows, isloadingCashTotals, cash_totals, page, pageCount, isDownloading } = state.cash_management;
  return {
  	isloading, 
    isloadingCashTotals,
    cash_totals,
  	cash_flows,
    page,
    pageCount,
    isDownloading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchCashManagements: (query={page: 1}) => {
    fetchCashManagements: (page=1, searchTerm, from_date, to_date) => {
      const query = {
        page,
        search_term: searchTerm,
        from_date,
        to_date
      }
      dispatch(Creators.fetchCashManagements(user && user.organization_id && user.organization_id._id, query));
    },
    fetchCashTotals: (searchTerm, from_date, to_date) => {
      const query = {
        search_term: searchTerm,
        from_date,
        to_date
      }
      dispatch(Creators.fetchCashTotals(user && user.organization_id && user.organization_id._id, query));
    },
    downloadCSV: (query) => {
      const _query = {
        isReport: true,
        ...query
      }
      dispatch(Creators.downloadCashflowCSV(user && user.organization_id && user.organization_id._id, _query));
    }
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(View);

