import { createActions } from 'reduxsauce'
 
export const { Types, Creators } = createActions({
  fetchUsers: ['id', 'query'],
  fetchUsersSuccess: ['responseData', 'page', 'pageCount'],
  fetchUsersFailure: ['error'],

  createUser: ['payload'],
  createUserSuccess: ['responseData'],
  createUserFailure: ['error'],

  getUser: ['id'],
  getUserSuccess: ['responseData'],
  getUserFailure: ['error'],

  updateUser: ['id', 'payload'],
  updateUserSuccess: ['responseData'],
  updateUserFailure: ['error'],

  storeUserDataChunks: ['payload'],

  attachUserOrganization: ['id', 'payload'],
  attachUserOrganizationSuccess: ['responseData'],
  attachUserOrganizationFailure: ['error'],

  reset: null
}, {});
