import React, { Fragment, Component } from 'react';
import { Card, Col, Row, Divider } from 'antd';
import PageContent from '../../reusable-components/PageContent';
import { getLocalState } from '../../util/helpers/reusable-functions';
import "../../styles/dashboard.less";

class Permission extends Component {
  render() {
    console.log("Permissions module")
    return (
      <>
        <PageContent title="Permission Denied">
          <div>
            <p>You have no permission to access this module</p>
          </div>
        </PageContent>
      </>
    );
  }
}

export default Permission;

