import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/statistics/actions'
import {
	fetchStatistics as fetchStatisticsApi,
} from '../../api/statistics';
import { history } from '../../../util/helpers/browserHistory';
import { OpenNotificationWithIcon } from '../../../reusable-components/Notifications';

export function* fetchStatistics(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchStatisticsApi, query);
		const resp = response && response.data;
		yield put(Creators.fetchStatisticsSuccess(resp))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchStatisticsFailure(errorsFound));
	}
}

export function* watchFetchStatistics() {
	yield takeEvery(Types.FETCH_STATISTICS, fetchStatistics);
}

