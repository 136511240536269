import * as yup from 'yup';
import { Field } from 'formik';

export const emailNotLongEnough = 'Email must be atleast 3 characters';
export const passwordNotLongEnough = 'Password must be atleast 3 characters';
export const invalidEmail = 'Email must be a valid email';
export const fieldRequired = 'Field is required';
export const positiveNumber = 'Positive number is required';

// setup reusable yup fxns...
export const passwordValidation = yup
	.string()
	.min(3, passwordNotLongEnough)
	.max(255)
	.required('Password is required')

// you construct your schema for the current form
export const validationSchema = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	password: passwordValidation
})

export const validationSchemaLoginForm = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	password: passwordValidation
})

export const validationSchemaForgotForm = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required()
});

export const validationSchemaResetForm = yup.object().shape({
	password: passwordValidation,
	confirmPassword: yup
		.string()
		.required('Confirm Password is required')
   		.oneOf([yup.ref('password'), null], 'Passwords do not match')
});

export const validationSchemaSignUpForm = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	firstName: yup
		.string()
		.required(),
	lastName: yup
		.string()
		.required(),
	password: passwordValidation,
	confirmPassword: yup
		.string()
		.required('Confirm Password is required')
   		.oneOf([yup.ref('password'), null], 'Passwords do not match')
});

export const validationSchemaCreateUpdateOrganizationForm = yup.object().shape({
	name: yup
		.string()
		.required(),
	address: yup
		.string()
		.required(),
});

export const validationSchemaCreateUpdateUserForm = yup.object().shape({
	name: yup
		.string()
		.required(fieldRequired),
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	gender: yup
		.string()
		.required(fieldRequired),
	role: yup
		.string()
		.required(fieldRequired),
	current_address: yup.object().shape({
		village: yup
			.string()
			.required(fieldRequired),
		parish: yup
			.string()
			.required(fieldRequired),
		sub_county: yup
			.string()
			.required(fieldRequired),
		district: yup
			.string()
			.required(fieldRequired),
	}),
});

export const validationSchemaCreateUpdateClientForm = yup.object().shape({
	name: yup
		.string()
		.required(fieldRequired),
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(fieldRequired),
	gender: yup
		.string()
		.required(fieldRequired),
});

export const validationSchemaStepOneForm = yup.object().shape({
	name: yup
		.string()
		.required(fieldRequired),
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.email(invalidEmail)
		.required(),
	gender: yup
		.string()
		.required(fieldRequired),
	date_of_birth: yup
		.string()
		.required(fieldRequired),
	phone_number: yup
		.string()
		.required(fieldRequired),
	national_id: yup
		.string()
		.required(fieldRequired),
	extra_info:  yup
		.string()
		.required(fieldRequired),
});

// object().shape({
//     branch1: object().shape({
//          branch1Key: string().required()
//     }),
//    branch2: object().shape({
//          branch2Key: object().when('branch1', (branch1, schema) => branch1.branch1Key === 'xyz'? schema.nullable() : schema.required()
//    })
// })

export const validationSchemaStepTwoForm = yup.object().shape({
	current_address: yup.object().shape({
		village: yup
			.string()
			.required(fieldRequired),
		parish: yup
			.string()
			.required(fieldRequired),
		sub_county: yup
			.string()
			.required(fieldRequired),
		district: yup
			.string()
			.required(fieldRequired),
	}),
	period_at_current_address: yup.object().shape({
		years: yup
			.string()
			.required(fieldRequired),
		months: yup
			.string()
			.required(fieldRequired),
		days: yup
			.string()
			.required(fieldRequired),
	}),
	chairperson: yup.object().shape({
		name: yup
			.string()
			.required(fieldRequired),
		phone: yup
			.string()
			.required(fieldRequired),
	}),
	// previous_address: yup.object().shape({
	// 	//village: yup.object().when('current_address', (current_address, schema) => parseInt(current_address.years) > 0 ? yup.string().nullable() : yup.string().required(),
	// 		// .string()
	// 		// .required(fieldRequired),
	// 	parish: yup
	// 		.string().nullable(),
	// 		// .required(fieldRequired),
	// 	sub_county: yup
	// 		.string().nullable(),
	// 		// .required(fieldRequired),
	// 	district: yup
	// 		.string().nullable(),
	// 		// .required(fieldRequired),
	// 	// branch2Key: object().when('branch1', (branch1, schema) => branch1.branch1Key === 'xyz'? schema.nullable() : schema.required()
	// }),
	// previous_address: yup.object().shape({
	// 	// village: yup
	// 	// 	.string()
	// 	// 	.nullable(),
	// 	// village: yup.object().when('current_address', (current_address, schema) => parseInt(current_address.years) > 0 ? yup.schema.nullable() : yup.schema.required()),
	// 	village: yup.string().when('period_at_current_address', (period_at_current_address, schema) => {
	// 		console.log("period_at_current_address ", period_at_current_address)
	// 		console.log("schema ", schema)
	// 		return (parseInt(period_at_current_address.years) > 0 ? schema.required() : schema.nullable())}),
	// 	parish: yup
	// 		.string()
	// 		.nullable(),
	// 	sub_county: yup
	// 		.string()
	// 		.nullable(),
	// 	district: yup
	// 		.string()
	// 		.nullable(),
	// }),
	period_at_previous_address: yup.object().shape({
		years: yup
			.string().nullable(),
			// .required(fieldRequired),
		months: yup
			.string().nullable(),
			// .required(fieldRequired),
		days: yup
			.string().nullable()
			// .required(fieldRequired),
	}),
});

export const validationSchemaStepThreeForm = yup.object().shape({
	business: yup.object().shape({
		name: yup
			.string()
			.required(fieldRequired),
	}),
	file_fees: yup
		.string()
		.required(fieldRequired),
	active: yup
		.string()
		.required(fieldRequired),
});

export const validationSchemaCreateUpdateLoanForm = yup.object().shape({
	relationship_officer: yup
		.string()
		.required(fieldRequired),
	verified_by: yup
		.string()
		.required(fieldRequired),
	release_date: yup
		.string()
		.required(fieldRequired),
	loan_interest_rate_percentage: yup
		.string()
		.required(fieldRequired),
	// loan_interest_rate_period: yup
	// 	.string()
	// 	.required(fieldRequired),
	loan_duration: yup
		.string()
		.required(fieldRequired),
	loan_duration_period: yup
		.string()
		.required(fieldRequired),
	repayment_cycle: yup
		.string()
		.required(fieldRequired),
	amount: yup
		.string()
		.required(fieldRequired),
	surcharge: yup
		.string()
		.required(fieldRequired),
	loan_application_fees: yup
		.string()
		.required(fieldRequired), 
	purpose: yup
		.string()
		.required(fieldRequired),
});

export const validationSchemaCreateUpdateRepaymentsForm = yup.object().shape({
	amount_paid: yup
		.string()
		.required(),
	repayment_against: yup
		.string()
		.required(),
	received_by: yup
		.string()
		.required(),
});

// you construct your schema for the current form
export const validationSchemaResetPwd = yup.object().shape({
	email: yup
		.string()
		.min(3, emailNotLongEnough)
		.max(255)
		.required('Email/Username field is required'),
	captchaToken: yup
		.string()
		.required('Captcha is required')
});

export const validationSchemaChangePasswordForm = yup.object({
	old_password: yup
		.string()
		.required('Password is required'),
	new_password: yup
		.string()
		.required('Password is required'),
	confirm_password: yup
		.string()
		.required('Confirm Password is required')
		.oneOf([yup.ref('new_password'), null], 'Passwords do not match')
});

export const validationSchemaCashManagementForm = yup.object({
	amount: yup
		.string()
		.required(fieldRequired),
	flow_type: yup
		.string()
		.required(fieldRequired),
});

