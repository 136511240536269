import React, { Component } from 'react';
import Header from "../../../reusable-components/Header"; 
import PageFooter from "../../../reusable-components/Footer";
import { getLocalState } from '../../../util/helpers/reusable-functions';
import { Layout } from "antd";

const { Content } = Layout;

class LayoutPage extends Component {
    render() {
        const { children, logout } = this.props;
        const user = getLocalState('lsu-user-details');
        const setupComplete = getLocalState('company-set-up-complete');

        return(
            <>
                <div id="main-wrapper">
                    <Header 
                        loggedInUser={user} 
                        logoutMethod={logout}
                        setupComplete={setupComplete} />
                        <Content style={{ width: "100%" }}>
                            {children}
                        </Content>
                    {/*<PageFooter />*/}
                </div>
            </>
        );
    }
}

export default LayoutPage;