import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import { DateField } from '../../../../reusable-components/DateField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaCashManagementForm } from '../../../../util/validations';
import { cash_inflow_options, cash_outflow_options, cash_operation_type_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import { history } from '../../../../util/helpers/browserHistory';

const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateForm extends Component {

  componentDidMount() {
    const { fetchCashManagement, match, reset } = this.props;
    if (match.params.id) {
      fetchCashManagement(match.params.id);
    } else {
      reset();
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    console.log("Values ", values)

    return (
      <>
          <PageContent 
            title={` ${ this.props.match.params.id ? 'Update': 'Create' } Cash Mangement`}
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            hasSubmitBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            hasCancelBtn={true}
            formClassNames=""
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <Row>
                      <Col span={24}>
                        <Field
                          name="cash_operation_type"
                          label="Cash flow type"
                          size="large"
                          component={SelectField}
                          options={cash_operation_type_options}
                          placeholder="select option"
                        />
                      </Col>
                      <Col span={24}>
                        { values && values.cash_operation_type === 'cash_inflow' && 
                          <Field
                            name="flow_type"
                            label="Cash Inflow"
                            size="large"
                            component={SelectField}
                            options={cash_inflow_options}
                            placeholder="select option"
                          />
                        }
                        { values && values.cash_operation_type === 'cash_outflow' && 
                          <Field
                            name="flow_type"
                            label="Cash Outflow"
                            size="large"
                            component={SelectField}
                            options={cash_outflow_options}
                            placeholder="select option"
                          />
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row>
                      <Col span={24}>
                        <FormItem>
                          <Field
                            type="number"
                            name="amount"
                            label="Amount"
                            size="large"
                            component={InputField}
                            placeholder="enter amount"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem>
                          <Field
                            name="comment"
                            label="Comment"
                            placeholder="Add comment..."
                            size="large"
                            component={TextAreaField}
                            rows={9}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

CreateUpdateForm.propTypes = {
  isloading: PropTypes.bool,
}

const CreateUpdate = withFormik({
  validationSchema: validationSchemaCashManagementForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details'); 
    values.created_by = user && user._id; 

    if (props.match.params.id) {
      props.updateCashManagement(props.match.params.id, values)
    } else {
      values.org_id = user.organization_id && user.organization_id._id
      props.createCashManagement(values);
    }
  },

  displayName: 'create-update-cash-form',
  enableReinitialize: true
})(CreateUpdateForm);

export default CreateUpdate;

