import React, { useState } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import Pagination from '../../../../reusable-components/Pagination';
import { history } from '../../../../util/helpers/browserHistory';
import {  getUserRole } from '../../../../services/api/axiosDefaults';
import { isPermitted } from '../../../../util/helpers/Can';
import { numberWithCommas } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const role = getUserRole();

const columns = (props) => [
  {
    title: 'Name',
    dataIndex: 'user_id.name',
    key: 'user_id.name',
    render: (text, row) => {
      return get(row, 'user_id.name') + '(' + get(row, 'user_id.phone_number') + ')'
    }
  },
  // {
  //   title: 'Contact',
  //   dataIndex: 'user_id.phone_number',
  //   key: 'user_id.phone_number',
  // },
  {
    title: 'Loan Officer',
    dataIndex: 'relationship_officer.name',
    key: 'relationship_officer.name',
  },
  {
    title: 'Loan',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, row) => {
      return numberWithCommas(row.amount)
    }
  },
  {
    title: 'Amount Paid',
    dataIndex: 'amount_paid',
    key: 'amount_paid',
    render: (text, row) => {
      return numberWithCommas(row.amount_paid)
    }
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text, row) => {
      return numberWithCommas(row.balance)
    }
  },
  {
    title: 'Repayment Cycle',
    dataIndex: 'repayment_cycle',
    key: 'repayment_cycle',
    render: (text, row) => {
      return (<><span style={{color: 'purple'}}>{row.repayment_cycle}</span><br/><span>Surcharge: {numberWithCommas(row.surcharge)} @ {row.loan_interest} {row.loan_interest_rate_period}</span></>)         
    }
  },
  {
    title: 'Date Released',
    dataIndex: 'release_date',
    key: 'release_date',
    render: (text, row) => {
      return moment(row.release_date).format("DD/MM/YYYY hh:mm")
    }
  },
  {
    title: 'Due date',
    dataIndex: 'last_repayment_date',
    key: 'last_repayment_date',
    render: (text, row) => {
      return moment(row.last_repayment_date).format("DD/MM/YYYY")
    }
  },
  // {
  //   title: 'Surcharge',
  //   dataIndex: 'principal',
  //   key: 'principal',
  //   render: (text, row) => {
  //     return (<span>{numberWithCommas(row.surcharge)} @ {row.loan_interest} {row.loan_interest_rate_period}</span>)         
  //   }
  // },
  {
    title: 'Penalty fees',
    dataIndex: 'penalty_fees',
    key: 'penalty_fees',
    render: (text, row) => {
      if (row.penalty_fees > 0)
        return <span style={{color: 'red'}}>{numberWithCommas(row.penalty_fees)}</span>
      else
        return numberWithCommas(row.penalty_fees)      
    }
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (text, row) => {
      let total = parseInt(row.balance || 0) + parseInt(row.penalty_fees || 0)
      return numberWithCommas(total)       
    }
  },
  {
    title: 'Status',
    dataIndex: 'loan_status',
    key: 'loan_status',
    render: (text, row) => {
      if (row.loan_status === 'pending')
        return <span style={{color: 'gray'}}>{row.loan_status}</span>
      else if (row.loan_status === 'open' || row.loan_status === 'closed')
        return <span style={{color: 'green'}}>{row.loan_status}</span>
      else
        return <span style={{color: 'red'}}>{row.loan_status}</span>        
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <>
          <Icon 
            type="eye" 
            style={{ fontSize: 20, cursor: 'pointer', color: 'blue' }} 
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/repayments/loan/${row._id}`,
                search: '',
                state: { name: row.user_id.name, userId: row.user_id._id }
              });
            }} />
        </>
      )         
    }
  }
];



const List = ({ searchTerm, status, from_date, to_date, loans, page, pageCount, isloading, ...props}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const handleChange = (e) => {
    console.log("value is ", e.target.value);
  }

  return ( 
    <>
      { isloading ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { props.isloading && (<div>Loading ...</div>) }
              { loans && loans.length > 0 &&                  
                <Table 
                  className="table"
                  dataSource={loans} 
                  pagination={false}
                  columns={columns(props)} />
            	}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing Loans</span>
                </div>
                <Pagination
                  handlePagination={(page) => {
                    props.fetchLoans({ page, search_term: searchTerm, status, from_date, to_date })
                  }}
                  page={page}
                  pageCount={pageCount} />
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

