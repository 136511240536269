import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, Avatar } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import PageContent from '../../../../reusable-components/PageContent';
import { history } from '../../../../util/helpers/browserHistory';
import { printDocument } from '../../../../util/helpers/reusable-functions';
import moment from 'moment';
import PropTypes from 'prop-types';
import get from 'lodash/get';

export default class DetailsComponent extends Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { fetchUser, match } = this.props;
    fetchUser(match.params.id);
  }

  HeaderBtns = () => {
    return (
      <button 
        type="button"
        className="btn header-btn" 
        onClick={() => console.log("Exporting data")}>Export</button>
    )
  } 

  render() {
    const { isloading, match, user } = this.props;

    console.log("User is ", user)

    return (
      <>
        <PageContent 
          title="Client Details"
          headerBtns={<this.HeaderBtns />}
          goBack={() => {}}
          moveNext={() => {}}
          cancelAction={() => {}}
          cardClass="card-container custom-stepper-content"
          customButtonContainerClass="align-btn-left"
          showFooter={false}
          hasBackBtn={true}
          hasCustomizableNextButton={true}
          customizableNextBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
          submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
          formClassNames=""
          buttonState={isloading}
          submitting={isloading}
          btnDisabled={isloading}
          handleSubmit={() => {}}
          showExportBtn={true}
          exportBtnLabel="Export"
          exportData={() => printDocument('printdiv', 'profile.pdf', 600, 600)}>
          <Row gutter={30}>
            <div id="printdiv" ref={this.myRef}>
              <Col span={24}>
                <div>
                  <Avatar
                    src={user && user.profile_picture ? get(user, 'profile_picture.path') : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"}
                    alt="Avatar"
                    className="profilePic"
                  />
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <h4 className="header"><b>Personal Details</b></h4>
                  <div className="address">
                    <p>Name: {user && user.name}</p>
                    <p>Email: {user && user.email}</p>
                    <p>Gender: {user && user.gender}</p>
                    <p>Contact: {user && user.phone_number}</p>
                    <p>Date of Birth: {moment(user && user.date_of_birth).format('YYYY-MM-DD')}</p>
                    <p>Extra Info: {user && user.extra_info}</p>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <h4 className="header"><b>Address of the Applicant</b></h4>
                  <div>
                    <div className="address">
                      <i><b>Current Address</b></i>
                      <p>Village: { get(user, 'current_address.village') }</p>
                      <p>Parish: { get(user, 'current_address.parish') }</p>
                      <p>Sub-county: { get(user, 'current_address.sub_county') }</p>
                      <p>District: { get(user, 'current_address.district') }</p>
                      <i><b>Period at current Address</b></i>
                      <p>Years: { get(user, 'period_at_current_address.years') }</p>
                      <p>Months: { get(user, 'period_at_current_address.months') }</p>
                      <p>Days: { get(user, 'period_at_current_address.days') }</p>
                      <i><b>Chairperson Details</b></i>
                      <p>Name: { get(user, 'chairperson.name') }</p>
                      <p>Contact: { get(user, 'chairperson.phone') }</p>
                    </div>
                  </div>
                  {user && user.previous_address && user.previous_address.village && 
                    (<div>
                      <i><b>Previous Address</b></i>
                      <div className="address">
                        <p>Village: { get(user, 'previous_address.village') }</p>
                        <p>Parish: { get(user, 'previous_address.parish') }</p>
                        <p>Sub-county: { get(user, 'previous_address.sub_county') }</p>
                        <p>District: { get(user, 'previous_address.district') }</p>
                        <i><b>Period at previous Address</b></i>
                        <p>Years: { get(user, 'period_at_previous_address.years') }</p>
                        <p>Months: { get(user, 'period_at_previous_address.months') }</p>
                        <p>Days: { get(user, 'period_at_previous_address.days') }</p>
                      </div>
                    </div>)
                  }
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <h4 className="header"><b>Other Details</b></h4>
                  <div className="address">
                    <p>Business Name: {user && user.business_name}</p>
                    <p>File fees: {user && user.file_fees}</p>
                    <p>Status: {user && user.active}</p>
                    { user && user.deactivated && 
                      (<>
                        <i><b>Reason for Deactivation</b></i>
                      </>)
                    }
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <h4 className="header"><b>Documents</b></h4>
                  <div className="address">
                    <p>
                      <i>
                        <b style={{marginRight: '10px'}}>Profile picture</b>
                        { user && user.profile_picture ? 
                          <a onClick={()=> window.open( get(user, 'profile_picture.path') )}>Click to preview</a> :
                          <span>File missing</span>
                        }
                      </i>
                    </p>
                    <p>
                      <i>
                        <b style={{marginRight: '10px'}}>Client National ID</b>
                        { user && user.personal_docs ? 
                          <a onClick={()=> window.open( get(user, 'personal_docs.path') )}>Click to preview</a> :
                          <span>File missing</span>
                        }
                      </i>
                    </p>
                    <p>
                      <i>
                        <b style={{marginRight: '10px'}}>Chairperson Documents</b> 
                        { user && user.chairperson && user.chairperson.docs ? 
                          <a onClick={()=> window.open( get(user, 'chairperson.docs.path') )}>Click to preview</a> :
                          <span>File missing</span>
                        }
                      </i>
                    </p>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </PageContent>
      </>
    );
  }
}

DetailsComponent.propTypes = {
  isloading: PropTypes.bool
}


