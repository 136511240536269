import React, { useState } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import Pagination from '../../../../reusable-components/Pagination';
import { history } from '../../../../util/helpers/browserHistory';
import { numberWithCommas } from '../../../../util/helpers/reusable-functions';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const columns = (props) => [
  {
    title: 'Amount Paid',
    dataIndex: 'amount_paid',
    key: 'amount_paid',
    render: (text, row) => {
      return numberWithCommas(row.amount_paid)
    }
  },
  {
    title: 'Paid againt',
    dataIndex: 'repayment_against',
    key: 'repayment_against',
  },
    {
    title: 'Loan balance',
    dataIndex: 'loan_balance',
    key: 'loan_balance',
    render: (text, row) => {
      return numberWithCommas(row.loan_balance)
    }
  },
    {
    title: 'Penalty fees',
    dataIndex: 'penalty_fees',
    key: 'penalty_fees',
    render: (text, row) => {
      return numberWithCommas(row.penalty_fees)
    }
  },
  {
    title: 'Received by',
    dataIndex: 'received_by.name',
    key: 'received_by.name',
  },
  {
    title: 'Date Received',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, row) => {
      return moment(row.created_at).format("DD/MM/YYYY hh:mm")
    }
  },
];


const List = ({ match, page, pageCount, repayments, isFetchingRepayments, ...props}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const handleChange = (e) => {
    console.log("value is ", e.target.value);
  }

  return ( 
    <>
      { isFetchingRepayments ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { isFetchingRepayments && (<div>Loading ...</div>) }
              { repayments && repayments.length > 0 &&                  
                <Table 
                  className="table"
                  dataSource={repayments} 
                  pagination={false}
                  columns={columns(props)} />
            	}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing Repayments</span>
                </div>
                {/*<Pagination defaultCurrent={1} total={repayments && repayments.length} />*/}
                <Pagination
                  handlePagination={(page) => {
                    props.fetchLoanRepayments(match.params.id, {page})
                  }}
                  page={page}
                  pageCount={pageCount} />
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

