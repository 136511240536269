import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Creators } from '../../../services/redux/auth/actions';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Alert, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import { InputField } from '../../../reusable-components/InputField';
import Spinner from '../../../reusable-components/Spinner';
import { validationSchemaResetForm } from '../../../util/validations';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class ResetPasswordForm extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    const {reset} = this.props;
    reset();
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, values, formerrors, message, isRecovered, isError, errors } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Reset";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <div className="auth-container">
          <Card style={{ width: "100%" }}>
            <Form onSubmit={handleSubmit} {...formItemLayout}>
              <Row>
                <Col span={24}><h2>Reset Password</h2></Col>
                <Col span={24}>
                  { isError && <Alert message={typeof errors === 'string' ? errors : message} type="error" /> }
                  { isRecovered && <Alert message={message} type="success" /> }
                </Col>
                <Col md={24} sm={24}>
                  <Field
                    type="password"
                    name='password'
                    label="Password"
                    placeholder="enter password"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={24} sm={24}>
                  <Field
                    type="password"
                    name='confirmPassword'
                    label="Confirm Password"
                    placeholder="confirm password"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem className="form-btns-container">
                    <Row>
                      <Col>
                        <button type="submit" className="auth-buttons">{buttonState}</button>
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </>
    );
  }
}

ResetPasswordForm.propTypes = {
  isloading: PropTypes.bool,
}

const ResetPassword = withFormik({
  validationSchema: validationSchemaResetForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.resetPassword(props.match.params.token, values.password);
  },

  displayName: 'reset-form',
  enableReinitialize: true
})(ResetPasswordForm);

const mapStateToProps = state => {
  const { isloading, auth, message, errors, isError, isRecovered } = state.auth;
  return {
    auth,
    isloading,
    message,
    errors,
    isError,
    isRecovered
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (token, password) => {
      dispatch(Creators.resetPassword(token, password));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword);

