import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import PageContent from '../../../../reusable-components/PageContent';
import { history } from '../../../../util/helpers/browserHistory';
import Filters from './filters';
import List from './list';

import PropTypes from 'prop-types';

export default class ListComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      searchTerm: null,
      from_date: null,
      to_date: null
    };
  }

  componentDidMount() {
    const { fetchDefaulters, resetPagination } = this.props;
    resetPagination();
    fetchDefaulters({ page: 1 });
  }

  render() {
    const { isloading, downloadCSV, isDownloading, fetchDefaulters } = this.props;
    const { searchTerm, from_date, to_date } = this.state;
    let buttonState = isloading ? <Spinner /> : "Continue";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <PageContent 
          title="Defaulters"
          showExportBtn={true}
          isFilterContainer={true}
          exportData={() => downloadCSV({ search_term: searchTerm, from_date, to_date })}
          isDownloading={isDownloading}
          {...this.props}>
          <Filters 
            handleChange={(from_date, to_date, searchTerm) => {
              this.setState({ searchTerm, from_date, to_date });
              fetchDefaulters({ page: 1, search_term: searchTerm, from_date, to_date });
            }}
            {...this.props} />
        </PageContent>
        <PageContent>
            <div className="filters-list">
              <List 
              searchTerm={searchTerm}
              from_date={from_date}
              to_date={to_date}
              {...this.props} />
            </div>
        </PageContent>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool,
  initialValues: PropTypes.object,
}


