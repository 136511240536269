import { apiRequest, apiRequestOthers } from "../apiRequest";
import { LOANS_ENDPOINT, REPAYMENTS_ENDPOINT } from "../urls";

export const fetchLoans = (org_id, query) => {
	let search_term = query && query.search_term;
	let status = query && query.status;
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;
	let page = query && query.page;

	return apiRequest(
		"GET",
		`${LOANS_ENDPOINT}/org/${org_id}?
		${search_term ? `&search=${search_term}` : ""}
		${status ? `&status=${status}` : ""}
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}
		${page ? `&page=${page}` : ""}`
	);
};

export const fetchAllLoans = (org_id, query) => {
	let search_term = query && query.search_term;
	let status = query && query.status;
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;
	let page = query && query.page;

	return apiRequest(
		"GET",
		`${LOANS_ENDPOINT}?
		${search_term ? `&search=${search_term}` : ""}
		${status ? `&status=${status}` : ""}
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}
		${page ? `&page=${page}` : ""}`
	);
};

export const fetchUserLoans = (user_id) => {
	return apiRequest("GET", `${LOANS_ENDPOINT}/userloans/${user_id}`);
};

export const getLoan = (id) => {
	return apiRequest("GET", `${LOANS_ENDPOINT}/${id}/`);
};

export const createLoan = (data) => {
	return apiRequest("POST", LOANS_ENDPOINT, data);
};

export const updateLoan = (id, data) => {
	return apiRequest("PATCH", `${LOANS_ENDPOINT}/${id}/`, data);
};

export const fetchLoan = (id) => {
	return apiRequest("GET", `${LOANS_ENDPOINT}/${id}/`);
};

export const fetchLoanRepayments = (id, query) => {
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;
	let page = query && query.page;
	let isReport = query && query.isReport;

	return apiRequest(
		"GET",
		`${REPAYMENTS_ENDPOINT}/loan/${id}/?
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}
		${isReport ? `&isReport=${isReport}` : ""}
		${page ? `&page=${page}` : ""}`
	);
};

export const fetchDefaulters = (org_id, query) => {
	let search_term = query && query.search_term;
	let status = query && query.status;
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;
	let page = query && query.page;
	let isReport = query && query.isReport;

	return apiRequest(
		"GET",
		`${LOANS_ENDPOINT}/defaulters/org/${org_id}?
		${search_term ? `&search=${search_term}` : ""}
		${status ? `&status=${status}` : ""}
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}
		${isReport ? `&isReport=${isReport}` : ""}
		${page ? `&page=${page}` : ""}`
	);
};

export const fetchRoLoansSummary = (id, query) => {
	let page = query && query.page;
	let org_id = query && query.org_id;
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;

	return apiRequest(
		"GET",
		`${LOANS_ENDPOINT}/summary/loan-officer/${id}?
		${page ? `&page=${page}` : ""}
		${org_id ? `&org_id=${org_id}` : ""}
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}`
	);
};
