import {apiRequest, api, apiRequestOthers} from "../apiRequest";
import { REPORTS_ENDPOINT, } from '../urls';

export const fetchReports = (query) => {
	let org_id = query && query.org_id
	let filters = query && query.filters
	let from_date = query && query.from_date
	let to_date = query && query.to_date

	// return apiRequest('GET', `${REPORTS_ENDPOINT}?
	// 	${ org_id ? `&org_id=${org_id}`: '' }
	// 	${ filters ? `&filters=${filters}`: '' }`);
	//return apiRequestOthers('PATCH', `${USERS_ENDPOINT}/${id}/`, data, '*/*');
	return apiRequestOthers('GET', `${REPORTS_ENDPOINT}?
		${ org_id ? `&org_id=${org_id}`: '' }
		${ filters ? `&filters=${filters}`: '' }
		${ from_date ? `&from_date=${from_date}`: '' }
		${ to_date ? `&to_date=${to_date}`: '' }`, {}, '*/*');
}

// /api/v1/reports/user?org_id=5e31701b870c7500041d20da&user_id=5e354a7f02b51e0004f1176b
export const extractRepaymentsReport = (org_id, user_id, query) => {
	let from_date = query && query.from_date
	let to_date = query && query.to_date

	return apiRequestOthers('GET', `${REPORTS_ENDPOINT}/user?
		${ org_id ? `org_id=${org_id}`: '' }
		${ user_id ? `&user_id=${user_id}`: '' }
		${ from_date ? `&from_date=${from_date}`: '' }
		${ to_date ? `&to_date=${to_date}`: '' }`, {}, '*/*');
}

