import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  isloading: false,
  isFetching: false,
  user: {},
  users: [],
  page: 0,
  pageCount: 0
}

export const fetchUsers = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchUsersSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, users: action.responseData, page: action.page, pageCount: action.pageCount, isloading: false }
}
 
export const fetchUsersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const getUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetching: true }
}
 
export const getUserSuccess = (state = INITIAL_STATE, action) => {
  let response = action.responseData
  if (response.active) {
    response.active = "active"
  } else {
    response.active = "deactive"
  }
  return { ...state, user: response, isFetching: false }
}
 
export const getUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, errors: action.error }
}

export const createUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const createUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false }
}
 
export const createUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const updateUser = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const updateUserSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false }
}
 
export const updateUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const storeUserDataChunks = (state = INITIAL_STATE, action) => {
  return { ...state, user: {...state.user, ...action.payload} }
}

export const attachUserOrganization = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const attachUserOrganizationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false }
}
 
export const attachUserOrganizationFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  const userObj = {
    name: undefined,
    email: undefined,
    phone_number: undefined,
    national_id: undefined,
    gender: undefined,
    date_of_birth: undefined,
    active: undefined,
    extra_info: undefined,
    role: undefined,
    organization_id: undefined,
    current_address: { village: undefined, parish: undefined, sub_county: undefined, district: undefined },
    period_at_current_address: { years: undefined, months: undefined, days: undefined },
    chairperson: { name: undefined, contact: undefined },
    previous_address: { village: undefined, parish: undefined, sub_county: undefined, district: undefined },
    period_at_previous_address: { years: undefined, months: undefined, days: undefined },
    business: undefined,
    other_business: undefined,
    file_fees: undefined,
    profile_picture: undefined,
    personal_docs: undefined,
    chairperson_docs: undefined,
    password: undefined
  }
  return { ...state, isloading: false, errors: null, user: userObj, users: [], page: null, pageCount: null }
}
  
export const HANDLERS = {
  [Types.FETCH_USERS]: fetchUsers,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.CREATE_USER]: createUser,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.GET_USER]: getUser,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
  [Types.STORE_USER_DATA_CHUNKS]: storeUserDataChunks,
  [Types.UPDATE_USER]: updateUser,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.ATTACH_USER_ORGANIZATION]: attachUserOrganization,
  [Types.ATTACH_USER_ORGANIZATION_SUCCESS]: attachUserOrganizationSuccess,
  [Types.ATTACH_USER_ORGANIZATION_FAILURE]: attachUserOrganizationFailure,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
