import rules from "../../rbac-rules";

const check = (rules, role, perform, data) => {
  if (!role) {
    return false;
  }

  if (Array.isArray(perform)) {
    let ruleFound = false
    perform.forEach(action => {
      if (Array.isArray(rules[role]) && rules[role].includes(action)) {
        ruleFound = true
      }
    });
    return ruleFound ? true : false;
  } else {
    if (Array.isArray(rules[role]) && rules[role].includes(perform)) {
      return true;
    }
  }

  return false;
};

const Can = props => 
  check(rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

export const isPermitted = (role, perform, data) => {
  const isAllowed = check(rules, role, perform, data);
  return isAllowed;
}

export default Can;