import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, Upload } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaCreateUpdateRepaymentsForm } from '../../../../util/validations';
import { paid_against_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Dragger } = Upload;

class CreateUpdateRepaymentsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    }
  }

  componentDidMount() {
    const { match, fetchUsers, fetchRepayment, reset } = this.props;
    if (match.params.id) {
      fetchRepayment(match.params.id);
    } else {
      reset();
    }
    fetchUsers();
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, users, location } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    const userId = location.state.userId
    const userName = location.state.name
    const loanId = location.state.loanId

    let user_options = [];
    users && users.length > 0 && users.map(filteredUser => {
      let userObj = {
        label: filteredUser.name,
        value: filteredUser._id
      }
      user_options.push(userObj)
    });

    return (
      <>
          <PageContent 
            title={` ${ this.props.match.params.id ? 'Update': 'Create' } Repayment for ${userName} `}
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            hasSubmitBtn={true}
            submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
            hasCancelBtn={true}
            formClassNames=""
            // errors={error_message}
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}> 
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={15}>
                      <Col span={24}>
                      	<Field
                          type="number"
                          name='amount_paid'
                          label="Amount"
                          placeholder="repayment amount"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col span={24}>
                        <Field
                          name="repayment_against"
                          label="Repayment Paid Against"
                          size="large"
                          component={SelectField}
                          options={paid_against_options}
                          placeholder="select option"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row gutter={15}>
                      <Col span={24}>
                        <Field
                          name="received_by"
                          label="Received by"
                          size="large"
                          component={SelectField}
                          options={user_options}
                          placeholder="Recieved By"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

// {
//   "loan_id": "string",
//   "amount_paid": "string",
//   "received_by": "string",
//   "date": "string"
// }

CreateUpdateRepaymentsForm.propTypes = {
  isloading: PropTypes.bool,
}

const CreateUpdateRepayments = withFormik({
  validationSchema: validationSchemaCreateUpdateRepaymentsForm,

  mapPropsToValues: (props) => (props.repayment),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');

    values.loan_id = props.location.state.loanId
    values.date = '2017-02-16T09:05:20Z' //temporary, should be removed since we ve created_at
    values.org_id = user.organization_id && user.organization_id._id
    //values.received_by = user._id

    if (values.amount_paid <= 0) {
      return;
    }

    // check if location pathname is update, then update the repayment with id.
    if (props.match.params.id) {
      props.updateRepayment(props.match.params.id, values);
    } else {
      props.createRepayment(values);
    }
  },

  displayName: 'create-repayments-form',
  enableReinitialize: true
})(CreateUpdateRepaymentsForm);

export default CreateUpdateRepayments;

