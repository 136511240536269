import { connect } from 'react-redux';
import { Creators } from '../../services/redux/reports/actions';
import Reports from "./components";

const mapStateToProps = state => {
  const { isloading, reports } = state.reports
  return {
    reports,
    isloading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchReports: (org_id, filters='collections', from_date, to_date) => {
      const query = {
        org_id,
        filters,
        from_date,
        to_date
      };

      dispatch(Creators.fetchReports(query));
    },
    reset: () => {
      dispatch(Creators.reset());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
