import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  isFetching: false,
  loan: null,
  loans: [],
  loan_repayments: [],
  isFetchingRepayments: false,
  page: null,
  pageCount: null,
  defaulters: [],
  isDownloading: false,
  csvData: null,
  loans_summary: [],
  isFetchingSummary: false,
};

export const fetchLoans = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchLoansSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loans: action.responseData,
    page: action.page,
    pageCount: action.pageCount,
    isloading: false,
  };
};

export const fetchLoansFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const fetchAllLoans = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchAllLoansSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loans: action.responseData,
    page: action.page,
    pageCount: action.pageCount,
    isloading: false,
  };
};

export const fetchAllLoansFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const fetchUserLoans = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchUserLoansSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, loans: action.responseData, isloading: false };
};

export const fetchUserLoansFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const getLoan = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetching: true };
};

export const getLoanSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, loan: action.responseData, isFetching: false };
};

export const getLoanFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, errors: action.error };
};

export const createLoan = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const createLoanSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, loan: action.responseData, isloading: false };
};

export const createLoanFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const updateLoan = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const updateLoanSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, loan: action.responseData, isloading: false };
};

export const updateLoanFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const fetchLoanRepayments = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetchingRepayments: true };
};

export const fetchLoanRepaymentsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loan_repayments: action.responseData,
    page: action.page,
    pageCount: action.pageCount,
    isFetchingRepayments: false,
  };
};

export const fetchLoanRepaymentsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingRepayments: false, errors: action.error };
};

export const fetchDefaulters = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchDefaultersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaulters: action.responseData,
    page: action.page,
    pageCount: action.pageCount,
    isloading: false,
  };
};

export const fetchDefaultersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const downloadCsv = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isDownloading: true };
};

export const downloadCsvSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, csvData: action.responseData, isDownloading: false };
};

export const downloadCsvFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: false, errors: action.error };
};

export const downloadRepaymentsCsv = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isDownloading: true };
};

export const downloadRepaymentsCsvSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, csvData: action.responseData, isDownloading: false };
};

export const downloadRepaymentsCsvFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: false, errors: action.error };
};

export const fetchRoLoansSummary = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isFetchingSummary: true };
};

export const fetchRoLoansSummarySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loans_summary: action.responseData,
    page: action.page,
    pageCount: action.pageCount,
    isFetchingSummary: false,
  };
};

export const fetchRoLoansSummaryFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetchingSummary: false, errors: action.error };
};

export const reset = (state = INITIAL_STATE, action) => {
  const loanObj = {
    amount: undefined,
    release_date: undefined,
    loan_interest_rate_percentage: undefined,
    loan_interest_rate_period: undefined,
    loan_duration: undefined,
    loan_duration_period: undefined,
    repayment_cycle: undefined,
    no_of_repayments: undefined,
    staff_access: undefined,
    penalty_fees: undefined,
    file: undefined,
    extra_info: undefined,
  };
  return { ...state, loan: loanObj, errors: null };
};

export const HANDLERS = {
  [Types.FETCH_LOANS]: fetchLoans,
  [Types.FETCH_LOANS_SUCCESS]: fetchLoansSuccess,
  [Types.FETCH_LOANS_FAILURE]: fetchLoansFailure,
  [Types.FETCH_ALL_LOANS]: fetchAllLoans,
  [Types.FETCH_ALL_LOANS_SUCCESS]: fetchAllLoansSuccess,
  [Types.FETCH_ALL_LOANS_FAILURE]: fetchAllLoansFailure,
  [Types.FETCH_USER_LOANS]: fetchUserLoans,
  [Types.FETCH_USER_LOANS_SUCCESS]: fetchUserLoansSuccess,
  [Types.FETCH_USER_LOANS_FAILURE]: fetchUserLoansFailure,
  [Types.CREATE_LOAN]: createLoan,
  [Types.CREATE_LOAN_SUCCESS]: createLoanSuccess,
  [Types.CREATE_LOAN_FAILURE]: createLoanFailure,
  [Types.GET_LOAN]: getLoan,
  [Types.GET_LOAN_SUCCESS]: getLoanSuccess,
  [Types.GET_LOAN_FAILURE]: getLoanFailure,
  [Types.UPDATE_LOAN]: updateLoan,
  [Types.UPDATE_LOAN_SUCCESS]: updateLoanSuccess,
  [Types.UPDATE_LOAN_FAILURE]: updateLoanFailure,
  [Types.FETCH_LOAN_REPAYMENTS]: fetchLoanRepayments,
  [Types.FETCH_LOAN_REPAYMENTS_SUCCESS]: fetchLoanRepaymentsSuccess,
  [Types.FETCH_LOAN_REPAYMENTS_FAILURE]: fetchLoanRepaymentsFailure,
  [Types.FETCH_DEFAULTERS]: fetchDefaulters,
  [Types.FETCH_DEFAULTERS_SUCCESS]: fetchDefaultersSuccess,
  [Types.FETCH_DEFAULTERS_FAILURE]: fetchDefaultersFailure,
  [Types.DOWNLOAD_CSV]: downloadCsv,
  [Types.DOWNLOAD_CSV_SUCCESS]: downloadCsvSuccess,
  [Types.DOWNLOAD_CSV_FAILURE]: downloadCsvFailure,
  [Types.DOWNLOAD_REPAYMENTS_CSV]: downloadRepaymentsCsv,
  [Types.DOWNLOAD_REPAYMENTS_CSV_SUCCESS]: downloadRepaymentsCsvSuccess,
  [Types.DOWNLOAD_REPAYMENTS_CSV_FAILURE]: downloadRepaymentsCsvFailure,
  [Types.FETCH_RO_LOANS_SUMMARY]: fetchRoLoansSummary,
  [Types.FETCH_RO_LOANS_SUMMARY_SUCCESS]: fetchRoLoansSummarySuccess,
  [Types.FETCH_RO_LOANS_SUMMARY_FAILURE]: fetchRoLoansSummaryFailure,
  [Types.RESET]: reset,
};

export default createReducer(INITIAL_STATE, HANDLERS);
