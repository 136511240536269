import React, { useState } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table, Pagination } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import { history } from '../../../../util/helpers/browserHistory';
import { numberWithCommas } from '../../../../util/helpers/reusable-functions';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const columns = (props) => [
  {
    title: 'Loan',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, row) => {
      return numberWithCommas(row.amount)
    }
  },
  {
    title: 'Amount Paid',
    dataIndex: 'amount_paid',
    key: 'amount_paid',
    render: (text, row) => {
      return numberWithCommas(row.amount_paid)
    }
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text, row) => {
      return numberWithCommas(row.balance)
    }
  },
  {
    title: 'Repayment Cycle',
    dataIndex: 'repayment_cycle',
    key: 'repayment_cycle',
  },
  {
    title: 'Date Released',
    dataIndex: 'release_date',
    key: 'release_date',
    render: (text, row) => {
      return moment(row.release_date).format("DD/MM/YYYY hh:mm")
    }
  },
  {
    title: 'Due date',
    dataIndex: 'last_repayment_date',
    key: 'last_repayment_date',
    render: (text, row) => {
      return moment(row.last_repayment_date).format("DD/MM/YYYY")
    }
  },
  {
    title: 'Surcharge',
    dataIndex: 'principal',
    key: 'principal',
    render: (text, row) => {
      return (<span>{row.surcharge} @ {row.loan_interest} {row.loan_interest_rate_period}</span>)         
    }
  },
  {
    title: 'Penalty fees',
    dataIndex: 'penalty_fees',
    key: 'penalty_fees',
    render: (text, row) => {
      if (row.penalty_fees > 0)
        return <span style={{color: 'red'}}>{numberWithCommas(row.penalty_fees)}</span>
      else
        return numberWithCommas(row.penalty_fees)      
    }
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (text, row) => {
      let total = parseInt(row.balance || 0) + parseInt(row.penalty_fees || 0)
      return numberWithCommas(total)        
    }
  },
  {
    title: 'Status',
    dataIndex: 'loan_status',
    key: 'loan_status',
    render: (text, row) => {
      if (row.loan_status === 'pending')
        return <span style={{color: 'gray'}}>{row.loan_status}</span>
      else if (row.loan_status === 'open' || row.loan_status === 'closed')
        return <span style={{color: 'green'}}>{row.loan_status}</span>
      else
        return <span style={{color: 'red'}}>{row.loan_status}</span>        
    }
  },
  {
    title: 'Documents',
    dataIndex: 'loan_documents',
    key: 'loan_documents',
    render: (text, row) => {
      return row && row.loan_documents ? <a onClick={()=> window.open(row.loan_documents)}>Preview file</a> : ' none'
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <>
        { row.is_edittable && <Button type="link" onClick={(e) => {
            e.preventDefault();
            //history.push(`/update/loan/${row._id}`)
            if (props.user && props.user._id) {
              history.push({
                pathname: `/update/loan/${row._id}`,
                search: '',
                state: { name: props.user && props.user.name, userId: props.user && props.user._id }
              })
            }
          }}>
            Edit
          </Button>
        }
        <Button 
          disabled={row.loan_status === 'pending' || row.loan_status === 'returned'}
          onClick={(e) => {
          e.preventDefault();
          //history.push(`/update/loan/${row._id}`)
          if (props.user && props.user._id) {
            history.push({
              pathname: `/repayments/loan/${row._id}`,
              search: '',
              state: { name: props.user && props.user.name, userId: props.user && props.user._id }
            })
          }
        }}>
          Payments
        </Button>
        </>
      )         
    }
  }
];

const List = ({ loans, isloading, ...props}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const handleChange = (e) => {
    console.log("value is ", e.target.value);
  }

  return ( 
    <>
      { isloading ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { props.isloading && (<div>Loading ...</div>) }
              { loans.length > 0 &&                  
                <Table 
                  className="table"
                  dataSource={loans} 
                  pagination={false}
                  columns={columns(props)} />
            	}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing Loans</span>
                </div>
                <Pagination defaultCurrent={1} total={loans && loans.length} />
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

