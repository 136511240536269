import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = 
  {  
    errors: null, 
    user: null, 
    isloading: false,
    message: null,
    isError: false,
    isRecovered: false
  }

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.user, isloading: true }
}
 
export const loginSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false }
}
 
export const loginFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error }
}

export const signUp = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const signUpSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isloading: false }
}
 
export const signUpFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error }
}

export const resetPassword = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, user: action.user, isloading: true, message: null, isError: false, isRecovered: false }
}
 
export const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, message: "Your password has been reset successfully. Go to login screen to signin", isloading: false, isError: false, isRecovered: true }
}
 
export const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error, message: "Link expired", isError: true, isRecovered: false }
}

export const forgotPassword = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, user: action.user, isloading: true, message: null, isError: false, isRecovered: false }
}
 
export const forgotPasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, message: action.responseData, isloading: false, isError: false, isRecovered: true }
}
 
export const forgotPasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, user: null, isloading: false, errors: action.error, message: "Email does not exist", isError: true, isRecovered: false }
}

export const changePassword = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true, message: null }
}
 
export const changePasswordSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, message: action.responseData, isloading: false }
}
 
export const changePasswordFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const logout = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null, message: null }
}

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.SIGN_UP_REQUEST]: signUp,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,
  [Types.FORGOT_PASSWORD]: forgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.CHANGE_PASSWORD_REQUEST]: changePassword,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
  [Types.LOGOUT]: logout,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
