import React from "react";
import { Menu, Icon, Row, Col, Avatar } from "antd";
import "../../../styles/header.less";
import { Link } from "react-router-dom";
import Can from "../../../util/helpers/Can";
import { getUserRole } from "../../../services/api/axiosDefaults";
import { history } from "../../../util/helpers/browserHistory";
import logo from "../../../assets/hive_logo.png";

const { SubMenu } = Menu;

class WebHeader extends React.Component {
	render() {
		//get user role from localstorage
		const role = getUserRole();

		const { loggedInUser, logout, setupComplete } = this.props;

		return (
			<Row id="lowerheader">
				<Col md={6} sm={12} id="logo">
					<a
						onClick={() => {
							window.location.href = "/home";
						}}
					>
						{/* <h3>DAAS Africa Limited</h3> */}
						<img src={logo} style={{ height: "71px" }} alt="Hive" />
					</a>
				</Col>
				{loggedInUser && setupComplete ? (
					<>
						<Col md={12} sm={6}>
							<Menu mode="horizontal" className="menu">
								<Menu.Item key="1">
									<Link to="/home">Home</Link>
								</Menu.Item>
								<SubMenu
									className="submenu"
									title={
										<span>
											Clients &nbsp;
											<Icon type="caret-down" />
										</span>
									}
								>
									<Menu.Item key="organizations">
										<Link to="/clients">View Clients</Link>
									</Menu.Item>
									<Menu.Item key="organizations:1">
										<Link to="/create/client">Add Client</Link>
									</Menu.Item>
								</SubMenu>
								<SubMenu
									className="submenu"
									title={
										<span>
											Loans &nbsp;
											<Icon type="caret-down" />
										</span>
									}
								>
									<Menu.Item key="addloan">
										<Link to="/select/client">Add Loan</Link>
									</Menu.Item>
									<Menu.Item key="loans">
										<Link to="/loans">View Loans</Link>
									</Menu.Item>
									<Menu.Item key="officers">
										<Link to="/defaulters">View Defaulters</Link>
									</Menu.Item>
									<Menu.Item key="defaulters">
										<Link to="/relationship/officers">
											View Relationship Officers
										</Link>
									</Menu.Item>
								</SubMenu>
								<SubMenu
									className="submenu"
									title={
										<span>
											Reports &nbsp;
											<Icon type="caret-down" />
										</span>
									}
								>
									<Menu.Item key="reports:1">
										<Link to="/reports">Loan Reports</Link>
									</Menu.Item>
								</SubMenu>
								<SubMenu
									className="submenu"
									title={
										<span>
											Cash Management &nbsp;
											<Icon type="caret-down" />
										</span>
									}
								>
									<Menu.Item key="cash-management:1">
										<Link to="/cash-management/view">View</Link>
									</Menu.Item>
									<Menu.Item key="cash-management:2">
										<Link to="/cash-management/create">Create</Link>
									</Menu.Item>
								</SubMenu>
								<SubMenu
									className="submenu"
									title={
										<span>
											System Users &nbsp;
											<Icon type="caret-down" />
										</span>
									}
								>
									<Menu.Item key="system:users">
										<Link to="/users">View System Users</Link>
									</Menu.Item>
									<Menu.Item key="clients:1">
										<Link to="/create/user">Add System User</Link>
									</Menu.Item>
								</SubMenu>
								{loggedInUser &&
								loggedInUser.organization_id &&
								loggedInUser.organization_id.is_super ? (
									<SubMenu
										className="submenu"
										title={
											<span>
												All records &nbsp;
												<Icon type="caret-down" />
											</span>
										}
									>
										<Menu.Item key="all:loans:1">
											<Link to="/all/loans">Records</Link>
										</Menu.Item>
									</SubMenu>
								) : null}
							</Menu>
						</Col>
						<Col md={6} sm={6}>
							<Menu mode="horizontal">
								<SubMenu
									className="submenu"
									title={
										<>
											<span style={{ marginRight: "5px" }}>
												Hello, {loggedInUser.name.split(" ")[0]}
											</span>
											<Avatar
												size="large"
												icon="user"
												className="avatar-icon"
											/>
										</>
									}
								>
									<Menu.Item key="profile:1">
										<Link to="/view/profile">My Profile</Link>
									</Menu.Item>
									<Menu.Item key="profile:2">
										<Link to="/change-password">Change Password</Link>
									</Menu.Item>
									<Menu.Item key="profile:3" onClick={logout}>
										Logout
									</Menu.Item>
								</SubMenu>
							</Menu>
						</Col>
					</>
				) : (
					<>
						{history &&
						history.location &&
						(history.location.pathname !== "/setup/organization" ||
							history.location.pathname !== "/complete/setup") ? (
							<Col span={12}>
								<Menu mode="horizontal" className="menu" theme="light">
									<Menu.Item key="1">
										<Link to="/help">Help</Link>
									</Menu.Item>
									<Menu.Item key="2" className="header-button">
										<Link to="/">Login</Link>
									</Menu.Item>
									{/*<Menu.Item key="3" className="header-button" onClick={() => history.push("/signup")}>Sign Up</Menu.Item>*/}
								</Menu>
							</Col>
						) : (
							<Col span={12}>
								<Menu mode="horizontal" className="menu" theme="light"></Menu>
							</Col>
						)}
					</>
				)}
			</Row>
		);
	}
}

export default WebHeader;
