import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/repayments/actions';
import { Creators as UsersCreators } from '../../../services/redux/users/actions';
import { getLocalState } from '../../../util/helpers/reusable-functions';
import CreateUpdateLoan from "./components";

const mapStateToProps = state => {
  const { isloading, loan, isFetching, loan_repayments } = state.loans;
  return {
    loan,
    isloading: state.repayments.isloading,
    isFetching,
    repayment: state.repayments.repayment,
    users: state.users.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createRepayment: (data) => {
      dispatch(Creators.createRepayment(data));
    },
    updateRepayment: (id, data) => {
      dispatch(Creators.updateRepayment(id, data));
    },
    fetchRepayment: (id) => {
      dispatch(Creators.getRepayment(id))
    },
    fetchUsers: () => {
      const user = getLocalState('lsu-user-details')
      dispatch(UsersCreators.fetchUsers(user && user.organization_id && user.organization_id._id));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdateLoan);
