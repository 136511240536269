import React, { useState, useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';
import Pagination from '../../../../reusable-components/Pagination';
import { history } from '../../../../util/helpers/browserHistory';
import { usePrevious } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import moment from 'moment';
import get from "lodash/get";

const FormItem = Form.Item;

const columns = (props) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Contact',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (text, row) => {
      let status = row.active ? "Active" : "Deactivated"
      return status
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <>
          <Button 
            type="link" 
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: '/create/loan',
                search: '',
                state: { name: row && row.name, userId: row && row._id }
              });
            }}>
            Add loan
          </Button>
        </>
      )         
    }
  }
];

const List = ({ users, isloading, page, pageCount, searchTerm, ...props}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  return ( 
    <>
      { isloading ? (<Spinner />) :  
        (<>
          <Row>
            <Col span={24}>
              { props.isloading && (<div>Loading ...</div>) }
              { users.length > 0 &&                  
                <Table 
                  className="selecteduserstable"
                  dataSource={users} 
                  pagination={false}
                  columns={columns(props)} />
              }
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{display: "flex", justifyContent: "space-between", padding: "15px 0"}}>
                <div>
                  <span>Showing {pageCount} results</span>
                </div>
              </div>
            </Col>
          </Row>
        </>)
      }
    </>
  );
}

export default List;

