import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, Tabs } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import ProfileContent from '../../../../reusable-components/ProfileContent';
import { history } from '../../../../util/helpers/browserHistory';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import List from './loanDetails';
import RepaymentsList from './repaymentsList';
import Comments from './comments';

import PropTypes from 'prop-types';

const { TabPane } = Tabs;
const user = getLocalState('lsu-user-details');

export default class ListComponent extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    const { fetchUser, fetchLoan, fetchLoanRepayments, resetPagination, match, location } = this.props;
    resetPagination();
    
    const userId = location.state.userId
    const userName = location.state.name
    // const user = getLocalState('lsu-user-details');

    fetchUser(userId);
    fetchLoan(match.params.id);
    fetchLoanRepayments(match.params.id);
  }

  render() {
    const { isloading, isFetchingUser, match, user, loan, resetPagination, location, fetchLoanRepayments, downloadRepaymentsCsv, repaymentsReport, comments, addComment } = this.props;

    return (
      <>
        <div className="app-container mini-bottom-padding">
          <Card style={{ width: "100%" }}>
            <div className="filters-content">
              <h2>Borrower Details</h2>
              <ProfileContent 
                onSubmit={() => {
                  history.push(`/view/loans/client/${user && user._id}`)
                }}
                submitBtnName="View loans"
                isFetching={isFetchingUser} 
                userId={user && user._id} 
                {...this.props}/>
            </div>
          </Card>
        </div>
        <div className="app-container mini-top-bottom-padding">
          <Card style={{ width: "100%" }}>
            <div className="filters-content">
              <h2>Loan Details</h2>
            </div>
            <div className="filters-list">
              <List {...this.props} />
            </div>
          </Card>
        </div>
        <div className="app-container mini-top-padding">
          <Card style={{ width: "100%" }}>
            <Tabs defaultActiveKey="1" onChange={(key) => {
              console.log("Key changed to ", key)
            }}>
              <TabPane tab="Repayments" key="1">
                <div className="filters-content repayments-header">
                  <h2>Repayments</h2>
                  { loan && loan.loan_status === 'open' && 
                    <Button 
                      onClick={() => {
                        history.push({
                          pathname: '/create/repayment',
                          search: '',
                          state: { name: user && user.name, userId: user && user._id, loanId: match.params.id }
                        })
                      }}>Add payment</Button>
                  }
                </div>
                { (loan && loan.loan_status === 'open') || (loan && loan.loan_status === 'fully_paid') ? 
                  (<>
                    <Filters 
                      handleChange={(from_date, to_date) => {
                        resetPagination();
                        fetchLoanRepayments(match.params.id, { page: 1, from_date, to_date });
                      }}
                      handleExport={(from_date, to_date) => {
                        downloadRepaymentsCsv(match.params.id, { page: 1, from_date, to_date });
                      }}
                      {...this.props} />
                    <div className="filters-list">
                      <RepaymentsList {...this.props} />
                    </div>
                  </>) : (<div><p>You will be able to add and review repayments once this loan is activated</p></div>)
                }
              </TabPane>
              <TabPane tab="Comments" key="2">
                <Comments 
                  comments={loan && loan.notes}
                  newComment={(comment) => {
                    const dataObj = {
                      comment,
                      comment_by: user && user._id
                    }
                    console.log("Data obj ", dataObj)
                    addComment(match.params.id, dataObj);
                  }} 
                  {...this.props}/>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool
}


