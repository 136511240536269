import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, DatePicker } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { DateField } from '../../../../reusable-components/DateField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaStepOneForm } from '../../../../util/validations';
import { gender_options, role_option, account_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import moment from 'moment';
import { history } from '../../../../util/helpers/browserHistory';

const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateUserForm extends Component {

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location, goBack, moveNext } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    return (
      <>
          <PageContent 
            title="Personal Details"
            goBack={() => {}}
            moveNext={moveNext}
            cancelAction={() => {}}
            cardClass="card-container custom-stepper-content"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            hasBackBtn={false}
            hasNextBtn={true}
            formClassNames=""
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                	<Field
                    name='name'
                    label="Name"
                    placeholder="name"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='email'
                    label="Email"
                    placeholder="email"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                {/*<Col md={12} sm={24}>
                  <Field
                    name='current_address.parish'
                    label="Parish"
                    placeholder="parish"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>*/}
                <Col md={12} sm={24}>
                  <Field
                    type="number"
                    name='phone_number'
                    label="Contact"
                    placeholder="contact"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='national_id'
                    label="National Id"
                    placeholder="National Id"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name="gender"
                    label="Gender"
                    size="large"
                    component={SelectField}
                    options={gender_options}
                    placeholder="select gender"
                  />
                </Col>
                <Col md={12} sm={24}>
                  <Field
                    name='date_of_birth'
                    label="Date of birth"
                    size="large"
                    component={DateField}
                    setFieldValue={this.props.setFieldValue}
                    values={values}
                    disabledDate= {(current) => {
                      // disable future dates
                      const today = moment();
                      return current && current > moment(today, "YYYY-MM-DD");
                    }}
                  />
                </Col>
                <Col md={12} sm={24}>
                  <FormItem>
                    <Field
                      name="extra_info"
                      label="Extra Info/Bio"
                      placeholder="Write any extra bio about the user"
                      size="large"
                      component={TextAreaField}
                      rows={9}
                    />
                  </FormItem>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

CreateUpdateUserForm.propTypes = {
  isloading: PropTypes.bool,
}

const StepOne = withFormik({
  validationSchema: validationSchemaStepOneForm,

  mapPropsToValues: (props) => (props.user),

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');

    // check if location pathname is update, then update the user with id.
    if (props.match.params.id) {
      //props.updateUser(props.match.params.id, values)
    } else {
      values.role = 'client';
      values.organization_id = user.organization_id && user.organization_id._id
      //props.createUser(values);
    }
    props.storeUserDataChunks(values);
    props.moveNext()
  },

  displayName: 'step-one-client-form',
  enableReinitialize: true
})(CreateUpdateUserForm);

export default StepOne;

