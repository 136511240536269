import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  isloading: false,
  statistics: null
}

export const fetchStatistics = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchStatisticsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, statistics: action.responseData, isloading: false }
}
 
export const fetchStatisticsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const resetStats = (state = INITIAL_STATE, action) => {
	return { ...state, isloading: false, errors: null, statistics: null }
}
  
export const HANDLERS = {
  [Types.FETCH_STATISTICS]: fetchStatistics,
  [Types.FETCH_STATISTICS_SUCCESS]: fetchStatisticsSuccess,
  [Types.FETCH_STATISTICS_FAILURE]: fetchStatisticsFailure,
  [Types.RESET_STATS]: resetStats,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
