import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  isloading: false,
  cash_flow_data: null,
  cash_flows: [],
  page: 0,
  pageCount: 0,
  isloading_cash_totals: false,
  cash_totals: null,
  isDownloading: false,
  csvData: null
}

export const getCashManagement = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const getCashManagementSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, cash_flow_data: action.responseData, isloading: false }
}
 
export const getCashManagementFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const fetchCashManagements = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchCashManagementsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, cash_flows: action.responseData, page: action.page, pageCount: action.pageCount, isloading: false }
}
 
export const fetchCashManagementsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const fetchCashTotals = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading_cash_totals: true }
}
 
export const fetchCashTotalsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, cash_totals: action.responseData, isloading_cash_totals: false }
}
 
export const fetchCashTotalsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading_cash_totals: false, errors: action.error }
}

export const createCashManagement = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const createCashManagementSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, cash_flow_data: action.responseData, isloading: false }
}
 
export const createCashManagementFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const updateCashManagement = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const updateCashManagementSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, cash_flow_data: action.responseData, isloading: false }
}
 
export const updateCashManagementFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: null, cash_flow_data: null, cash_totals: null }
}

export const downloadCashflowCsv = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isDownloading: true }
}
 
export const downloadCashflowCsvSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, csvData: action.responseData, isDownloading: false }
}
 
export const downloadCashflowCsvFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isDownloading: false, errors: action.error }
}
  
export const HANDLERS = {
  [Types.CREATE_CASH_MANAGEMENT]: createCashManagement,
  [Types.CREATE_CASH_MANAGEMENT_SUCCESS]: createCashManagementSuccess,
  [Types.CREATE_CASH_MANAGEMENT_FAILURE]: createCashManagementFailure,
  [Types.UPDATE_CASH_MANAGEMENT]: updateCashManagement,
  [Types.UPDATE_CASH_MANAGEMENT_SUCCESS]: updateCashManagementSuccess,
  [Types.UPDATE_CASH_MANAGEMENT_FAILURE]: updateCashManagementFailure,
  [Types.FETCH_CASH_MANAGEMENTS]: fetchCashManagements,
  [Types.FETCH_CASH_MANAGEMENTS_SUCCESS]: fetchCashManagementsSuccess,
  [Types.FETCH_CASH_MANAGEMENTS_FAILURE]: fetchCashManagementsFailure,
  [Types.FETCH_CASH_TOTALS]: fetchCashTotals,
  [Types.FETCH_CASH_TOTALS_SUCCESS]: fetchCashTotalsSuccess,
  [Types.FETCH_CASH_TOTALS_FAILURE]: fetchCashTotalsFailure,
  [Types.GET_CASH_MANAGEMENT]: getCashManagement,
  [Types.GET_CASH_MANAGEMENT_SUCCESS]: getCashManagementSuccess,
  [Types.GET_CASH_MANAGEMENT_FAILURE]: getCashManagementFailure,
  [Types.DOWNLOAD_CASHFLOW_CSV]: downloadCashflowCsv,
  [Types.DOWNLOAD_CASHFLOW_CSV_SUCCESS]: downloadCashflowCsvSuccess,
  [Types.DOWNLOAD_CASHFLOW_CSV_FAILURE]: downloadCashflowCsvFailure,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
