import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import { InputField } from '../../../../reusable-components/InputField';
import { TextAreaField } from '../../../../reusable-components/TextAreaField';
import { CheckboxField as CheckBox } from '../../../../reusable-components/CheckboxField';
import { SelectField } from '../../../../reusable-components/SelectField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaChangePasswordForm } from '../../../../util/validations';
import { gender_options, role_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class ChangePasswordForm extends Component {

  componentDidMount() {
    const { fetchUser, match } = this.props;
    if (match.params.id) {
      fetchUser(match.params.id);
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values, location } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    return (
      <>
          <PageContent 
            title="Change Password"
            goBack={() => {}}
            moveNext={() => {}}
            cancelAction={() => {}}
            cardClass="card-container"
            customButtonContainerClass="align-btn-left"
            showFooter={true}
            hasSubmitBtn={true}
            submitBtnName="Update"
            hasCancelBtn={true}
            formClassNames=""
            // errors={error_message}
            buttonState={isloading}
            submitting={submitting}
            btnDisabled={pristine || submitting}
            handleSubmit={handleSubmit}>
              <Row gutter={30}>
                <Col md={12} sm={24}>
                  <div>
                    <Row>
                      <Col md={24} sm={24}>
                      	<Field
                          type="password"
                          name='old_password'
                          label="Old password"
                          placeholder="old password"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                      <Col md={24} sm={24}>
                        <Field
                          type="password"
                          name='new_password'
                          label="New Password"
                          placeholder="password"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div>
                    <Row>
                      <Col md={24} sm={24}>
                        <Field
                          type="password"
                          name='confirm_password'
                          label="Confirm Password"
                          placeholder="confirm password"
                          size="large"
                          component={InputField}
                          formerrors={formerrors}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
          </PageContent>
      </>
    );
  }
}

ChangePasswordForm.propTypes = {
  isloading: PropTypes.bool,
}

const ChangePassword = withFormik({
  validationSchema: validationSchemaChangePasswordForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    const user = getLocalState('lsu-user-details');
    delete values.confirm_password
    props.changePassword(user && user._id, values)
  },

  displayName: 'change-password-form',
  enableReinitialize: false
})(ChangePasswordForm);

export default ChangePassword;

