import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, Modal } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import PageContent from '../../../../reusable-components/PageContent';
import { history } from '../../../../util/helpers/browserHistory';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import Filters from './filters';
import List from './list';

import PropTypes from 'prop-types';

const { TextArea } = Input;

export default class ListComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
      isVisible: false,
      isReturnedModalVisible: false,
      isCancelModalVisible: false,
      comment: null,
      loanId: null,
      searchTerm: null,
      status: null,
      from_date: null,
      to_date: null
    };
  }

  componentDidMount() {
    const { fetchLoans, resetPagination } = this.props;
    resetPagination();
    fetchLoans();
  }

  refreshTable = () => {
    const { resetPagination, fetchLoans } = this.props;
    resetPagination();
    fetchLoans();
  }

  handleOk = () => {
    const { approveLoan, resetPagination, fetchLoans } = this.props;
    approveLoan(this.state.loanId, 'approved');
    this.setState({ visible: false });
  }

  disburseLoan = () => {
    const { approveLoan, resetPagination, fetchLoans } = this.props;
    approveLoan(this.state.loanId, 'open');
    this.setState({ isVisible: false });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  _handleOk = () => {
    const { approveLoan, resetPagination, fetchLoans } = this.props;
    const { loanId, comment, isReturnedModalVisible, isCancelModalVisible } = this.state;
    const name = isReturnedModalVisible ? 'isReturnedModalVisible' : 'isCancelModalVisible';
    const loan_status = isReturnedModalVisible ? 'returned' : 'cancelled';

    if (comment) {
      const user = getLocalState('lsu-user-details');
      const dataObj = {
        comment,
        comment_by: user && user._id
      }
      approveLoan(loanId, loan_status, dataObj);
      this.setState({ [name]: false });
      // this.refreshTable();
    } else {
      this.setState({ errors: 'Please add a comment' });
    }
  }

  _handleCancel = () => {
    const { isReturnedModalVisible, isCancelModalVisible } = this.state;
    const name = isReturnedModalVisible ? 'isReturnedModalVisible' : 'isCancelModalVisible';
    this.setState({ [name]: false });
  }

  render() {
    const { isloading, fetchLoans } = this.props;
    const { searchTerm, status, from_date, to_date } = this.state;
    let buttonState = isloading ? <Spinner /> : "Continue";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    const user = getLocalState('lsu-user-details');

    return (
      <>
        <PageContent 
          title="All Loans"
          isFilterContainer={true}>
          <Filters 
            handleChange={(from_date, to_date, searchTerm, status) => {
              this.setState({ searchTerm, from_date, to_date, status });
              fetchLoans({ page: 1, search_term: searchTerm, from_date, to_date, status });
            }}
            {...this.props} />
        </PageContent>
        <PageContent>
          <Button
            className="float-btn"
            onClick={() => {
              history.push('/select/client');
            }}>
            <Icon type="plus" style={{ fontSize: 24 }} />
          </Button>
          <div className="filters-list">
            <List 
              openModal={(loanId) => {
                this.setState({ visible: true, loanId });
              }}
              disburseModal={(loanId) => {
                this.setState({ isVisible: true, loanId });
              }}
              _openModal={(loanId, name) => {
                this.setState({ [name]: true, loanId });
              }}
              searchTerm={searchTerm}
              status={status}
              from_date={from_date}
              to_date={to_date}
              {...this.props} />
          </div>
        </PageContent>
        <Modal
          title="Approve Loan"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          <p>Would you like to approve this loan?</p>
        </Modal>
        <Modal
          title="Disburse Loan"
          visible={this.state.isVisible}
          onOk={this.disburseLoan}
          onCancel={this.handleCancel}>
          <p>Disburse this loan?</p>
        </Modal>
        <Modal
          title="Cancel Loan"
          visible={this.state.isReturnedModalVisible || this.state.isCancelModalVisible}
          onOk={this._handleOk}
          onCancel={this._handleCancel}>
          <>
            <p style={{color: 'red'}}>{this.state.errors}</p>
            <p>Would you like to return this loan, please add a comment below?</p>
            <TextArea  
              onChange = {(e) => {
                this.setState({ comment: e.target.value })
              }}
              label="Comment"
              size="large"
              rows={5}
              placeholder="Add comment here"
              style={{ marginTop: 20 }}
            />
          </>
        </Modal>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool
}


