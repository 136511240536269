import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Table,
  Pagination,
} from "antd";
import { withFormik, Field, FormikProps } from "formik";
import Spinner from "../../../reusable-components/Spinner";
import { InputField } from "../../../reusable-components/InputField";
import { history } from "../../../util/helpers/browserHistory";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import moment from "moment";
import get from "lodash/get";
import "../../../styles/reports.less";
import { CSVLink, CSVDownload } from "react-csv";

const FormItem = Form.Item;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const List = ({ reports, isloading, filters, ...props }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [cols, setCols] = useState(null);
  const [rows, setRows] = useState(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const getJsDateFromExcel = (excelDate) => {
    // let newDate = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
    let newDate = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
    return moment(newDate).format("YYYY-MM-DD");
  };

  const extractDataFromBlob = (reportsData) => {
    if (reportsData) {
      // const reader = new FileReader();
      console.log("reportsData is ", reportsData);
      console.log("Type of reportsData is ", typeof reportsData);

      const file = new Blob([reportsData]);
      console.log("Blob file is ", file);
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);

      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log("ExcelRenderer errors ", err);
        } else {
          console.log("Response reports excel sheets ", resp);
          setCols(resp.cols);
          let newRows = [];
          resp &&
            resp.rows.length > 0 &&
            resp.rows.forEach((row, index) => {
              console.log("row[2] ", row[2]);
              if (index > 0) {
                if (filters !== "collections_totals")
                  row[0] = getJsDateFromExcel(row[0]);
              }
              console.log("row[2] ", row);
              newRows.push(row);
            });
          // setRows(resp.rows);
          console.log("newRows are ", newRows);
          setRows(newRows);
        }
      });
    }
  };

  const prevReportsData = usePrevious(reports);

  useEffect(() => {
    const currentReportsData = reports;

    if (prevReportsData !== currentReportsData) {
      extractDataFromBlob(currentReportsData);
    }
  });

  return (
    <>
      {isloading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col span={24}>{isloading && <div>Loading ...</div>}</Col>
          </Row>
          <Row>
            {rows && cols && reports && (
              <OutTable
                data={rows}
                columns={cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default List;
