import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Alert } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import { InputField } from '../../../reusable-components/InputField';
import Spinner from '../../../reusable-components/Spinner';
import { validationSchemaForgotForm } from '../../../util/validations';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class ForgotPasswordForm extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    const {reset} = this.props;
    reset();
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, values, formerrors, message, isRecovered, isError, errors } = this.props;

    let buttonState = isloading ? <Spinner /> : "Submit";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <Form onSubmit={handleSubmit} {...formItemLayout}>
          <Row>
            <Col span={24}><h2>Recover Password</h2></Col>
            <Col span={24}>
              { isError && <Alert message={typeof errors === 'string' ? errors : message} type="error" /> }
              { isRecovered && <Alert message={message} type="success" /> }
            </Col>
            <Col md={24} sm={24}>
            	<Field
                name='email'
                label="Email"
                placeholder="enter email"
                size="large"
                component={InputField}
                formerrors={formerrors}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem className="form-btns-container">
                <Row>
                  <Col>
                    <button type="submit" className="auth-buttons">{buttonState}</button>
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

ForgotPasswordForm.propTypes = {
  isloading: PropTypes.bool,
}

const ForgotPassword = withFormik({
  validationSchema: validationSchemaForgotForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.forgotPassword(values.email);
  },

  displayName: 'forgot-form',
  enableReinitialize: true
})(ForgotPasswordForm);

export default ForgotPassword;

