import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  isloading: false,
  reports: null,
  repaymentsReport: ""
}

export const fetchReports = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchReportsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, reports: action.responseData, isloading: false }
}
 
export const fetchReportsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const extractRepaymentsReport = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const extractRepaymentsReportSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, repaymentsReport: action.responseData, isloading: false }
}
 
export const extractRepaymentsReportFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: null, reports: null }
}
  
export const HANDLERS = {
  [Types.FETCH_REPORTS]: fetchReports,
  [Types.FETCH_REPORTS_SUCCESS]: fetchReportsSuccess,
  [Types.FETCH_REPORTS_FAILURE]: fetchReportsFailure,
  [Types.EXTRACT_REPAYMENTS_REPORT]: extractRepaymentsReport,
  [Types.EXTRACT_REPAYMENTS_REPORT_SUCCESS]: extractRepaymentsReportSuccess,
  [Types.EXTRACT_REPAYMENTS_REPORT_FAILURE]: extractRepaymentsReportFailure,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
