import React from 'react';
import decode from 'jwt-decode';
import { getToken } from '../../services/api/axiosDefaults';
import { clearLocalState } from './reusable-functions';

const checkAuth = () => {
  let token = getToken();

  if(!token){
    return false;
  }

  try{
    const { exp } = decode(token);
    if(exp < new Date().getTime() / 1000){
      // clear localstorage 
      clearLocalState("lsu-user-access");
      clearLocalState("lsu-user-details");
      window.location.href = "/";
      return false;
    }
  }catch(e){
    return false;
  }
  return true;
}

export default checkAuth;