import { combineReducers } from 'redux';
import auth from './services/redux/auth/reducers';
import organizations from './services/redux/organizations/reducers';
import users from './services/redux/users/reducers';
import loans from './services/redux/loans/reducers';
import repayments from './services/redux/repayments/reducers';
import statistics from './services/redux/statistics/reducers';
import pagination from './services/redux/pagination/reducers';
import cash_management from './services/redux/cash-management/reducers';
import reports from './services/redux/reports/reducers';

const allReducers = combineReducers({
  auth,
  organizations,
  users,
  loans,
  repayments,
  statistics,
  pagination,
  cash_management,
  reports,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
  	state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;