export const gender_options = [
	{ label: "Male", value: "male" },
	{ label: "Female", value: "female" },
];

export const role_options = [
	//{ label: "Client", value: "client" },
	{ label: "Admin", value: "admin" },
	{ label: "Secretary", value: "secretary" },
	{ label: "Loan Officer", value: "loan_officer" },
	{ label: "Business Manager", value: "business_manager" }
];

export const interest_rate_options = [
	{ label: "Per Day", value: "per_day" },
	{ label: "Per Week", value: "per_week" },
	{ label: "Per Month", value: "per_month" },
	{ label: "Per Year", value: "per_year" },
];

export const duration_options = [
	{ label: "Days", value: "days" },
	{ label: "Weeks", value: "weeks" },
	{ label: "Months", value: "months" },
	{ label: "Years", value: "years" },
];

export const repayment_cycle_options = [
	{ label: "Daily", value: "daily" },
	{ label: "Weekly", value: "weekly" },
	{ label: "Monthly", value: "monthly" },
	{ label: "Yearly", value: "yearly" },
];

export const account_status_options = [
	{ label: "Active", value: "active" },
	{ label: "Deactivate", value: "deactive" },
];

export const cash_operation_type_options = [
	{ label: "Cash Inflow", value: "cash_inflow" },
    { label: "Cash Outflow", value: "cash_outflow" }
];

export const cash_inflow_options = [
	{ label: "Capital", value: "capital" },
    { label: "Donation/Grant", value: "donation_grant" },
	{ label: "Other", value: "other" }
];

export const cash_outflow_options = [
    { label: "Airtime", value: "airtime" },
    { label: "Transport", value: "transport" },
    { label: "Lunch", value: "lunch" },
    { label: "Breakfast", value: "breakfast" },
    { label: "Banked", value: "banked" },
    { label: "Given to director", value: "given_to_director" },
    { label: "Other", value: "other" }
];

export const paid_against_options = [
	{ label: "Balance(principal + interest", value: "loan" },
    { label: "Penalty fees", value: "penalty_fees" }
];

export const reports_filter_options = [
	{ label: "Loans", value: "loans" },
    { label: "Collections", value: "collections" },
    { label: "Collection Totals", value: "collections_totals" },
    { label: "Pending loans", value: "pending_loans" },
    { label: "Open loans", value: "open_loans" },
    { label: "Closed loans", value: "closed_loans" },
    { label: "Cancelled loans", value: "cancelled_loans" },
    { label: "Returned loans", value: "returned_loans" },
    // { label: "Defaulters", value: "defaulters" },
];

export const loan_status_options = [
	{ label: "All Loans", value: "" },
	{ label: "Open Loans", value: "open" },
	{ label: "Approved Loans", value: "approved" },
    { label: "Closed Loans", value: "closed" },
    { label: "Pending loans", value: "pending" },
    { label: "Returned loans", value: "returned" },
    { label: "Cancelled loans", value: "cancelled" },
];

export const dashboard_status_options = [
	{ label: "Days", value: "days" },
    { label: "Months", value: "months" },
];

export const daily_repayment_status_options = [
	{ label: "Yes", value: true },
    { label: "No", value: false },
];




