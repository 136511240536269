const rules = {
  admin: [
    "profile:view",
    "profile:edit",
    "password:change",
    "organization:create",
    "organization:edit",
    "organization:view",
    "dashboard:view",
    "statistics:view",
    "statisticscollections:view",
    "statisticsloans:view",
    "user:create",
    "user:view",
    "user:edit",
    "user:list",
    "user:view",
    "client:create",
    "client:view",
    "client:list",
    "loan:create",
    "loan:view",
    "loan:list",
    "loan:edit",
    "loan:return",
    "loan:approve",
    "loan:decline",
    "repayment:create",
    "repayment:view",
    "repayment:list",
    "repayment:edit",
    "cashmanagement:create",
    "cashmanagement:view",
    "cashmanagement:list",
    "cashmanagement:edit",
    "reports:view",
    "cash-management:create",
    "cash-management:view",
    "permission:view",
    // "all-loans:list",
  ],
  secretary: [
    "profile:view",
    "password:change",
    "organizations:create",
    // "organizations:edit",
    // "organizations:view",
    "dashboard:view",
    "statistics:view",
    "statisticscollections:view",
    "statisticsloans:view",
    "user:create",
    "user:view",
    // "user:edit",
    "user:list",
    "user:view",
    "client:create",
    "client:view",
    "client:list",
    "loan:create",
    "loan:view",
    "loan:list",
    "loan:edit",
    "repayment:create",
    "repayment:view",
    "repayment:list",
    "repayment:edit",
    // "cashmanagement:create",
    // "cashmanagement:view",
    // "cashmanagement:list",
    // "cashmanagement:edit",
    "reports:view",
    // "cash-management:create",
    // "cash-management:view",
    "permission:view",
    // "all-loans:list",
  ],
  loan_officer: [
    "profile:view",
    "password:change",
    "dashboard:view",
    "statistics:view",
    "statisticscollections:view",
    "statisticsloans:view",
    "user:view",
    "user:list",
    "user:view",
    "client:create",
    "client:view",
    "client:list",
    "loan:create",
    "loan:view",
    "loan:list",
    "loan:edit",
    "repayment:create",
    "repayment:view",
    "repayment:list",
    // "cash-management:create",
    // "cash-management:view",
    "permission:view",
    // "all-loans:list",
  ],
  business_manager: [
    "profile:view",
    "password:change",
    "organizations:create",
    "organizations:edit",
    "organizations:view",
    "dashboard:view",
    "statistics:view",
    "statisticscollections:view",
    "statisticsloans:view",
    "user:create",
    "user:view",
    "user:edit",
    "user:list",
    "user:view",
    "client:create",
    "client:view",
    "client:list",
    "loan:create",
    "loan:view",
    "loan:list",
    "loan:edit",
    "loan:return",
    "loan:approve",
    "loan:decline",
    "repayment:create",
    "repayment:view",
    "repayment:list",
    "repayment:edit",
    "cashmanagement:create",
    "cashmanagement:view",
    "cashmanagement:list",
    "cashmanagement:edit",
    "reports:view",
    "cash-management:create",
    "cash-management:view",
    "permission:view",
    // "all-loans:list",
  ]
}

export default rules;
