import React from 'react';
import { Form } from 'antd';
import { Select } from "formik-antd";
import { Formik, Field } from 'formik';

const FormItem = Form.Item;
const { Option } = Select;

export const SelectField = ({ label, field, form: { touched, errors }, ...props }) => {
	const errorMsg =  errors[field.name];
	return (
	  <FormItem
	  	label={label}
	    validateStatus={ errorMsg ? 'error': ''}
	    help={errorMsg}
	  >
			<Select
				{...props}
				name={field.name}
				id={field.id}
				size={props.size}
				mode={props.mode}
				loading={props.loading}
				showSearch
				placeholder={props.placeholder}
				optionFilterProp="children"
				onChange={(value) => {
					props.getValue && props.getValue(value);
				}}
				filterOption={(input, option) =>
				option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
			>
				<Option value="">Select ...</Option>
				{ props.options && props.options.length > 0 ? props.options.map((option, index) => (
						<Option key={index} value={option.value || option.code}>{option.label || option.name}</Option>
					)) : null
				}
		  </Select>
	  </FormItem>
	);
}