import { apiRequest } from "../apiRequest";
import { 
	ORGANIZATIONS_ENDPOINT
 } from '../urls';

export const fetchOrganizations = () => { 
	return apiRequest('GET', ORGANIZATIONS_ENDPOINT);
}

export const getOrganization = (id) => { 
	return apiRequest('GET', `${ORGANIZATIONS_ENDPOINT}/${id}/`);
}

export const createOrganization = (data) => { 
	console.log("Create an organization, post data ", data)
	return apiRequest('POST', ORGANIZATIONS_ENDPOINT, data);
}

export const updateOrganization = (id, data) => {
	return apiRequest('PATCH', `${ORGANIZATIONS_ENDPOINT}/${id}/`, data);
}

