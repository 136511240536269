import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Creators } from '../../services/redux/auth/actions';
import Auth from './components';
import checkAuth  from '../../util/helpers/checkAuth';

class Index extends Component {

  componentDidMount() {
    const { history } = this.props;
    if (checkAuth()) history.push('/home');
  }

  render() {
    return (
      <Auth {...this.props}/>
    );
  }
}

const mapStateToProps = state => {
  const { isloading, auth, message, errors, isError, isRecovered } = state.auth;
  return {
    auth,
    isloading,
    message,
    errors,
    isError,
    isRecovered
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (email, password) => {
      dispatch(Creators.loginRequest(email, password));
    },
    signUpUser: (data) => {
      dispatch(Creators.signUpRequest(data));
    },
    forgotPassword: (email) => {
      dispatch(Creators.forgotPassword(email));
    },
    resetPassword: (token, password) => {
      dispatch(Creators.resetPassword(token, password));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Index);

