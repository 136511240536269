import React, { Component } from 'react';
import { Pagination as RNPagination } from 'antd';

// export default class Pagination extends Component {
//   state = {
//     limit: 10
//   }

//   onChange = page => {
//     const { searchQuery, handleFetchIncidents, modifyPagination } = this.props;
//     const offset = this.state.limit * (page - 1);
//     const limit = this.state.limit
//     modifyPagination({ currentPage: page });
//     handleFetchIncidents({...searchQuery, limit, offset, currentPage: page});
//   };

//   render() {
//     const { totalCount, currentPage } = this.props;
//     const itemsPerPage = this.state.limit
//     const totalPages = Math.ceil(totalCount / itemsPerPage)
//     console.log("currentPage ", currentPage)
//     return ( 
//       <>
//         <RNPagination 
//           size="small" 
//           total={parseInt(totalCount)} 
//           onChange={this.onChange} 
//           defaultCurrent={1}
//           current={currentPage}
//           showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
//       </>
//     );
//   }
// }

const Pagination = ({ page=0, pageCount=0, ...props }) => {
  const onChange = (page) => {
    console.log("Page is now ", page)
    props.handlePagination(page);
  }

  console.log("page and pageCount are ", page, pageCount)

  return (
    <>
      <RNPagination 
        total={parseInt(pageCount) * 10} 
        onChange={onChange} 
        defaultCurrent={1}
        current={page} />
    </>
  )
}

export default Pagination;

