import React, { useState } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect, Comment, Avatar } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import Comments from '../../../../reusable-components/Comments';
import { InputField } from '../../../../reusable-components/InputField';
import { reports_filter_options } from '../../../../util/helpers/form-field-options';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const CommentsBox = ({ isloading, comments, newComment, ...props }) => {
	const [comment, setComment] = useState("");

  let commentButtonState = isloading ? <Spinner /> : "Add";

	return ( 
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div>
            <Comments 
              comments={comments} {...props} />
            <FormItem>
              <TextArea
                name="comment"
                placeholder="Leave comment here"
                size="large" 
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value)
                }}
                style={{paddingBottom: "35px"}}
                rows={7}
              />
              <a 
                className="btn-add-comment" 
                onClick={() => {
                  newComment(comment)
                  setComment('')
                }}>{commentButtonState}</a>
            </FormItem>
          </div>
        </Col>
      </Row>
		</>
	);
}

export default CommentsBox;

