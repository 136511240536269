import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/users/actions';
import ViewProfile from "./components";

const mapStateToProps = state => {
  const { isloading, user, isFetching } = state.users;
  return {
    user,
    isloading,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, data) => {
      dispatch(Creators.updateUser(id, data));
    },
    fetchUser: (id) => {
      dispatch(Creators.getUser(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProfile);
