import React, { Component } from 'react';
import Spinner from '../../../../reusable-components/Spinner';
import Steppers from '../../../../reusable-components/Steppers';
import PropTypes from 'prop-types';
import Summary from './Summary';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const steps = [
  {
    title: 'Personal Details',
    step: 0
  },
  {
    title: 'Address Info',
    step: 1
  },
  {
    title: 'Other Info',
    step: 2
  },
  {
    title: 'Summary',
    step: 3
  },
];

export default class CreateUpdateClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    const { fetchUser, match, reset } = this.props;
    if (match.params.id) {
      fetchUser(match.params.id);
    } else {
      reset();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, reset } = this.props;
    if (location !== prevProps.location) {
      const locationPathname = location && location.pathname;
      if (locationPathname === '/create/user' || locationPathname === '/create/client') {
        reset();
      }
    }
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  setCurrentStep(step) {
    this.setState({ current: step });
  }

  render() {
    const { current } = this.state; 

    let content = null;
    if (current === 3) {
      content = 
        <Summary 
          current={current}
          goBack={() => this.prev() }
          moveNext={() => console.log("move next") }
          {...this.props} />
    } else if (current === 2) {
      content = 
        <StepThree 
          current={current}
          goBack={() => this.prev() }
          moveNext={() => this.next() }
          {...this.props} />
    } else if (current === 1) {
      content = 
        <StepTwo 
          current={current}
          goBack={() => this.prev() }
          moveNext={() => this.next() }
          {...this.props} />
    } else {
      content = 
        <StepOne 
          current={current}
          moveNext={() => this.next() }
          {...this.props} />
    }

    return (
      <>
        <div className="steppers-container">
          <h1>{this.props.match.params.id ? 'Update Client' : 'Create Client'}</h1> 
          <Steppers 
            current={current} 
            steps={steps}
            onPress={(step) => {
              if (step === current || step < current)
                this.setCurrentStep(step)
            }} />
        </div>
        { content }
      </>
    );
  }
}

