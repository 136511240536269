import React, { Component } from 'react';
import { Form, Icon, Input, Button, Select, Row, Col, DatePicker, TreeSelect } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import Spinner from '../../../../reusable-components/Spinner';
import { InputField } from '../../../../reusable-components/InputField';

const FormItem = Form.Item;
const { Option } = Select;
const { TreeNode } = TreeSelect;

class Filters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search_term: "",
			start_date: "",
		};
		this.handleChange = this.handleChange.bind(this);
	}

  handleChange = (e) => {
    e.preventDefault();
    console.log("e.target.value ", e.target.value)
		this.setState({ [e.target.name]: e.target.value });
    this.props.handleChange(e.target.value);
  }

  componentDidUpdate(prevProps) {
    const { location, fetchUsers } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.setState({ search_term: "" })
    }
  }

	render() {
		return ( 
			<>
        <div>
          <Row gutter={32}>
            <Col offset={16} md={8} sm={12}>
              <Input
                name='search_term'
                size="large"
                className="search-input"
                style={{ width: "100%" }}
                placeholder="Search..."
                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={this.handleChange}
                value={this.state.search_term}
                autoComplete="off"
              />
            </Col>
          </Row>
        </div>
			</>
		);
	}
}

export default Filters;

