import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import ProfileContent from '../../../../reusable-components/ProfileContent';
import { history } from '../../../../util/helpers/browserHistory';
import List from './list';

import PropTypes from 'prop-types';

export default class ListComponent extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    const { fetchUser, fetchUserLoans, resetPagination, match } = this.props;
    resetPagination();
    fetchUser(match.params.id);
    fetchUserLoans(match.params.id);
  }

  render() {
    const { isloading, isFetchingUser, match, user } = this.props;

    console.log("User is ", user)

    return (
      <>
        <div className="app-container mini-bottom-padding">
          <Card style={{ width: "100%" }}>
            <div className="filters-content">
              <h2>Borrower Details</h2>
              <ProfileContent 
                onSubmit={() => {
                  if (user && user._id) {
                    history.push({
                      pathname: '/create/loan',
                      search: '',
                      state: { name: user && user.name, userId: user && user._id }
                    })
                  }
                }}
                submitBtnName="Add loan"
                isFetching={isFetchingUser} 
                userId={match.params.id} 
                {...this.props}/>
            </div>
          </Card>
        </div>
        <div className="app-container mini-top-padding">
          <Card style={{ width: "100%" }}>
            <div className="filters-content">
              <h2>Borrower Loans</h2>
            </div>
            <div className="filters-list">
              <List {...this.props} />
            </div>
          </Card>
        </div>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool
}


