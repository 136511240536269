import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/users/actions';
import { getLocalState } from '../../../util/helpers/reusable-functions';
import CreateUpdateUser from "./components";

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
  const { isloading, user, isFetching } = state.users;
  return {
    user,
    isloading,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUser: (data) => {
      data.created_by = user && user._id;
      dispatch(Creators.createUser(data));
    },
    updateUser: (id, data) => {
      dispatch(Creators.updateUser(id, data));
    },
    fetchUser: (id) => {
      dispatch(Creators.getUser(id));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdateUser);
