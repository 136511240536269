import { connect } from 'react-redux';
import { Creators } from '../../../services/redux/cash-management/actions';
import { getLocalState } from '../../../util/helpers/reusable-functions';
import CreateUpdate from "./components";

const mapStateToProps = state => {
  const { isloading, cash_flow_data, isFetching } = state.cash_management;
  return {
    cash_flow_data,
    isloading,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCashManagement: (data) => {
      const user = getLocalState('lsu-user-details');
      dispatch(Creators.createCashManagement(user && user.organization_id && user.organization_id._id, data));
    },
    updateCashManagement: (id, data) => {
      dispatch(Creators.updateCashManagement(id, data));
    },
    fetchCashManagement: (id) => {
      dispatch(Creators.getCashManagement(id));
    },
    reset: () => {
      dispatch(Creators.reset());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdate);
