import React, { Fragment, Component } from 'react';
import { Card, Col, Row } from 'antd';
import PageContent from '../../../../reusable-components/PageContent';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState, numberWithCommas } from '../../../../util/helpers/reusable-functions';
import "../../../../styles/dashboard.less";
import List from "./list";
import Filters from "./filters";

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: null,
      from_date: null,
      to_date: null
    }
  }

  componentDidMount() {
    const { fetchCashManagements, fetchCashTotals } = this.props;
    fetchCashManagements();
    fetchCashTotals();
  }

  render() {
    const { cash_flows, isloading, cash_totals, fetchCashManagements, fetchCashTotals, downloadCSV } = this.props;
    const { searchTerm, from_date, to_date } = this.state;

    let total_cash_inflows = 0;
    let total_cash_outflows = 0;

    if (cash_flows && cash_flows.length > 0) {
      cash_flows.forEach(flow => {
        if (flow.cash_operation_type === 'cash_inflow') {
          total_cash_inflows += flow.amount;
        } else {
          total_cash_outflows += flow.amount;
        }
      });
    }

    return (
      <>
        <PageContent 
          title="Cash Flow"
          showExportBtn={true} 
          exportBtnLabel="Download CSV"
          isFilterContainer={true}
          exportData={() => downloadCSV({ search_term: searchTerm, from_date, to_date })}
          {...this.props}>
            <Filters 
              handleChange={(searchTerm, from_date, to_date) => {
                this.setState({ searchTerm, from_date, to_date})
                fetchCashManagements(1, searchTerm, from_date, to_date);
                fetchCashTotals(searchTerm, from_date, to_date);
              }}
              {...this.props} />
        </PageContent>
        <PageContent>
          <Row>
            <Col sm={12} md={6}>
              <Card className="summary-cards">
                <div className="card-info">
                  <p>Cash Inflows</p>
                  <p className="no-padding">
                    { isloading && <Spinner /> }
                    {!isloading && (<><span className="price">{numberWithCommas(cash_totals && cash_totals.total_cash_inflow)}</span> <span className="currency">UGX</span></>)}</p>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="summary-cards">
                <div className="card-info">
                  <p>Cash Outflows</p>
                  <p className="no-padding">
                    { isloading && <Spinner /> }
                    { !isloading && (<><span className="price">{numberWithCommas(cash_totals && cash_totals.total_cash_outflow)}</span> <span className="currency">UGX</span></>) }
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className="filters-list">
              <List  
              searchTerm={searchTerm}
              fromDate={from_date}
              toDate={to_date}
              {...this.props} />
            </div>
          </Row>
        </PageContent>
      </>
    );
  }
}

export default View;

