import { createReducer } from 'reduxsauce'
import Types from './actionTypes'
 
export const INITIAL_STATE = { 
  errors: null,
  organization: null,
  organizations: [],
  isloading: false,
}

export const getOrganization = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const getOrganizationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, organization: action.responseData, isloading: false }
}
 
export const getOrganizationFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const fetchOrganizations = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const fetchOrganizationsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, organizations: action.responseData, isloading: false }
}
 
export const fetchOrganizationsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const createOrganization = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const createOrganizationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, organization: action.responseData, isloading: false }
}
 
export const createOrganizationFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const updateOrganization = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true }
}
 
export const updateOrganizationSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, organization: action.responseData }
}
 
export const updateOrganizationFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error }
}

export const reset = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: null, organization: null }
}
  
export const HANDLERS = {
  [Types.GET_ORGANIZATION]: getOrganization,
  [Types.GET_ORGANIZATION_SUCCESS]: getOrganizationSuccess,
  [Types.GET_ORGANIZATION_FAILURE]: getOrganizationFailure,
  [Types.FETCH_ORGANIZATIONS]: fetchOrganizations,
  [Types.FETCH_ORGANIZATIONS_SUCCESS]: fetchOrganizationsSuccess,
  [Types.FETCH_ORGANIZATIONS_FAILURE]: fetchOrganizationsFailure,
  [Types.CREATE_ORGANIZATION]: createOrganization,
  [Types.CREATE_ORGANIZATION_SUCCESS]: createOrganizationSuccess,
  [Types.CREATE_ORGANIZATION_FAILURE]: createOrganizationFailure,
  [Types.UPDATE_ORGANIZATION]: updateOrganization,
  [Types.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,
  [Types.UPDATE_ORGANIZATION_FAILURE]: updateOrganizationFailure,
  [Types.RESET]: reset,
}
 
export default createReducer(INITIAL_STATE, HANDLERS);
