import React, { useState } from "react";
import { Form, Icon, Input, Button, Checkbox, Row, Col, Table } from "antd";
import { withFormik, Field, FormikProps } from "formik";
import Spinner from "../../../../reusable-components/Spinner";
import { InputField } from "../../../../reusable-components/InputField";
import Pagination from "../../../../reusable-components/Pagination";
import { history } from "../../../../util/helpers/browserHistory";
import { numberWithCommas } from "../../../../util/helpers/reusable-functions";
import Filters from "./filters";
import moment from "moment";
import get from "lodash/get";

const FormItem = Form.Item;

const columns = (props) => [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, row) => {
      return numberWithCommas(row.amount);
    },
  },
  {
    title: "Flow type",
    dataIndex: "flow_type",
    key: "flow_type",
    render: (text, row) => {
      let fullName = row.flow_type.split("_").join(" ");
      return fullName;
    },
  },
  {
    title: "Operation Type",
    dataIndex: "cash_operation_type",
    key: "cash_operation_type",
    render: (text, row) => {
      let operation_type =
        row.cash_operation_type === "cash_inflow" ? (
          <span className="inflow">Cash inflow</span>
        ) : (
          <span className="outflow">Cash outflow</span>
        );
      return operation_type;
    },
  },
  {
    title: "Client",
    dataIndex: "user_id.name",
    key: "user_id.name",
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  },
  {
    title: "Created by",
    dataIndex: "created_by.name",
    key: "created_by.name",
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (text, row) => {
      return moment(row.created_at).format("DD/MM/YYYY hh:mm");
    },
  },
];

{
  /*
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (text, row) => {
      return (
        <Button type="link" onClick={(e) => {
          e.preventDefault();
          history.push(`/update/cash-management/${row._id}`)
        }}>
          Edit
        </Button>
      )         
    }
  */
}

const List = ({
  cash_flows,
  isloading,
  page,
  pageCount,
  searchTerm,
  fromDate,
  toDate,
  fetchCashManagements,
  ...props
}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

  const handleChange = (e) => {
    console.log("value is ", e.target.value);
  };

  return (
    <>
      {isloading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 0",
                }}
              >
                <div>
                  <span>Showing records</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {props.isloading && <div>Loading ...</div>}
              {cash_flows.length > 0 && (
                <Table
                  className="table"
                  dataSource={cash_flows}
                  pagination={false}
                  columns={columns(props)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 0",
                }}
              >
                <div>
                  <span>Showing records</span>
                </div>
                <Pagination
                  handlePagination={(_page) => {
                    fetchCashManagements(_page, searchTerm, fromDate, toDate);
                  }}
                  page={page}
                  pageCount={pageCount}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default List;
