import React, { Component } from 'react';
import { Form, Icon, Button, Row, Col, Card } from 'antd';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import { history } from '../../util/helpers/browserHistory';

const PageContent = ({ showExportBtn=false, exportBtnLabel="Export", showExtraBtns=false, isFilterContainer=false, ...props }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
      md: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 24 },
    },
  };

	return (
		<div className={`app-container ${isFilterContainer ? 'app-container-bottom-margin' : null}`}>
	    <Card style={{ width: "100%" }} className={props.cardClass}>
	      <Form onSubmit={props.handleSubmit} className={props.formClassNames} {...formItemLayout} >
		      <Row>
		      	<Col md={showExportBtn || showExtraBtns ? 12 : 24} sm={24}><h2>{props.title}</h2></Col>
		      	{showExportBtn && 
		      		(<Col md={12} sm={24} className="btn-header-container">
				      	<button 
					        type="button"
					        className="btn header-btn" 
					        onClick={() => {
					        	console.log("header button clicked")
					        	props.exportData() }}>{props.isDownloading ? <Spinner /> : exportBtnLabel }</button>
		      		</Col>)
		      	}
		      	{ showExtraBtns ? (<Col md={12} sm={24} className="btn-header-container">{props.extraBtns}</Col>) : null}
		      </Row>
	      	{ props.isFetching && <Spinner /> }
	      	{ !props.isFetching && 
	      		(<>
						{props.children}
		        {props.showFooter &&
			        (<Row>
					      <Col span={24}>
					        <div className={`footer-btn-container ${props.customButtonContainerClass}`}>
					        	{ props.hasCancelBtn &&
					          	(<button 
					          		type="button"
					            	className="btn back-btn"
					            	onClick={() => history.goBack() }>Cancel</button>
					            )
					          }
					        	{ props.hasBackBtn &&
					          	(<button 
					          		type="button"
					            	className="btn back-btn"
					            	onClick={props.goBack}>Back</button>
					            )
					          }	
					          { props.hasNextBtn && 
					          	(<button 
					          		type="submit"
					            	className="btn continue-btn"
					            	disabled={props.submitting}>{props.buttonState ? <Spinner /> : 'Next'}</button>
					            )
					        	}
					          { props.hasSubmitBtn && 
					          	(<button
				                type="submit" 
				                className="btn continue-btn"
				                disabled={props.submitting}>{props.buttonState ? <Spinner /> : props.submitBtnName}</button>
					          	)
					        	}
					        	{ props.hasCustomizableNextButton &&
					        		(<button 
					        			type="button"
		                    className={`btn continue-btn ${props.btnDisabled ? 'disabled-btn' : ''}`}
		                    disabled={props.btnDisabled}
		                    onClick={() => {
		                      props.moveNext()
		                    }}>{props.buttonState ? <Spinner /> : props.customizableNextBtnName}</button>
		                  )
					        	}
					        </div>
					      </Col>
				      </Row>)
			      }
			      </>)
			    }
		    </Form>
			</Card>
		</div>
	);
}

export default PageContent;
