import React from "react";
import { Layout, Row, Col } from "antd";
import "../../styles/footer.less";

const { Footer } = Layout;

const PageFooter = (props) => {
	return (
		<>
			<Footer>
				<p>HiveTechnologies</p>
			</Footer>
		</>
	);
}

export default PageFooter;