import React, { Component } from 'react';
import { Form, Icon, Button, Row, Col, Card, Comment, Avatar } from 'antd';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import { history } from '../../util/helpers/browserHistory';
import get from 'lodash/get';

const ProfileContent = ({ isFetching, user, userId, submitBtnName, ...props }) => {
	return (
		<div className="profile-container">
				{ isFetching && <Spinner /> }
	    	{!isFetching && 
	    	<Row>
	    		<Col md={8} sm={12}>
	    			<Comment
              avatar={
                <Avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  alt="Avatar"
                />
              }
              content={
              	<>
              	<p><label>Name:</label> {get(user, 'name') || 'N/A'}</p>
                <p><label>Email:</label> {get(user, 'email') || 'N/A'}</p>
                <p><label>Gender:</label> {get(user, 'gender') || 'N/A'}</p>
                <p><label>Contact:</label> {get(user, 'phone_number') || 'N/A'}</p>
                </>
              }
            />
	    		</Col>
	    		<Col md={8} sm={12}>
	    			<p><label>Village:</label> {get(user, 'current_address.village') || 'N/A'}</p>
	    			<p><label>Parish:</label> {get(user, 'current_address.parish') || 'N/A'}</p>
	    			<p><label>Sub-county:</label> {get(user, 'current_address.sub_county') || 'N/A'}</p>
	    			<p><label>District:</label> {get(user, 'current_address.district') || 'N/A'}</p>
	    		</Col>
	    		<Col md={8} sm={12}>
            <p><label>Business Name:</label> {get(user, 'business.name') || 'N/A'}</p>
            <p><label>Business Address:</label> {get(user, 'business.address') || 'N/A'}</p>
	    			{/*<p><label>Village:</label> {(user && user.village) || 'N/A'}</p>
	    			<p><label>Parish:</label> {(user && user.parish) || 'N/A'}</p>*/}
	    			<p><label>Account Status:</label> {get(user, 'active') || 'N/A'}</p>
	    			<Button 
	    				className="add-loan-btn"
	    				onClick={props.onSubmit}>{submitBtnName}</Button>
	    		</Col>
	    	</Row>}
		</div>
	);
}

  // "name": "string",
  // "email": "string",
  // "password": "string",
  // "gender": "string",
  // "phone_number": "string",
  // "national_id": "string",
  // "village": "string",
  // "parish": "string",
  // "sub_county": "string",
  // "district": "string",
  // "date_of_birth": "string",
  // "next_of_kin": "string",
  // "next_of_kin_docs": "string",
  // "personal_docs": "string",
  // "profile_picture": "string",
  // "business_name": "string",
  // "extra_info": "string",
  // "organization_id": "string",
  // "role": "string",
  // "active": "string"

export default ProfileContent;
