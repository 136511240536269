import React from 'react';
import {Row, Col, Button} from 'antd';
import "../../../styles/notfound.less";
import {Link} from "react-router-dom";

const NotFound = (props) => {
    return (
        <Row id="toprow">
            <Col xs={24} sm={24} md={12}>
                <div>Page is not found</div>
            </Col>
        </Row>
    );
}

export default NotFound;