import React, { Component } from "react";
import { Card } from "antd";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import SignUp from "./SignUp";

export default class Auth extends Component {
	render() {
		const { location, history } = this.props;

		let content = null;

		if (location.pathname === "/signup") {
			content = <SignUp {...this.props} />;
		} else if (location.pathname === "/forgot/password") {
			content = <ForgotPassword {...this.props} />;
		} else {
			content = <Login {...this.props} />;
		}

		return (
			<div className="auth-container">
				<Card style={{ width: "100%" }}>{content}</Card>
				{location.pathname === "/" && (
					<p className="forgot-pwd-link">
						<a
							style={{ color: "#5985bd" }}
							onClick={() => history.push("/forgot/password")}
						>
							Forgot Password
						</a>
					</p>
				)}
			</div>
		);
	}
}
