import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import LayoutPage from "./components/LayoutPage";

const mapStateToProps = state => {
  const { auth } = state.auth;
  return {
    auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(Creators.logout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutPage);

