import { useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

export const getMapValue = (obj, key) => {
  if (obj.hasOwnProperty(key))
    return obj[key]; 
}

export const uppercaseFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const storeLocalState = (field, data) => {
	try {
		if (typeof data === 'object') {
			localStorage.setItem(field, JSON.stringify(data));
		} else {
			localStorage.setItem(field, data);
		}
	} catch (err) {
		console.log("Error storing in local storage ", err);
	}
}

export const getLocalState = (field, field_is_object=false) => {
	try {
		const data = localStorage.getItem(field);
		if (field_is_object) {
			return data;
		} else {
			return JSON.parse(data);
		}
	} catch (err) {
		console.log("Error fetching from local storage ", err);
		return null;
	}
}

export const clearLocalState = (field) => {
	try {
		localStorage.removeItem(field);
	} catch (err) {
		console.log("Error clearing in local storage ", err);
	}
}

export const generateFormData = (payload = {}) => {
  	const formData = new FormData();
  	const data = Object.keys(payload);
  	data.forEach(key => {
    	if (payload[key] !== null && payload[key] !== "" && payload[key] !== undefined) {
    		if (typeof payload[key] === 'object') {
    			console.log('Instance of File ', payload[key] instanceof File)
    			if (!(payload[key] instanceof File)) {
	    			let payloadObj = payload[key]
	    			const payloadKeys = Object.keys(payload[key]);
	    			payloadKeys.forEach(k => {
	    				if (payloadObj[k])
	    					formData.append(`${key}[${k}]`, payloadObj[k])
	    			});
	    		} else {
	    			formData.append(key, payload[key]);
	    		}
	      	} else if (Array.isArray(payload[key])) {
	      		let payloadArray = payload[key]
	      		payloadArray.forEach(payloadOption => {
	      			formData.append(`${key}[]`, payloadOption);
	      		});
	      	} else {
	      		formData.append(key, payload[key]);
	      	}
    	}
  	});

  	return formData;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const printDocument = (divId, filename, sizet, sizeh) => {  
  const node = document.getElementById(divId);
  const pdf = new jsPDF('p', 'pt', 'a4');
  if (pdf) {
    domtoimage.toPng(node)
    .then(function (dataUrl) {
      pdf.addImage(dataUrl, 'PNG', 10, 10, sizet, sizeh);
      pdf.save(filename);
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }
}

export function numberWithCommas(str){
    if(str){
        var parts = str.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    return str;
}

export function addInputCommas(str){
    if(str){
        str = replaceCommas(str);
        var parts = str.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    return str;
}

export function replaceCommas(comma_seperated_str){
    if(comma_seperated_str){
        var str = comma_seperated_str.toString();
        str = str.replace(/,/g, "");
        return str;
    }
    return comma_seperated_str;
}

