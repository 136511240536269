import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import Spinner from '../../../../reusable-components/Spinner';
import PageContent from '../../../../reusable-components/PageContent';
import { history } from '../../../../util/helpers/browserHistory';
import Filters from './filters';
import List from './list';

import PropTypes from 'prop-types';

export default class ListComponent extends Component {
  componentDidMount() {
    const { resetPagination } = this.props;
    resetPagination();
  }

  render() {
    const { isloading, fetchUsers } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <PageContent 
          title="Search for client to give loan"
          isFilterContainer={true}>
          <Filters 
            handleChange={(val) => {
              fetchUsers({ page: 1, search_term: val, userType: 'client' });
            }}
            {...this.props} />
        </PageContent>
        <PageContent>
          <List {...this.props} />
        </PageContent>
      </>
    );
  }
}

ListComponent.propTypes = {
  isloading: PropTypes.bool,
  initialValues: PropTypes.object,
}

