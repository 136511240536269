import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message } from "antd"
import { Creators, Types } from '../../redux/repayments/actions'
import {
	createRepayment as createRepaymentApi,
	updateRepayment as updateRepaymentApi,
	fetchRepayments as fetchRepaymentsApi,
	getRepayment as getRepaymentApi,
} from '../../api/repayments';
import { history } from '../../../util/helpers/browserHistory';
import { OpenNotificationWithIcon } from '../../../reusable-components/Notifications';
import { generateFormData } from '../../../util/helpers/reusable-functions';

export function* createRepayment(actions) {
	try {
		const { payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(createRepaymentApi, payload);
		const response = yield call(createRepaymentApi, formData);
		const resp = response && response.data;
		yield put(Creators.createRepaymentSuccess(resp))
		OpenNotificationWithIcon('success', 'Repayment Create', 'Repayment has been created successfully!')
		history.goBack();
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		console.log("errorsFound ", errorsFound && errorsFound.message)
		// OpenNotificationWithIcon('error', 'Repayment Create', 'Failed to create Repayment')
		OpenNotificationWithIcon('error', 'Repayment Create', errorsFound && errorsFound.message)
		yield put(Creators.createRepaymentFailure(errorsFound));
	}
}

export function* updateRepayment(actions) {
	try {
		const { id, payload } = actions;
		const formData = generateFormData(payload);
		// const response = yield call(updateRepaymentApi, id, payload);
		const response = yield call(updateRepaymentApi, id, formData);
		const resp = response && response.data;
		yield put(Creators.updateRepaymentSuccess(resp && resp.doc))
		OpenNotificationWithIcon('success', 'Repayment Update', 'Repayment has been updated successfully!')
		history.push('/loans');
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		OpenNotificationWithIcon('error', 'Repayment Update', 'Failed to update Repayment!')
		yield put(Creators.updateRepaymentFailure(errorsFound));
	}
}

export function* getRepayment(actions) {
	try {
		const { id } = actions;
		const response = yield call(getRepaymentApi, id);
		const resp = response && response.data;
		yield put(Creators.getRepaymentSuccess(resp && resp.doc))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getRepaymentFailure(errorsFound));
	}
}

export function* fetchRepayments(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchRepaymentsApi, query);
		const resp = response && response.data;
		yield put(Creators.fetchRepaymentsSuccess(resp && resp.doc))
	} catch (error) {
		let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchRepaymentsFailure(errorsFound));
	}
}

export function* watchFetchRepayments() {
	yield takeEvery(Types.FETCH_REPAYMENTS, fetchRepayments);
}

export function* watchGetRepayment() {
	yield takeEvery(Types.GET_REPAYMENT, getRepayment);
}

export function* watchCreateRepayment() {
    yield takeEvery(Types.CREATE_REPAYMENT, createRepayment);
}

export function* watchUpdateRepayment() {
    yield takeEvery(Types.UPDATE_REPAYMENT, updateRepayment);
}

