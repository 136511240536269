import { connect } from "react-redux";
import { Creators } from "../../../services/redux/statistics/actions";
import { Creators as UsersCreators } from "../../../services/redux/users/actions";
import { Creators as LoansCreators } from "../../../services/redux/loans/actions";
import { getLocalState } from "../../../util/helpers/reusable-functions";
import Dashboard from "./components";

const user = getLocalState("lsu-user-details");

const mapStateToProps = (state) => {
  const { isloading, statistics } = state.statistics;
  return {
    isloading,
    statistics,
    loan_officers: state.users.users,
    isFetching: state.users.isloading,
    loans_summary: state.loans.loans_summary,
    page: state.loans.page,
    pageCount: state.loans.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStatistics: (period = "months", from_date, to_date, officer_id) => {
      let query = {
        org_id: user && user.organization_id && user.organization_id._id,
        period,
        from_date,
        to_date,
        officer_id,
      };
      dispatch(Creators.fetchStatistics(query));
    },
    fetchUsers: () => {
      const user = getLocalState("lsu-user-details");
      dispatch(
        UsersCreators.fetchUsers(
          user && user.organization_id && user.organization_id._id
        )
      );
    },
    resetStats: () => {
      dispatch(Creators.resetStats());
    },
    fetchRoLoansSummary: (page = 1, from_date, to_date, officer_id) => {
      let query = {
        page,
        org_id: user && user.organization_id && user.organization_id._id,
        from_date,
        to_date,
      };
      dispatch(LoansCreators.fetchRoLoansSummary(officer_id, query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
