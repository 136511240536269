import React from 'react';
import { Form,  Input} from 'antd';
import { Formik, Field } from 'formik';
import { getMapValue } from '../../util/helpers/reusable-functions';

const FormItem = Form.Item;

export const InputField = ({ label, field, form: { touched, errors }, ...props }) => {

	let errorMsg = errors[field.name];

	const objKeys = Object.keys(errors);
	objKeys.forEach(key => {
		if (typeof errors[key] === 'object') {
			try {
				const innerKeys = Object.keys(errors[key]);

				innerKeys.forEach(k => {
					errors[key + '.' + k] = errors[key][k]
				})
			} catch (err) {

			}
		}
	});

	//errors['current_address.parish'] = 'Field is required now'

	const { formerrors } = props;
	const errorsFound = touched && formerrors && formerrors.hasOwnProperty(field.name) && getMapValue(formerrors, field.name).map(err=>{
    	return err;
	});

	if (errorsFound) {
		errorMsg = errorsFound
	}

	return (
	  	<FormItem
			label={label}
		    validateStatus={ errorMsg ? 'error': ''}
		    help={errorMsg}>
		    <Input
			 	{...field}
			  	{... props}  
			  	type={props.type ? props.type : 'text'}
			  	onInput= {props.type === "number" ? ((e) => e.target.value = Math.abs(e.target.value)) : undefined}
			  	step = {props.type === "number" ? "1" : undefined}
			  	onChange = {(e) => {
					field.onChange(e)
					props.onChange && props.onChange(e)
			  	}}
			  	style={{...props.styles || props.style}}
		  	/>
	  	</FormItem>
	);
}

