import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import { InputField } from '../../../../reusable-components/InputField';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { validationSchemaCreateUpdateOrganizationForm } from '../../../../util/validations';
import PropTypes from 'prop-types';

const FormItem = Form.Item;
const { TextArea } = Input;

class CreateUpdateOrganizationForm extends Component {

  componentDidMount() {
    const { fetchUser, match, reset } = this.props;
    if (match.params.id) {
      fetchUser(match.params.id);
    } else {
      reset();
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, isloading, handleChange, handleBlur, formerrors, values } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 24 },
      },
    };

    return (
      <>
        <div className="auth-container">
          <Card style={{ width: "100%" }}>
            <Form onSubmit={handleSubmit} {...formItemLayout}>
              <Row>
                <Col span={24}><h2>Create Organization</h2></Col>
                <Col md={24} sm={24}>
                	<Field
                    name='name'
                    label="Company Name"
                    placeholder="company name"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
                <Col md={24} sm={24}>
                  <Field
                    name='address'
                    label="Address"
                    placeholder="address"
                    size="large"
                    component={InputField}
                    formerrors={formerrors}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem className="form-btns-container">
                    <Row>
                      <Col>
                        <button type="submit" className="auth-buttons">{buttonState}</button>
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </>
    );
  }
}

CreateUpdateOrganizationForm.propTypes = {
  isloading: PropTypes.bool,
}

const CreateUpdateOrganization = withFormik({
  validationSchema: validationSchemaCreateUpdateOrganizationForm,

  handleSubmit: (values, { props, setSubmitting }) => {
    // const user = getLocalState('lsu-user-details');
    // values.contact_person = user._id
    // values.subscribed_model = 'loan'
    // check if location pathname is update, then update the organization with id.
    console.log("values to submit ", values)
    props.createOrganization(values);
  },

  displayName: 'create-organization-form',
  enableReinitialize: true
})(CreateUpdateOrganizationForm);

export default CreateUpdateOrganization;

