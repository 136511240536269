import { apiRequest, apiRequestOthers } from "../apiRequest";
import { STATISTICS_ENDPOINT } from "../urls";

export const fetchStatistics = (query) => {
	let org_id = query && query.org_id;
	let period = query && query.period;
	let from_date = query && query.from_date;
	let to_date = query && query.to_date;
	let officer_id = query && query.officer_id;

	// return apiRequest('GET', `${STATISTICS_ENDPOINT}/${org_id}`)
	return apiRequest(
		"GET",
		`${STATISTICS_ENDPOINT}?
		${org_id ? `&org_id=${org_id}` : ""}
		${period ? `&period=${period}` : ""}
		${from_date ? `&from_date=${from_date}` : ""}
		${to_date ? `&to_date=${to_date}` : ""}
		${officer_id ? `&officer_id=${officer_id}` : ""}`
	);
};
