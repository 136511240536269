import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Card, DatePicker } from 'antd';
import { withFormik, Field, FormikProps } from 'formik';
import PageContent from '../../../../reusable-components/PageContent';
import Spinner from '../../../../reusable-components/Spinner';
import { getLocalState } from '../../../../util/helpers/reusable-functions';
import { gender_options, role_option, account_status_options } from '../../../../util/helpers/form-field-options';
import PropTypes from 'prop-types';
import { history } from '../../../../util/helpers/browserHistory';
import moment from 'moment';
import { get } from 'lodash/get';

const FormItem = Form.Item;
const { TextArea } = Input;

export default class Summary extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = () => {
    const { updateUser, createUser, match, user } = this.props;

    if (user && user.deactivated) {
      delete user.deactivated;
    }

    if (user && user.deactivatedObj) {
      user.deactivated = user.deactivatedObj;
    }
    
    if (match.params.id) {
      delete user.organization_id
      updateUser(match.params.id, user)
    } else {
      createUser(user)
    }
  }

  render() {
    const { isloading, formerrors, user, location, goBack, moveNext } = this.props;
  
    let buttonState = isloading ? <Spinner /> : "Create";

    return (
      <>
        <PageContent 
          title="Summary Review"
          goBack={goBack}
          moveNext={this.handleSubmit}
          cancelAction={() => {}}
          cardClass="card-container custom-stepper-content"
          customButtonContainerClass="align-btn-left"
          showFooter={true}
          showFooter={true}
          hasBackBtn={true}
          hasCustomizableNextButton={true}
          customizableNextBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
          submitBtnName={` ${ this.props.match.params.id ? 'Update': 'Create' }`}
          formClassNames=""
          buttonState={isloading}
          submitting={isloading}
          btnDisabled={isloading}
          handleSubmit={() => {}}>
          <Row gutter={30}>
            <Col span={24}>
              <div>
                <h4 className="header"><b>Personal Details</b></h4>
                <div className="address">
                  <p>Name: {user && user.name}</p>
                  <p>Email: {user && user.email}</p>
                  <p>Gender: {user && user.gender}</p>
                  <p>Contact: {user && user.phone_number}</p>
                  <p>Date of Birth: {moment(user && user.date_of_birth).format('YYYY-MM-DD')}</p>
                  <p>Extra Info: {user && user.extra_info}</p>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <h4 className="header"><b>Address of the Applicant</b></h4>
                <div>
                  <div className="address">
                    <i><b>Current Address</b></i>
                    <p>Village: {user && user.current_address && user.current_address.village}</p>
                    <p>Parish: {user && user.current_address && user.current_address.parish}</p>
                    <p>Sub-county: {user && user.current_address && user.current_address.sub_county}</p>
                    <p>District: {user && user.current_address && user.current_address.district}</p>
                    <i><b>Period at current Address</b></i>
                    <p>Years: {user && user.period_at_current_address && user.period_at_current_address.years}</p>
                    <p>Months: {user && user.period_at_current_address && user.period_at_current_address.months}</p>
                    <p>Days: {user && user.period_at_current_address && user.period_at_current_address.days}</p>
                    <i><b>Chairperson Details</b></i>
                    <p>Name: {user && user.chairperson && user.chairperson.name}</p>
                    <p>Contact: {user && user.chairperson && user.chairperson.phone}</p>
                  </div>
                </div>
                {user && user.previous_address && user.previous_address.village && 
                  (<div>
                    <i><b>Previous Address</b></i>
                    <div className="address">
                      <p>Village: {user && user.previous_address && user.previous_address.village}</p>
                      <p>Parish: {user && user.previous_address && user.previous_address.parish}</p>
                      <p>Sub-county: {user && user.previous_address && user.previous_address.sub_county}</p>
                      <p>District: {user && user.previous_address && user.previous_address.district}</p>
                      <i><b>Period at previous Address</b></i>
                      <p>Years: {user && user.period_at_previous_address && user.period_at_previous_address.years}</p>
                      <p>Months: {user && user.period_at_previous_address && user.period_at_previous_address.months}</p>
                      <p>Days: {user && user.period_at_previous_address && user.period_at_previous_address.days}</p>
                    </div>
                  </div>)
                }
              </div>
            </Col>
            <Col span={24}>
              <div>
                <h4 className="header"><b>Other Details</b></h4>
                <div className="address">
                  <p>Business Name: {user && user.business_name}</p>
                  <p>File fees: {user && user.file_fees}</p>
                  <p>Status: {user && user.active}</p>
                  { user && user.deactivatedObj && 
                    (<>
                      <i><b>Reason for Deactivation</b></i>
                      <p>{user.deactivatedObj && user.deactivatedObj.comment}</p>
                    </>)
                  }
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <h4 className="header"><b>Documents</b></h4>
                <div className="address">
                  <p><i><b>Profile picture</b> <span>{user && user.profile_picture ? 'File attached' : 'File missing'}</span></i></p>
                  <p><i><b>Client National ID</b> <span>{user && user.personal_docs ? 'File attached' : 'File missing'}</span></i></p>
                  <p><i><b>Chairperson Documents</b> <span>{user && user.chairperson && user.chairperson.docs ? 'File attached' : 'File missing'}</span></i></p>
                </div>
              </div>
            </Col>
          </Row>
        </PageContent>
      </>
    );
  }
}

Summary.propTypes = {
  isloading: PropTypes.bool,
}

