import React, { Fragment, Component } from "react";
import { Card, Col, Row, Divider } from "antd";
import { Line } from "react-chartjs-2";
import PageContent from "../../../../reusable-components/PageContent";
import Spinner from "../../../../reusable-components/Spinner";
import Filters from "./filters";
import Summary from "./view-loans-summary";
import {
  getLocalState,
  printDocument,
  numberWithCommas,
} from "../../../../util/helpers/reusable-functions";
import "../../../../styles/dashboard.less";
import get from "lodash/get";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      officerId: null,
    };
  }

  componentDidMount() {
    const { fetchUsers, resetStats } = this.props;
    resetStats();
    fetchUsers();
  }

  render() {
    const {
      statistics,
      isloading,
      fetchStatistics,
      fetchRoLoansSummary,
      loan_officers,
      loans_summary,
    } = this.props;
    const { fromDate, toDate, officerId } = this.state;

    let loan_officer_options = [];
    loan_officers
      .filter((user) => user.role !== "client")
      .map((filteredUser) => {
        let userObj = {
          label: filteredUser.name,
          value: filteredUser._id,
        };
        loan_officer_options.push(userObj);
      });

    return (
      <>
        <PageContent
          title="Relationship Officers Performance"
          showExportBtn={true}
          exportBtnLabel="Export Summary"
          exportData={() => printDocument("graphdiv", "graph.pdf", 550, 550)}
          isFilterContainer={true}
        >
          <Row>
            <Col span={24}>
              <Filters
                loan_officer_options={loan_officer_options}
                handleChange={(from_date, to_date, period, officer_id) => {
                  fetchStatistics(period, from_date, to_date, officer_id);
                  this.setState({
                    fromDate: from_date,
                    toDate: to_date,
                    officerId: officer_id,
                  });
                  fetchRoLoansSummary(1, from_date, to_date, officer_id);
                }}
              />
            </Col>
          </Row>
        </PageContent>
        <PageContent>
          <div id="graphdiv">
            {isloading ? (
              <Spinner />
            ) : statistics ? (
              <>
                <Row>
                  <Col span={24}>
                    <h3 className="header">
                      <b>SUMMARY</b>
                    </h3>
                  </Col>
                  <Col span={8}>
                    <p>
                      <h4 className="no-padding">
                        <b>Relations Officer</b>
                      </h4>{" "}
                      {get(statistics, "relationship_officer.name")}
                    </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      <h4 className="no-padding">
                        <b>Gender</b>
                      </h4>{" "}
                      {get(statistics, "relationship_officer.gender")}
                    </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      <h4 className="no-padding">
                        <b>Contact</b>
                      </h4>{" "}
                      {get(statistics, "relationship_officer.phone_number")}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Total loans</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics && statistics.total_loans_released
                                )}
                              </span>{" "}
                              <span className="currency">UGX</span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Total Repayments</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics &&
                                    statistics.total_loans_collections
                                )}
                              </span>{" "}
                              <span className="currency">UGX</span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Fully Paid Loans</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics && statistics.fully_paid_loans
                                )}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Open Loans</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics && statistics.open_loans
                                )}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Pending Loans</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics && statistics.pending_loans
                                )}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col sm={12} md={6}>
                    <Card className="summary-cards">
                      <div className="card-info">
                        <p>Cancelled Loans</p>
                        <p className="no-padding">
                          {isloading && <Spinner />}
                          {!isloading && (
                            <>
                              <span className="price">
                                {numberWithCommas(
                                  statistics && statistics.cancelled_loans
                                )}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Summary
                      loans={loans_summary}
                      fromDate={fromDate}
                      toDate={toDate}
                      officerId={officerId}
                      {...this.props}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Col md={12} sm={24}>
                <div className="line-graph">
                  <p>No results found</p>
                </div>
              </Col>
            )}
          </div>
        </PageContent>
      </>
    );
  }
}

export default Dashboard;
