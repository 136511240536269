import { createTypes } from "reduxsauce";

export default createTypes(
  `
  FETCH_LOANS
  FETCH_LOANS_SUCCESS
  FETCH_LOANS_FAILURE

  FETCH_ALL_LOANS
  FETCH_ALL_LOANS_SUCCESS
  FETCH_ALL_LOANS_FAILURE

  FETCH_USER_LOANS
  FETCH_USER_LOANS_SUCCESS
  FETCH_USER_LOANS_FAILURE

  CREATE_LOAN
  CREATE_LOAN_SUCCESS
  CREATE_LOAN_FAILURE

  GET_LOAN
  GET_LOAN_SUCCESS
  GET_LOAN_FAILURE

  UPDATE_LOAN
  UPDATE_LOAN_SUCCESS
  UPDATE_LOAN_FAILURE

  FETCH_LOAN_REPAYMENTS
  FETCH_LOAN_REPAYMENTS_SUCCESS
  FETCH_LOAN_REPAYMENTS_FAILURE

  FETCH_DEFAULTERS
  FETCH_DEFAULTERS_SUCCESS
  FETCH_DEFAULTERS_FAILURE

  DOWNLOAD_CSV
  DOWNLOAD_CSV_SUCCESS
  DOWNLOAD_CSV_FAILURE

  DOWNLOAD_REPAYMENTS_CSV
  DOWNLOAD_REPAYMENTS_CSV_SUCCESS
  DOWNLOAD_REPAYMENTS_CSV_FAILURE

  FETCH_RO_LOANS_SUMMARY
  FETCH_RO_LOANS_SUMMARY_SUCCESS
  FETCH_RO_LOANS_SUMMARY_FAILURE

  RESET

`,
  {}
);
