import { createTypes } from 'reduxsauce'
 
export default createTypes(`
  FETCH_USERS
  FETCH_USERS_SUCCESS
  FETCH_USERS_FAILURE

  CREATE_USER
  CREATE_USER_SUCCESS
  CREATE_USER_FAILURE

  GET_USER
  GET_USER_SUCCESS
  GET_USER_FAILURE

  UPDATE_USER
  UPDATE_USER_SUCCESS
  UPDATE_USER_FAILURE

  STORE_USER_DATA_CHUNKS

  ATTACH_USER_ORGANIZATION
  ATTACH_USER_ORGANIZATION_SUCCESS
  ATTACH_USER_ORGANIZATION_FAILURE

  RESET

`, {});
