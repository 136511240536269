import { connect } from 'react-redux';
import { Creators } from '../../services/redux/statistics/actions';
import { getLocalState } from '../../util/helpers/reusable-functions';
import Dashboard from './components';

const user = getLocalState('lsu-user-details');

const mapStateToProps = state => {
	const { isloading, statistics } = state.statistics;
  return {
  	isloading, 
  	statistics
  };
};

const mapDispatchToProps = dispatch => {
  return {
  	fetchStatistics: (period="months", from_date, to_date) => {
      let query = {
        org_id: user && user.organization_id && user.organization_id._id,
        period,
        from_date,
        to_date
      }
  		dispatch(Creators.fetchStatistics(query));
  	},
    resetStats: () => {
      dispatch(Creators.resetStats());
    }
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);

