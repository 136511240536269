/* Base Urls */
export const BASE_URL = "https://loansapi.hivetech-ug.com/api/v1"; //production
// export const BASE_URL = "https://loanbackend.herokuapp.com/api/v1"; //heroku
// export const BASE_URL = "https://a6a68e9c6e08.ngrok.io/api/v1"; //remotely
// export const BASE_URL = 'http://localhost:3003/api/v1'; //local

/* Auth endpoints */
export const LOGIN_ENDPOINT = "users/login";
export const SIGN_UP_ENDPOINT = "users/signup"; //email, name, pwd, role=client=default, active=true-default
export const FORGOT_PASSWORD_ENDPOINT = "users/forgot/password";
export const RESET_PASSWORD_ENDPOINT = "users/reset";
export const CHANGE_PASSWORD_ENDPOINT = "users/change";
export const REFRESH_TOKEN_ENDPOINT = "refresh/token";

/* user account endpoints */
export const USERS_ENDPOINT = "users";
export const USER_PROFILE_ENDPOINT = "me/";
export const RESET_NEW_ACCOUNT_ENDPOINT = "reset_new_account_password/";

/* organization endpoints */
export const ORGANIZATIONS_ENDPOINT = "organizations";

/* loan endpoints */
export const LOANS_ENDPOINT = "loans";

/* repayment endpoints */
export const REPAYMENTS_ENDPOINT = "repayments";

/* statistics endpoints */
// export const STATISTICS_ENDPOINT = 'statistics/org';
export const STATISTICS_ENDPOINT = "statistics/";

/* Reports */
export const REPORTS_ENDPOINT = "reports";

/* Cash Management */
export const CASH_MANAGEMENT_ENDPOINT = "cash";

/* Cash totals */
export const CASH_TOTALS_STATISTICS_ENDPOINT = "statistics/totals";
